class Mask {
  phone(p: string): string {
    p = p.replace(/\D/g, '').substring(0, 11);
    p = p.replace(/^(\d{2})(\d)/g, '($1) $2');
    if (p.length <= 10) {
      p = p.replace(/(\d{4})(\d)/, '$1-$2');
    } else {
      p = p.replace(/(\d{5})(\d)/, '$1-$2');
    }
    return p;
  }

  cnpj(p: string): string {
    p = p.replace(/\D/g, '');
    p = p.replace(/^(\d{2})(\d)/, '$1.$2');
    p = p.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3');
    p = p.replace(/\.(\d{3})(\d)/, '.$1/$2');
    p = p.replace(/(\d{4})(\d)/, '$1-$2');
    return p.substring(0, 18);
  }

  cpf(p: string): string {
    p = p.replace(/\D/g, '');
    p = p.replace(/^(\d{3})(\d)/g, '$1.$2');
    p = p.replace(/^(\d{3})\.(\d{3})(\d)/, '$1.$2.$3');
    p = p.replace(/^(\d{3})\.(\d{3})\.(\d{3})(\d)/, '$1.$2.$3-$4');
    p = p.replace(/^(\d{3})\.(\d{3})\.(\d{3})\/(\d{2})(\d)/, '$1.$2.$3-$4');
    return p.substring(0, 14);
  }

  cep(p: string): string {
    p = p.replace(/\D/g, '');
    p = p.replace(/(\d{5})(\d{3})/g, '$1-$2');

    return p.substring(0, 9);
  }

  number(p: string): string {
    p = p.replace(/\D/g, '');
    return p;
  }

  date(date: string): string {
    if (date === null || date === undefined || date.includes('null')) {
      return '';
    }

    date = date.replace(/\D/g, '').slice(0, 10);
    date = date.replace(/(\d{2})(\d)/, '$1/$2');
    date = date.replace(/(\d{2})(\d)/, '$1/$2');

    return date;
  }

  creditCard(p: string): string {
    p = p.replace(/\D/g, '');
    p = p.replace(/(\d{4})/g, '$1 ');
    p = p.replace(/\.$/, '');
    p = p.substring(0, 19);

    return p;
  }

  monthYear(p: string): string {
    p = p.replace(/\D/g, '').slice(0, 6);
    p = p.replace(/(\d{2})(\d)/, '$1/$2');
    p = p.replace(/(\d{4})(\d)/, '$1/$2');

    return p;
  }

  uppercaseAZ(p: string): string {
    p = p.replace(/[^\D]/, '');

    return p;
  }

  formatDateDMY(date: string) {
    if (!date) return '';
    return date.substring(0, 10).split('-').reverse().join('/');
  }

  formatDateYMD(date: string) {
    if (!date) return '';
    return date.substring(0, 10).split('/').reverse().join('-');
  }

  formatCep(date: string) {
    if (!date) return '';
    return `${date.substring(0, 5)}-${date.substring(5, 8)}`;
  }

  formatMoeda = (value: any) => {
    if (!Number(value) && value !== 0 && value !== '0') return '';

    const amount = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value);

    return `${amount}`;
  };

  formatCurrencyInput = (value: string) => {
    let v = value.replace(/\D/g, '');
    v = `${(Number(v) / 100).toFixed(2)}`;
    v = v.replace('.', ',');
    v = v.replace(/(\d)(\d{3})(\d{3}),/g, '$1.$2.$3,');
    v = v.replace(/(\d)(\d{3}),/g, '$1.$2,');
    return v;
  };

  formatWeightInput = (value: string) => {
    let v = value.replace(/\D/g, '');
    v = `${(Number(v) / 1000).toFixed(3)}`;
    v = v.replace(/(\d)(\d{1})(\d{3}),/g, '$1.$2');
    return v;
  };
}

export default new Mask();
