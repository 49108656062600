import classNames from 'classnames';
import { useContext, useEffect } from 'react';
import { AiOutlineHome } from 'react-icons/ai';
import { BiCategoryAlt } from 'react-icons/bi';
import { IoIosSend, IoMdLogIn } from 'react-icons/io';
import {
  MdOutlineCategory,
  MdOutlineRequestQuote,
  MdOutlineVerifiedUser,
} from 'react-icons/md';
import { RiProductHuntLine, RiUserSettingsLine } from 'react-icons/ri';
import { TbBrandVk, TbTicket } from 'react-icons/tb';
import { useNavigate } from 'react-router-dom';
import SessionContext from '../../../contexts/session.context';
import { getItem } from '../../../helpers/storage.helper';

export default function Menu() {
  const { signOut, setMenu, menu } = useContext(SessionContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!menu) {
      const pathname = getItem('pathname', true);
      setMenu(pathname);
    }
  }, []);

  return (
    <div className="flex flex-col gap-2 mt-6 relative h-full ">
      <button
        type="button"
        onClick={() => {
          setMenu('dashboard');
          navigate('/dashboard');
        }}
        className={classNames(
          'flex items-center px-5 py-2 gap-2 rounded-md font-medium text-sm w-36 text-black-regular hover:bg-background hover:transition hover:text-black-light',
          {
            'bg-primary text-black-light hover:bg-primary-light hover:transition':
              menu === 'dashboard',
          }
        )}
      >
        <AiOutlineHome />
        <span>Resumo</span>
      </button>
      <button
        type="button"
        onClick={() => {
          setMenu('cupom');
          navigate('/cupom');
        }}
        className={classNames(
          'flex items-center px-5 py-2 gap-2 rounded-md font-medium text-sm w-36 text-black-regular hover:bg-background hover:transition hover:text-black-light',
          {
            'bg-primary text-black-light hover:bg-primary-light hover:transition':
              menu === 'cupom',
          }
        )}
      >
        <TbTicket />
        <span>Cupom</span>
      </button>
      <button
        type="button"
        onClick={() => {
          setMenu('brand');
          navigate('/brand');
        }}
        className={classNames(
          'flex items-center px-5 py-2 gap-2 rounded-md font-medium text-sm w-36 text-black-regular hover:bg-background hover:transition hover:text-black-light',
          {
            'bg-primary text-black-light hover:bg-primary-light hover:transition':
              menu === 'brand',
          }
        )}
      >
        <TbBrandVk />
        <span>Marcas</span>
      </button>
      <button
        type="button"
        onClick={() => {
          setMenu('category');
          navigate('/category');
        }}
        className={classNames(
          'flex items-center px-5 py-2 gap-2 rounded-md font-medium text-sm w-36 text-black-regular hover:bg-background hover:transition hover:text-black-light',
          {
            'bg-primary text-black-light hover:bg-primary-light hover:transition':
              menu === 'category',
          }
        )}
      >
        <BiCategoryAlt />
        <span>Categorias</span>
      </button>
      <button
        type="button"
        onClick={() => {
          setMenu('subcategory');
          navigate('/subcategory');
        }}
        className={classNames(
          'flex items-center px-5 py-2 gap-2 rounded-md font-medium text-sm w-36 text-black-regular hover:bg-background hover:transition hover:text-black-light',
          {
            'bg-primary text-black-light hover:bg-primary-light hover:transition':
              menu === 'subcategory',
          }
        )}
      >
        <MdOutlineCategory />
        <span>Subcategoria</span>
      </button>
      <button
        type="button"
        onClick={() => {
          setMenu('product');
          navigate('/product');
        }}
        className={classNames(
          'flex items-center px-5 py-2 gap-2 rounded-md font-medium text-sm w-36 text-black-regular hover:bg-background hover:transition hover:text-black-light',
          {
            'bg-primary text-black-light hover:bg-primary-light hover:transition':
              menu === 'product',
          }
        )}
      >
        <RiProductHuntLine />
        <span>Produtos</span>
      </button>
      <button
        type="button"
        onClick={() => {
          setMenu('subscription');
          navigate('/subscription');
        }}
        className={classNames(
          'flex items-center px-5 py-2 gap-2 rounded-md font-medium text-sm w-36 text-black-regular hover:bg-background hover:transition hover:text-black-light',
          {
            'bg-primary text-black-light hover:bg-primary-light hover:transition':
              menu === 'subscription',
          }
        )}
      >
        <MdOutlineVerifiedUser />
        <span>Assinaturas</span>
      </button>
      <button
        type="button"
        onClick={() => {
          setMenu('purshase');
          navigate('/purshase');
        }}
        className={classNames(
          'flex items-center px-5 py-2 gap-2 rounded-md font-medium text-sm w-36 text-black-regular hover:bg-background hover:transition hover:text-black-light',
          {
            'bg-primary text-black-light hover:bg-primary-light hover:transition':
              menu === 'purshase',
          }
        )}
      >
        <MdOutlineRequestQuote />
        <span>Pedidos</span>
      </button>
      <button
        type="button"
        onClick={() => {
          setMenu('user');
          navigate('/user');
        }}
        className={classNames(
          'flex items-center px-5 py-2 gap-2 rounded-md font-medium text-sm w-36 text-black-regular hover:bg-background hover:transition hover:text-black-light',
          {
            'bg-primary text-black-light hover:bg-primary-light hover:transition':
              menu === 'user',
          }
        )}
      >
        <RiUserSettingsLine />
        <span>Usuários</span>
      </button>
      <button
        type="button"
        onClick={() => {
          setMenu('notification');
          navigate('/notification');
        }}
        className={classNames(
          'flex items-center px-5 py-2 gap-2 rounded-md font-medium text-sm w-36 text-black-regular hover:bg-background hover:transition hover:text-black-light',
          {
            'bg-primary text-black-light hover:bg-primary-light hover:transition':
              menu === 'notification',
          }
        )}
      >
        <IoIosSend />
        <span>Notificações</span>
      </button>
      <button
        type="button"
        onClick={() => {
          signOut();
          navigate('/');
        }}
        className="absolute bottom-10 bg-background flex items-center px-5 py-2 gap-2 rounded-md font-medium text-sm w-36 text-black-light hover:bg-primary-light hover:transition hover:text-black-light"
      >
        <IoMdLogIn />
        <span>Sair</span>
      </button>
    </div>
  );
}
