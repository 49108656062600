import HttpClient from '../helpers/httpClient.helper';
import { AuthorizationToken } from './common/authorization.token';

class UserServices extends AuthorizationToken {
  private api;

  constructor() {
    super();
    this.api = new HttpClient(String(process.env.REACT_APP_API), this.token);
  }

  async getById(): Promise<void> {
    return await this.api.get('user/id');
  }

  async update(data: any): Promise<void> {
    return await this.api.put(`user`, data);
  }

  async getInvitions(): Promise<void> {
    return await this.api.get('user/invitions');
  }

  async getSubscriptionsPays(): Promise<void> {
    return await this.api.get('user/subscriptions/pays');
  }

  async getAll(): Promise<void> {
    return await this.api.get('user');
  }

  async changeRole(id: number, roleId: number): Promise<void> {
    return await this.api.put(`user/role/${id}`, {
      role_id: roleId,
    });
  }
}

export default UserServices;
