import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { removeItem, setItem } from '../helpers/storage.helper';

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    removeItem('pathname');
    setItem('pathname', pathname.replace('/', ''));
  }, [pathname]);

  return null;
}
