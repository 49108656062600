import { useContext, useEffect, useState } from 'react';
import { MdOutlineVerifiedUser } from 'react-icons/md';
import { BsEye, BsPrinter } from 'react-icons/bs';
import classNames from 'classnames';
import Layout from '../components/Layout';
import Modal from '../../../components/Modal';
import maskHelper from '../../../helpers/mask.helper';
import {
  translateStatusPay,
  translateStatusSubscription,
} from '../../../helpers/utils.helper';
import SelectCustom from '../../../components/Select';
import { UserServices, SubscriptionServices } from '../../../services';
import LoadingContext from '../../../contexts/loading.context';
import { statusSubscription } from '../mocks';

export default function Subscription() {
  const { loading, setLoading } = useContext(LoadingContext);
  const [details, setDetails] = useState(false);
  const [clients, setClients] = useState<any[]>([]);
  const [filterClient, setFilterClient] = useState<any>(null);
  const [filterStatus, setFilterStatus] = useState<any>({
    id: 'ACTIVE',
    title: 'Ativas',
  });
  const [dataDetails, setDataDetails] = useState<any>(null);
  const [data, setData] = useState({
    items: [],
    total: 0,
  });

  async function handleByFilter(): Promise<void> {
    setLoading({
      isOpen: true,
      title: 'Carregando...',
    });
    const responsePurshase: any = await new SubscriptionServices().getByFilters(
      filterClient?.id === 'ALL' ? '' : filterClient?.id,
      filterStatus?.id === 'ALL' ? '' : filterStatus?.id
    );

    let total = 0;
    responsePurshase.data.forEach((item: any) => {
      total += JSON.parse(item.pay_json).value;
    });

    setData({ items: responsePurshase.data, total });
    setLoading({ ...loading, isOpen: false });
  }

  async function handleClients(): Promise<void> {
    const responseCli: any = await new UserServices().getAll();

    const clientsPopulate: any = [
      {
        id: 'ALL',
        title: 'Todos clientes',
      },
    ];
    responseCli.data.forEach((item: any) => {
      clientsPopulate.push({
        id: item.id,
        title: item.name,
      });
    });

    setClients(clientsPopulate);
  }

  useEffect(() => {
    handleClients();
  }, []);

  useEffect(() => {
    handleByFilter();
  }, [filterClient, filterStatus]);

  return (
    <Layout
      legend={{
        value: `${maskHelper.formatMoeda(data.total)}`,
        text:
          !filterStatus || filterStatus?.id === 'ALL'
            ? `${data.items.length} Assinaturas`
            : `${data.items.length} ${filterStatus?.title}`,
      }}
    >
      {details && (
        <Modal
          title="Detalhes da assinatura"
          setIsOpen={setDetails}
          isOpen={details}
          size="4xlarge"
        >
          <div className="flex flex-col">
            <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between mt-1 mb-4">
              <div className="flex flex-col mb-6 sm:mb-0">
                <span className="font-bold mb-3 text-sm">
                  Dados da Assinatura
                </span>
                <div className="flex flex-col font-light text-xs gap-1">
                  <span>Criada em: {dataDetails?.subscription.created_at}</span>
                  <span>Status: {dataDetails?.subscription.status}</span>
                  <span>
                    Próxima cobrança: {dataDetails?.subscription.next_due_date}
                  </span>
                </div>
              </div>
              <div className="flex flex-col mb-6 sm:mb-0">
                <span className="font-bold mb-3 text-sm">Dados do Cliente</span>
                <div className="flex flex-col font-light text-xs gap-1">
                  <span>Nome: {dataDetails?.user.name}</span>
                  <span>E-mail: {dataDetails?.user.email}</span>
                  <span>Telefone: {dataDetails?.user.phone}</span>
                </div>
              </div>
              <div className="flex flex-col mb-6 sm:mb-0">
                <span className="font-bold mb-3 text-sm">
                  Endereço do Cliente
                </span>
                <div className="flex flex-col font-light text-xs gap-1">
                  <span>
                    {dataDetails?.user.address}, {dataDetails?.user.number} -{' '}
                    {dataDetails?.user.province}
                  </span>
                  <span>
                    {dataDetails?.user.city}/{dataDetails?.user.state}
                  </span>
                  <span>
                    {maskHelper.cep(String(dataDetails?.user.postal_code))}
                  </span>
                </div>
              </div>
            </div>
            <table className="table-auto w-full mt-4">
              <thead className="border-solid border-b-2 text-xs sm:text-sm bg-primary-light/25">
                <tr>
                  <th className="p-1 pl-2 text-left rounded-tl-md">
                    Assinatura
                  </th>
                  <th className="p-1 pl-2 text-left rounded-tl-md">Cobrança</th>
                  <th className="p-1 pl-2 text-center">Vencimento</th>
                  <th className="p-1 pl-2 text-center">Valor</th>
                  <th className="p-1 pl-2 text-center">Status</th>
                  <th className="p-1 pl-2 text-center">Pagamento</th>
                  <th className="p-1 pl-2 text-center rounded-tr-md">&nbsp;</th>
                </tr>
              </thead>
              <tbody className="font-light text-xs sm:text-sm">
                {dataDetails.subscription_items.map((item: any) => (
                  <tr>
                    <td className="p-1 pl-2 text-left">
                      {dataDetails.subscription.id}
                    </td>
                    <td className="p-1 pl-2 text-left">{item.payment_id}</td>
                    <td className="p-1 pl-2 text-center">
                      {maskHelper.formatDateDMY(
                        JSON.parse(item.pay_json).dueDate
                      )}
                    </td>
                    <td className="p-1 pl-2 text-center">
                      {maskHelper.formatMoeda(JSON.parse(item.pay_json).value)}
                    </td>
                    <td className="p-1 pl-2 text-center">
                      {translateStatusPay(item.status)}
                    </td>
                    <td className="p-1 pl-2 text-center">
                      {maskHelper.formatDateDMY(
                        JSON.parse(item.pay_json).confirmedDate ||
                          JSON.parse(item.pay_json).paymentDate ||
                          JSON.parse(item.pay_json).clientPaymentDate ||
                          (item.status === 'CONFIRMED' ||
                          item.status === 'RECEIVED'
                            ? JSON.parse(item.pay_json).dueDate
                            : '')
                      )}
                    </td>
                    <td className="p-1 pl-2 text-center">
                      {(JSON.parse(item.pay_json).confirmedDate ||
                        JSON.parse(item.pay_json).paymentDate ||
                        JSON.parse(item.pay_json).clientPaymentDate ||
                        item.status === 'CONFIRMED' ||
                        item.status === 'RECEIVED') && (
                        <a
                          href={JSON.parse(item.pay_json).transactionReceiptUrl}
                          target="_blank"
                          rel="noreferrer"
                          className="cursor-pointer"
                        >
                          <BsPrinter size={15} color="#32BC50" />
                        </a>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Modal>
      )}

      <div className="w-full bg-white rounded-lg shadow-xs sm:min-h-[500px] 2xl:min-h-[700px] h-fit">
        <div className="flex flex-row justify-between items-center mb-8 relative">
          <div className="flex flex-row items-center gap-2">
            <MdOutlineVerifiedUser size={22} color="#DC7E19" />
            <span className="font-bold text-lg">Assinaturas</span>
          </div>
          <div className="flex gap-2">
            <SelectCustom
              placeholder="Pesquise pelo nome"
              data={clients}
              defaultValue={
                !filterClient
                  ? { id: 0, title: 'Pesquise pelo nome' }
                  : filterClient
              }
              noOptionsMessage="Cliente não encontrado"
              onChange={setFilterClient}
              width="250px"
            />
            <SelectCustom
              placeholder="Pesquise pelo status"
              data={statusSubscription}
              defaultValue={
                !filterStatus
                  ? { id: 0, title: 'Pesquise pelo status' }
                  : filterStatus
              }
              noOptionsMessage="Status não encontrado"
              onChange={setFilterStatus}
              width="180px"
            />
          </div>
        </div>
        <table className="text-left w-full">
          <thead className="flex w-full border-solid border-b-2 text-xs sm:text-sm bg-primary-light/25">
            <tr className="flex w-full">
              <th className="p-2 text-left w-[12%] rounded-tl-sm">Data</th>
              <th className="p-2 text-left w-[29%]">Assinante</th>
              <th className="p-2 text-left w-[29%]">E-mail</th>
              <th className="p-2 text-left w-1/6">Telefone</th>
              <th className="p-2 text-left">Status</th>
              <th className="p-2 text-left">Valor</th>
              <th className="p-2 text-left w-1/6">Próxima</th>
              <th className="p-2 text-left rounded-tr-sm mr-5">ver</th>
            </tr>
          </thead>
          <tbody
            className="font-light text-xs sm:text-sm flex flex-col  overflow-y-auto w-full"
            style={{ height: data.items.length > 10 ? '60vh' : '' }}
          >
            {data.items.map((item: any) => {
              let indicatedName = 'Nenhuma';

              if (item.user.user_id_parent) {
                indicatedName = item.user.user.name;

                if (indicatedName.includes(' ')) {
                  const indicatedArr = indicatedName.split(' ');

                  if (
                    indicatedArr[1] === 'de' ||
                    indicatedArr[1] === 'da' ||
                    indicatedArr[1] === 'do'
                  ) {
                    indicatedName = `${indicatedArr[0]} ${indicatedArr[2]}`;
                  } else {
                    indicatedName = `${indicatedArr[0]} ${indicatedArr[1]}`;
                  }
                }
              }

              return (
                <tr key={Math.random()} className="flex items-center w-full">
                  <td className="p-2 text-left w-[12%]">
                    {maskHelper.formatDateDMY(item.created_at)}
                  </td>
                  <td className="p-2 text-left w-[29%]">
                    <p>{item.user.name}</p>
                    <p
                      className={classNames(
                        'text-xs fo text-primary font-bold',
                        {
                          'text-black-regular': indicatedName === 'Nenhuma',
                        }
                      )}
                    >
                      Indicação: {indicatedName}
                    </p>
                  </td>
                  <td className="p-2 text-left w-[29%]">{item.user.email}</td>
                  <td className="p-2 text-left w-1/6">
                    {maskHelper.phone(item.user.phone)}
                  </td>
                  <td className="p-2 text-left flex items-center gap-2">
                    {translateStatusSubscription(item.status)}
                  </td>
                  <td className="p-2 text-left">
                    {maskHelper.formatMoeda(JSON.parse(item.pay_json).value)}
                  </td>
                  <td className="p-2 text-left w-1/6">
                    {maskHelper.formatDateDMY(
                      JSON.parse(item.pay_json)?.nextDueDate || ''
                    )}
                  </td>
                  <td
                    className="p-2 text-left cursor-pointer mr-5"
                    onClick={() => {
                      setDetails(true);
                      setDataDetails({
                        subscription: {
                          created_at: maskHelper.formatDateDMY(item.created_at),
                          next_due_date: maskHelper.formatDateDMY(
                            JSON.parse(item.pay_json)?.nextDueDate
                          ),
                          status: translateStatusSubscription(item.status),
                          id: item.subscription,
                        },
                        subscription_items: item.subscription_items,
                        user: item.user,
                      });
                    }}
                    aria-hidden="true"
                  >
                    <BsEye color="#DC7E19" size={20} />
                  </td>
                </tr>
              );
            })}
            {!loading.isOpen && data.items.length === 0 && (
              <tr>
                <td className="p-1 text-left">
                  Nenhuma assinatura encontrada.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </Layout>
  );
}
