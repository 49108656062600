import { useContext, useEffect, useState } from 'react';
import ReactLoading from 'react-loading';
import { AiOutlinePlus } from 'react-icons/ai';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { FaEdit } from 'react-icons/fa';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import { useDropzone } from 'react-dropzone';
import { MdOutlineCategory } from 'react-icons/md';
import { CategoryServices, SubcategoryServices } from '../../../services';
import Layout from '../components/Layout';
import Modal from '../../../components/Modal';
import Switch from '../../../components/Switch';
import AlertContext from '../../../contexts/alert.context';
import SelectCustom from '../../../components/Select';
import LoadingContext from '../../../contexts/loading.context';
import { units } from '../mocks';

export default function SubCategory() {
  const { alert, setAlert } = useContext(AlertContext);
  const { loading, setLoading } = useContext(LoadingContext);
  const [loadingSave, setLoadingSave] = useState(false);
  const [isOpenForm, setIsOpenForm] = useState(false);
  const [data, setData] = useState([]);
  const [categorys, setCategorys] = useState([]);
  const [file, setFile] = useState<any>([]);
  const [item, setItem] = useState<any>({
    id: '',
    category: null,
    title: '',
    subtitle: '',
    unit: '',
    description: '',
    file: null,
    active: false,
    favorite: false,
  });
  const [errorForm, setErrorForm] = useState<any>(null);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    maxFiles: 1,
    multiple: false,
    onDrop: (acceptedFiles: any) => {
      const fileCreateObj = acceptedFiles.map((f: any) =>
        Object.assign(f, {
          preview: URL.createObjectURL(f),
        })
      );

      if (!'image/png,image/jpg,image/jpeg'.includes(fileCreateObj[0].type)) {
        toast.warn('O formato deve ser PNG ou JPEG');
        return;
      }

      setFile(fileCreateObj);
      setItem({ ...item, file: fileCreateObj });
    },
  });

  async function handleGetCategorys(): Promise<void> {
    const response: any = await new CategoryServices().getAll();
    setCategorys(response.data);
  }

  async function handleGetAll(): Promise<void> {
    setLoading({
      isOpen: true,
      title: 'Carregando...',
    });
    const response: any = await new SubcategoryServices().getAll();
    setData(response.data);
    setLoading({ ...loading, isOpen: false });
  }

  async function handleRemove(id: number): Promise<void> {
    await toast.promise(
      async () => {
        const response: any = await new SubcategoryServices().delete(id);

        if (response.data === 'error_exist_product') {
          throw new Error('error_exist_product');
        }

        await handleGetAll();
      },
      {
        pending: 'Processando',
        success: `Subcategoria removida com sucesso!`,
        error: 'Esta Subcategoria possui produto vinculado!',
      }
    );
  }

  async function onSubmit(): Promise<void> {
    try {
      setErrorForm(null);

      let errors = null;
      if (!!item.title === false) {
        errors = { title: true };
      }

      if (!!item.subtitle === false) {
        errors = { ...errors, subtitle: true };
      }

      if (!!item.unit === false) {
        errors = { ...errors, unit: true };
      }

      if (!!item.description === false) {
        errors = { ...errors, description: true };
      }

      if (!!item.category === false) {
        errors = { ...errors, category: true };
      }

      if (!!item.file === false && !!item.path === false) {
        errors = { ...errors, file: true };
      }

      if (errors) {
        setErrorForm(errors);
        toast.warn('Campo(s) obrigatório(s)!');
        return;
      }

      setLoadingSave(true);

      await toast.promise(
        async () => {
          const formData = new FormData();

          formData.append('title', item.title);
          formData.append('category_id', item.category_id);
          formData.append('subtitle', item.subtitle);
          formData.append('unit', item.unit);
          formData.append('description', item.description);
          formData.append('active', item.active);
          formData.append('favorite', item.favorite);

          if (item.file) formData.append('file', item?.file[0]);
          else formData.append('path', item.path);

          if (item.id) {
            await new SubcategoryServices().update(item.id, formData);
          } else {
            await new SubcategoryServices().create(formData);
          }

          setIsOpenForm(false);
          await handleGetAll();
        },
        {
          pending: 'Processando',
          success: `Subcategoria ${
            item.id ? 'atualizada' : 'criada'
          } com sucesso!`,
          error: 'Tente novamente mais tarde :(',
        }
      );
    } catch (e: any) {
      console.log(e);
    } finally {
      setLoadingSave(false);
    }
  }

  useEffect(() => {
    handleGetCategorys();
    handleGetAll();
  }, []);

  useEffect(() => {
    if (!isOpenForm) {
      setFile([]);
      setItem({
        id: '',
        category: null,
        title: '',
        subtitle: '',
        unit: '',
        description: '',
        file: null,
        active: false,
        favorite: false,
      });
    }
  }, [isOpenForm]);

  return (
    <Layout
      legend={{
        value: data.length,
        text: 'Subcategoria',
      }}
    >
      {isOpenForm && (
        <Modal
          title={`${item.id ? 'Atualizar' : 'Nova'} subcategoria`}
          setIsOpen={setIsOpenForm}
          isOpen={isOpenForm}
        >
          <form id="form" autoComplete="off">
            {file.length > 0 ? (
              <div className="flex flex-col items-center gap-1 justify-center">
                <a href={file[0].preview} target="_blank" rel="noreferrer">
                  <img
                    alt="Carletto"
                    src={file[0].preview}
                    className="rounded-md w-[100px] h-[90px]"
                  />
                </a>
                <RiDeleteBin6Line
                  size={17}
                  color="#aaa"
                  onClick={() => {
                    setFile([]);
                    setItem({ ...item, file: null, path: '' });
                  }}
                  className="cursor-pointer"
                />
              </div>
            ) : (
              <div
                {...getRootProps()}
                className={classNames(
                  'border-[1px] border-dashed border-[#aaa] p-4 rounded-md cursor-pointer bg-background mb-4 relative',
                  {
                    'border-[#DC7E19]': isDragActive,
                  }
                )}
              >
                <input {...getInputProps()} />
                {isDragActive ? (
                  <p className="font-light text-sm text-primary">
                    Solte a imagem aqui...
                  </p>
                ) : (
                  <p className="font-light text-sm text-center text-[#555]">
                    Arraste e solte aqui ou clique para selecionar um arquivo
                  </p>
                )}

                <span
                  className={classNames(
                    'absolute text-red text-[0.60rem] left-1 bottom-[-15px]',
                    {
                      hidden: !errorForm?.file,
                    }
                  )}
                >
                  {errorForm?.file ? 'Obrigatório' : ''}
                </span>
              </div>
            )}
            <div className="flex flex-col mt-2 gap-1 relative">
              <span className="font-light text-xs">Título</span>
              <input
                className="border-solid border-[0.5px] border-[#aaa] p-3 rounded-md text-xs w-full focus:border-primary focus:border"
                placeholder="Ex: Picanha"
                onChange={(e) => setItem({ ...item, title: e.target.value })}
                value={item.title}
              />
              <span
                className={classNames(
                  'absolute text-red text-[0.60rem] left-1 bottom-[-15px]',
                  {
                    hidden: !errorForm?.title,
                  }
                )}
              >
                {errorForm?.title ? 'Obrigatório' : ''}
              </span>
            </div>
            <div className="flex flex-col mt-4 relative">
              <span className="font-light text-xs">Categoria</span>
              <SelectCustom
                placeholder="Qual a categoria?"
                data={categorys}
                borderColor="#ddd"
                defaultValue={
                  !item?.category
                    ? { id: '', title: 'Qual a categoria?' }
                    : categorys.filter((i: any) => i.id === item.category.id)[0]
                }
                noOptionsMessage="Não encontrado"
                marginTop={4}
                onChange={(e: any) =>
                  setItem({
                    ...item,
                    category_id: e.id,
                    category: e,
                  })
                }
              />
              <span
                className={classNames(
                  'absolute text-red text-[0.60rem] left-1 bottom-[-15px]',
                  {
                    hidden: !errorForm?.category,
                  }
                )}
              >
                {errorForm?.category ? 'Obrigatório' : ''}
              </span>
            </div>
            <div className="flex flex-col mt-4 relative">
              <span className="font-light text-xs">Unidade</span>
              <SelectCustom
                placeholder="Qual a unidade?"
                data={units}
                borderColor="#ddd"
                defaultValue={
                  !item?.unit
                    ? { id: '', title: 'Qual a unidade?' }
                    : units.filter((i: any) => i.id === item.unit)[0]
                }
                noOptionsMessage="Não encontrado"
                marginTop={4}
                onChange={(e: any) =>
                  setItem({
                    ...item,
                    unit: e.id,
                  })
                }
              />
              <span
                className={classNames(
                  'absolute text-red text-[0.60rem] left-1 bottom-[-15px]',
                  {
                    hidden: !errorForm?.unit,
                  }
                )}
              >
                {errorForm?.unit ? 'Obrigatório' : ''}
              </span>
            </div>
            <div className="flex flex-col mt-4 gap-1 relative">
              <span className="font-light text-xs">Subtítulo</span>
              <input
                className="border-solid border-[0.5px] border-[#aaa] p-3 rounded-md text-xs w-full focus:border-primary focus:border"
                placeholder="Ex: Picanha bovina"
                onChange={(e) => setItem({ ...item, subtitle: e.target.value })}
                value={item.subtitle}
              />
              <span
                className={classNames(
                  'absolute text-red text-[0.60rem] left-1 bottom-[-15px]',
                  {
                    hidden: !errorForm?.subtitle,
                  }
                )}
              >
                {errorForm?.subtitle ? 'Obrigatório' : ''}
              </span>
            </div>
            <div className="flex flex-col mt-4 gap-1 relative">
              <span className="font-light text-xs">Descrição</span>
              <textarea
                rows={5}
                className="border-solid border-[0.5px] border-[#aaa] p-3 rounded-md text-xs w-full focus:border-primary focus:border"
                placeholder="Ex: Picanha bovina"
                onChange={(e) =>
                  setItem({ ...item, description: e.target.value })
                }
                value={item.description}
              />
              <span
                className={classNames(
                  'absolute text-red text-[0.60rem] left-1 bottom-[-15px]',
                  {
                    hidden: !errorForm?.description,
                  }
                )}
              >
                {errorForm?.description ? 'Obrigatório' : ''}
              </span>
            </div>
            <div className="mt-6 flex items-center gap-2">
              <Switch
                state={item.active}
                onChange={() => setItem({ ...item, active: !item.active })}
                handleDiameter={22}
                width={47}
                height={26}
              />
              <span className="font-light text-sm">
                {item.active ? 'Ativo' : 'Descontinuado'}
              </span>
            </div>
            <div className="mt-6 flex items-center gap-2">
              <Switch
                state={item.favorite}
                onChange={() => setItem({ ...item, favorite: !item.favorite })}
                handleDiameter={22}
                width={47}
                height={26}
              />
              <span className="font-light text-sm">
                {item.favorite ? 'Destaque' : 'Não destacado'}
              </span>
            </div>

            <div className="flex gap-3 mt-7 justify-end">
              <button
                type="button"
                form="form"
                disabled={loadingSave}
                onClick={onSubmit}
                className={classNames(
                  'relative rounded-md bg-primary p-2 pl-6 pr-6 font-Inter font-bold text-xs w-36',
                  {
                    'bg-opacity-20 text-gray-400 cursor-not-allowed hover:bg-opacity-20':
                      loadingSave,
                  }
                )}
              >
                <span>Salvar</span>
                <ReactLoading
                  type="spokes"
                  color="#DC7E19"
                  width={15}
                  className={classNames('absolute right-3 top-2', {
                    hidden: !loadingSave,
                  })}
                />
              </button>
            </div>
          </form>
        </Modal>
      )}

      <div className="w-full bg-white rounded-lg shadow-xs sm:min-h-[500px] 2xl:min-h-[700px] h-fit">
        <div className="flex flex-row justify-between items-center mb-8 relative">
          <div className="flex flex-row items-center gap-2">
            <MdOutlineCategory size={22} color="#DC7E19" />
            <span className="font-bold text-lg">Subcategoria</span>
          </div>

          <button
            type="button"
            onClick={() => {
              setIsOpenForm(true);
            }}
            className="flex items-center gap-1 border-primary text-primary border-solid border-[1px] px-3 py-[0.4rem] rounded-md text-xs transition hover:text-black-light hover:bg-primary"
          >
            <AiOutlinePlus />
            <span>adicionar</span>
          </button>
        </div>
        <table className="table-auto w-full">
          <thead className="flex w-full border-solid border-b-2 text-xs sm:text-sm bg-primary-light/25">
            <tr className="flex w-full">
              <th className="p-1 text-left rounded-tl-sm w-1/12">&nbsp;</th>
              <th className="p-1 text-left w-1/6">Título</th>
              <th className="p-1 text-left w-1/6">Categoria</th>
              <th className="p-1 text-left w-1/6">Unidade</th>
              <th className="p-1 text-left w-11/12">Descrição</th>
              <th className="p-1 text-left w-1/6">Subtítulo</th>
              <th className="p-1 text-left w-1/6">Status</th>
              <th className="p-1 text-left w-1/6">Destaque</th>
              <th className="p-1 text-center w-1/12">&nbsp;</th>
              <th className="p-1 text-center rounded-tr-sm w-1/12">&nbsp;</th>
            </tr>
          </thead>
          <tbody className="font-light text-xs sm:text-sm overflow-y-auto block max-h-[31rem] 2xl:max-h-[40rem]">
            {data.map((cat: any) => (
              <tr
                key={cat.id}
                className={classNames('flex items-center w-full', {
                  'text-black-regular': !cat.active,
                })}
              >
                <td className="p-1 text-left w-1/12">
                  <a
                    href={`${process.env.REACT_APP_API}/${cat.path}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      alt="Carletto"
                      src={`${process.env.REACT_APP_API}/${cat.path}`}
                      loading="lazy"
                      className="w-[100px] h-[40px] object-cover rounded-md"
                    />
                  </a>
                </td>
                <td className="p-1 text-left w-1/6">{cat.title}</td>
                <td className="p-1 text-left w-1/6">{cat.category.title}</td>
                <td className="p-1 text-left w-1/6">{cat.unit}</td>
                <td className="p-1 text-left w-11/12">{cat.description}</td>
                <td className="p-1 text-left w-1/6">{cat.subtitle}</td>
                <td className="p-1 text-left w-1/6">
                  {cat.active ? 'Ativo' : 'Inativo'}
                </td>
                <td className="p-1 text-left w-1/6">
                  {cat.favorite ? 'Sim' : 'Não'}
                </td>

                <td className="p-1 text-left w-1/12">
                  <FaEdit
                    className="cursor-pointer"
                    color="#DC7E19"
                    size={16}
                    onClick={() => {
                      setItem({
                        ...cat,
                      });
                      setFile([
                        {
                          preview: `${process.env.REACT_APP_API}/${cat.path}`,
                        },
                      ]);
                      setIsOpenForm(true);
                    }}
                  />
                </td>
                <td className="p-1 text-left w-1/12">
                  <RiDeleteBin6Line
                    className="cursor-pointer"
                    color="#B0B0B0"
                    size={16}
                    onClick={() =>
                      setAlert({
                        title: 'Atenção!',
                        description: 'Confirma a exclusão deste corte?',
                        isOpen: true,
                        button: [
                          {
                            title: 'Não',
                            type: 'black-regular',
                            onClick: () =>
                              setAlert({ ...alert, isOpen: false }),
                          },
                          {
                            title: 'Sim, confirmar',
                            type: 'red',
                            onClick: () => {
                              handleRemove(cat.id);
                              setAlert({ ...alert, isOpen: false });
                            },
                          },
                        ],
                      })
                    }
                  />
                </td>
              </tr>
            ))}

            {!loading.isOpen && data.length === 0 && (
              <tr>
                <td className="p-1 text-left">Nenhum corte cadastrado.</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </Layout>
  );
}
