import HttpClient from '../helpers/httpClient.helper';
import { AuthorizationToken } from './common/authorization.token';

class ProductServices extends AuthorizationToken {
  private api;

  constructor() {
    super();
    this.api = new HttpClient(String(process.env.REACT_APP_API), this.token);
  }

  async create(data: any): Promise<void> {
    return await this.api.post('product', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  async update(id: number, data: any): Promise<void> {
    return await this.api.put(`product/${id}`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  async delete(id: number): Promise<void> {
    return await this.api.delete(`product/${id}`);
  }

  async getAll(): Promise<void> {
    return await this.api.get('product');
  }
}

export default ProductServices;
