import React, { createContext, useMemo } from 'react';
import useSession from './hooks/useSession';

interface SessionContextData {
  user: any;
  isAuthenticated: boolean;
  loading: boolean;
  menu: string;
  setMenu: (e: string) => void;
  /* eslint  no-unused-vars: off */
  signIn(email: string, password: string): Promise<any>;
  signOut: () => void;
}

const SessionContext = createContext<SessionContextData>(
  {} as SessionContextData
);

interface SessionProviderProps {
  children: React.ReactNode;
}

export const SessionProvider: React.FC<SessionProviderProps> = ({
  children,
}) => {
  const { user, menu, setMenu, loading, isAuthenticated, signIn, signOut } =
    useSession();

  const value = useMemo(
    () => ({
      user,
      loading,
      isAuthenticated,
      menu,
      setMenu,
      signIn,
      signOut,
    }),
    [user, loading, isAuthenticated, signIn, signOut]
  );

  return (
    <SessionContext.Provider value={value}>{children}</SessionContext.Provider>
  );
};

export default SessionContext;
