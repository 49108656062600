import { MdOutlineEmail } from 'react-icons/md';
import { HiOutlineLockClosed } from 'react-icons/hi';
import { Link, useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames';
import ReactLoading from 'react-loading';
import { toast } from 'react-toastify';
import { motion } from 'framer-motion';
import yup from '../../../config/yup';

import Footer from '../../../components/Footer';
import Copyright from '../../../components/Copyright';
import Header from '../../../components/Header';
import SessionContext from '../../../contexts/session.context';
import photoLogin from '../../../assets/images/photo_login.png';

interface IForm {
  email: string;
  password: string;
}

export default function LoginPage() {
  const navigate = useNavigate();
  const { loading, signIn } = useContext(SessionContext);

  const schema = yup
    .object({
      email: yup.string().email().required(),
      password: yup.string().required(),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IForm>({
    resolver: yupResolver(schema),
  });

  async function onSubmit(data: IForm): Promise<void> {
    try {
      const response: any = await signIn(data.email, data.password);

      if (response === 'error_not_exist') {
        toast.warn('E-mail não encontrado!');
        return;
      }

      if (response === 'error_credential_invalid') {
        toast.warn('Credenciais inválidas!');
        return;
      }

      navigate('/products');
    } catch (e: any) {
      console.log('a');
    }
  }

  return (
    <div className="containerScreen">
      <div className="main">
        <Header isDivideMarginTop isApresentation={false} />

        <motion.section
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="flex flex-row justify-center px-0 py-0 sm:px-56 sm:py-5 md:py-10 lg:py-5 xl:pt-10 lg:pb-5 bg-background max-h-[90%] 2xl:h-full"
        >
          <div className="flex justify-center">
            <div className="relative hidden xl:flex ">
              <img
                alt="Carletto"
                src={photoLogin}
                loading="lazy"
                className="bottom-12 left-24"
                width="400"
              />
            </div>
            <div className="flex flex-col relative items-center justify-center bg-white min-w-[330px] sm:w-[390px] min-h-[500px] sm:h-[96.5%] shadow-lg rounded-r-lg">
              <span className="w-full self-start ml-[15%] text-2xl font-bold mt-10 sm:mt-0">
                Olá novamente
              </span>
              <span className="w-full self-start ml-[15%] text-sm font-light mb-6">
                Seja bem vindo de volta!
              </span>
              <form
                id="form-auth"
                onSubmit={handleSubmit(onSubmit)}
                autoComplete="off"
                className="flex flex-col w-[70%] gap-5"
              >
                <div className="relative">
                  <input
                    onClick={() => {}}
                    className={classNames(
                      'border-[1px] border-solid border-black-regular placeholder:text-black-regular p-[0.65rem] pl-7 rounded-md text-xs w-[100%] focus:border-primary',
                      {
                        'border-red': !!errors.email,
                      }
                    )}
                    placeholder="E-mail"
                    {...register('email')}
                  />
                  <MdOutlineEmail
                    size={15}
                    color="#B0B0B0"
                    className="absolute top-[12px] left-2"
                  />
                  <span
                    className={classNames(
                      'absolute text-red text-[0.60rem] left-1 bottom-[-15px]',
                      {
                        hidden: !!errors.email === false,
                      }
                    )}
                  >
                    {errors.email?.message}
                  </span>
                </div>
                <div className="relative mb-4">
                  <input
                    type="password"
                    onClick={() => {}}
                    className={classNames(
                      'border-[1px] border-solid border-black-regular placeholder:text-black-regular p-[0.65rem] pl-7 rounded-md text-xs w-[100%] focus:border-primary',
                      {
                        'border-red': !!errors.password,
                      }
                    )}
                    placeholder="Senha"
                    {...register('password')}
                  />
                  <HiOutlineLockClosed
                    size={15}
                    color="#B0B0B0"
                    className="absolute top-[12px] left-2"
                  />
                  <span
                    className={classNames(
                      'absolute text-red text-[0.60rem] left-1 bottom-[-15px]',
                      {
                        hidden: !!errors.password === false,
                      }
                    )}
                  >
                    {errors.password?.message}
                  </span>
                </div>
                <button
                  type="submit"
                  form="form-auth"
                  disabled={loading}
                  className={classNames(
                    'bg-primary p-[0.65rem] pl-4 pr-4 rounded-md text-xs focus:border-primary font-bold transition shadow-lg hover:bg-opacity-80 relative',
                    {
                      'bg-opacity-20 text-gray-400 cursor-not-allowed hover:bg-opacity-20':
                        loading,
                    }
                  )}
                >
                  <span>Entrar</span>
                  <ReactLoading
                    type="spokes"
                    color="#DC7E19"
                    width={17}
                    className={classNames('absolute right-3 top-2', {
                      hidden: !loading,
                    })}
                  />
                </button>
              </form>
              <Link
                to="/recovery-pass"
                className=" text-primary p-3 pl-4 pr-4 rounded-md text-xs"
              >
                Esqueci minha senha
              </Link>
              <div className="flex items-center justify-center w-full mt-2">
                <div className="bg-black-light h-[1px] w-[30%]" />
                <p className="font-light text-lg text-center mx-2">ou</p>
                <div className="bg-black-light h-[1px] w-[30%]" />
              </div>
              <p className="font-light text-xs px-[70px] mt-5">
                Não possui uma conta?{' '}
                <Link to="/register" className="font-bold">
                  Clique aqui
                </Link>{' '}
                para se cadastrar.
              </p>
            </div>
          </div>
        </motion.section>

        <Footer />
        <Copyright />
      </div>
    </div>
  );
}
