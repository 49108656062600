import React, { createContext, useMemo } from 'react';
import usePurshase, { PurshaseTypes } from './hooks/usePurshase';

interface PurshaseContextData {
  /* eslint  no-unused-vars: off */
  purshase: PurshaseTypes | null;
  getPurshase(): PurshaseTypes;

  addPurshase(
    productId: number,
    productWeightRangeId: number,
    qty: number,
    discount: number,
    priceByKg: number,
    productJson: any,
    productWeightRangeJson: any
  ): void;
  delPurshase(productWeightId: number): void;
  delAllPurshase(): void;
  addQtdPurshase(
    productId: number,
    productWeightId: number,
    qty?: number
  ): void;
  delQtdPurshase(
    productId: number,
    productWeightId: number,
    qty?: number,
    msg?: boolean
  ): void;
  getExistProductPurshase(
    productId: number,
    productWeightRangeId: number
  ): boolean;
  addCupomPurshase(cupom: any): void;
}

const PurshaseContext = createContext<PurshaseContextData>(
  {} as PurshaseContextData
);

interface PurshaseProviderProps {
  children: React.ReactNode;
}

export const PurshaseProvider: React.FC<PurshaseProviderProps> = ({
  children,
}) => {
  const {
    purshase,
    addPurshase,
    delPurshase,
    addQtdPurshase,
    delQtdPurshase,
    delAllPurshase,
    getPurshase,
    getExistProductPurshase,
    addCupomPurshase,
  } = usePurshase();

  const value = useMemo(
    () => ({
      purshase,
      addPurshase,
      delPurshase,
      addQtdPurshase,
      delQtdPurshase,
      delAllPurshase,
      getPurshase,
      getExistProductPurshase,
      addCupomPurshase,
    }),
    [
      purshase,
      addPurshase,
      delPurshase,
      addQtdPurshase,
      delQtdPurshase,
      delAllPurshase,
      getPurshase,
      getExistProductPurshase,
      addCupomPurshase,
    ]
  );

  return (
    <PurshaseContext.Provider value={value}>
      {children}
    </PurshaseContext.Provider>
  );
};

export default PurshaseContext;
