import HttpClient from '../helpers/httpClient.helper';
import { AuthorizationToken } from './common/authorization.token';

class SubcategoryServices extends AuthorizationToken {
  private api;

  constructor() {
    super();
    this.api = new HttpClient(String(process.env.REACT_APP_API), this.token);
  }

  async create(data: any): Promise<void> {
    return await this.api.post('category-items', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  async update(id: number, data: any): Promise<void> {
    return await this.api.put(`category-items/${id}`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  async delete(id: number): Promise<void> {
    return await this.api.delete(`category-items/${id}`);
  }

  async getAll(): Promise<void> {
    return await this.api.get('category-items');
  }
}

export default SubcategoryServices;
