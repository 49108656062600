import React from 'react';
import Select, { defaultTheme } from 'react-select';

type dataProps = {
  id: number | string;
  title: string;
};

interface SelectCustomProps {
  placeholder: string;
  noOptionsMessage: string;
  defaultValue?: any;
  data: dataProps[];
  marginTop?: number;
  borderColor?: string;
  width?: string;
  /* eslint-disable no-unused-vars */
  onChange: (e?: any) => void;
}

const SelectCustom: React.FC<SelectCustomProps> = ({
  placeholder,
  noOptionsMessage,
  defaultValue,
  data,
  marginTop = 10,
  borderColor = '#DC7E19',
  width = '100%',
  onChange,
}) => {
  const customStyles = {
    option: (provided: any) => ({
      ...provided,
      fontSize: 13,
      color: 'black',
      padding: 10,
      cursor: 'pointer',
      background: 'white',
      '&:hover': {
        background: '#FFF4DF',
      },
    }),
    noOptionsMessage: (provided: any) => ({
      ...provided,
      fontSize: 14,
    }),
    control: () => ({
      width,
      height: 40,
      borderRadius: 6,
      border: `1px solid ${borderColor}`,
      marginTop,
      fontSize: 13,
      display: 'flex',
      cursor: 'pointer',
    }),
    singleValue: (provided: any, state: any) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';

      return {
        ...provided,
        opacity,
        transition,
      };
    },
  };

  return (
    <Select
      styles={customStyles}
      value={defaultValue}
      defaultValue={defaultValue}
      onChange={(value) => onChange(value)}
      theme={{
        ...defaultTheme,
        colors: { ...defaultTheme.colors, primary: 'var(--color-black)' },
      }}
      placeholder={placeholder}
      getOptionLabel={(item) => item.title}
      options={data}
      noOptionsMessage={() => noOptionsMessage}
    />
  );
};

export default SelectCustom;
