import React from 'react';
import { useNavigate } from 'react-router-dom';
import banner1 from '../../../assets/images/banner1.png';
import banner2 from '../../../assets/images/banner2.png';

const PromotionalBannerSmall: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className="py-2 flex items-center justify-between flex-col lg:flex-row w-full gap-10 lg:gap-2 px-5 lg:px-32 pt-3 bg-background">
      <img
        alt="Carletto"
        src={banner1}
        loading="lazy"
        className="w-full lg:w-[48%] xl:w-[48%] 2xl:w-[49%] cursor-pointer sm:hover:scale-105 sm:ease-in-out sm:duration-300"
        onClick={() => navigate('/products')}
      />
      <img
        alt="Carletto"
        src={banner2}
        loading="lazy"
        className="w-full lg:w-[48%] xl:w-[48%] 2xl:w-[49%] cursor-pointer sm:hover:scale-105 sm:ease-in-out sm:duration-300"
        onClick={() => navigate('/products')}
      />
    </div>
  );
};

export default PromotionalBannerSmall;
