import { AiFillInfoCircle } from 'react-icons/ai';

import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import Footer from '../../../components/Footer';
import Copyright from '../../../components/Copyright';
import Header from '../../../components/Header';
import PurshaseContext from '../../../contexts/purshase.context';
import maskHelper from '../../../helpers/mask.helper';

export default function WarningStockPage() {
  const { purshase } = useContext(PurshaseContext);
  const navigate = useNavigate();

  return (
    <div className="z-0 h-screen relative flex justify-center">
      <div className="bg-white relative w-full h-screen">
        <Header
          isDivideMarginTop
          title="Nossos produtos"
          description="As melhores peças para você."
          isApresentation={false}
        />

        <motion.section
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="flex justify-center items-center px-0 lg:px-56 bg-background h-fit p-0 sm:p-20"
        >
          <div className="w-[100%] sm:w-[550px] bg-white rounded-md shadow-lg p-7 h-fit">
            <div className="flex items-start gap-2 p-3 text-xs bg-primary bg-opacity-10 rounded-md border-solid border-[1px] border-primary mb-4">
              <AiFillInfoCircle size={20} color="#DC7E19" />
              <p className="font-light">
                Alguns produtos tiveram ajuste na quantidade solicitada
                <br />
                por falta no estoque.
              </p>
            </div>

            <div className="mt-6">
              <p className="font-bold text-sm text-left">
                Produtos atualizados no carrinho
              </p>
              <p className="font-light text-xs text-left mb-7">
                Você pode continuar comprando ou finalizar o pagamento:
              </p>

              {purshase?.items.map((item: any) => (
                <div
                  className="flex items-center justify-between mb-2"
                  key={Math.random()}
                >
                  <p className="text-sm font-regular">
                    <span className="font-light mr-2 text-slate-400">
                      {item.qty}x
                    </span>{' '}
                    {item.product_json.category_items.subtitle}
                  </p>
                  <span className="text-sm font-light hidden sm:flex">
                    {item.product_weight_range_json.weight_start} a{' '}
                    {item.product_weight_range_json.weight_end} kg
                  </span>
                  <span className="text-sm font-regular">
                    {maskHelper.formatMoeda(item.subtotal)}
                  </span>
                </div>
              ))}
            </div>

            <div className="flex justify-between flex-col-reverse sm:flex-row gap-3 mt-10">
              <button
                type="button"
                onClick={() => navigate('/products')}
                className="rounded-md border-primary border-solid border-[1px] p-2 pl-6 pr-6 font-Inter font-bold text-xs text-primary"
              >
                Continuar comprando
              </button>
              <button
                type="button"
                onClick={() => navigate('/shopping-summary')}
                className="rounded-md bg-primary p-2 pl-6 pr-6 font-Inter font-bold text-xs text-black"
              >
                Finalizar pagamento
              </button>
            </div>
          </div>
        </motion.section>

        <Footer />
        <Copyright />
      </div>
    </div>
  );
}
