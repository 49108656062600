import React from 'react';
import { BsWhatsapp } from 'react-icons/bs';

import about from '../../../assets/images/about.png';

const AboutUS: React.FC = () => (
  <section className="flex items-start gap-12 py-10 sm:py-20 px-10 lg:pl-32 lg:pr-32 bg-background">
    <img
      alt="Carletto"
      src={about}
      loading="lazy"
      className="cursor-pointer hidden lg:flex"
      width="400"
    />
    <div>
      <p className="font-bold text-3xl text-center lg:text-left mb-5">
        Sobre nós
      </p>
      <img
        alt="Carletto"
        src={about}
        loading="lazy"
        className="cursor-pointer lg:hidden mb-5 lg:mb-0"
        width="400"
      />
      <p className="font-light text-md mb-5 text-justify lg:text-left">
        Nossos fundadores atuando{' '}
        <span className="font-bold">a mais de 15 anos</span> nos setores de
        alimentação e tecnologia criaram um HUB de Negócios que tem como intuito
        mudar a maneira dos associados consumirem as melhores marcas de carnes
        do mercado.
        <br />
        <br />
        Para isto, reproduziram um modelo de negócio que auxilia os afiliados a
        fazerem seu churrasco com as melhores carnes e preços baixos.
        <br />
        <br />
        Carletto é uma empresa que tem inovado o mercado de carnes, mostrando
        que por meio de relacionamento entre açougues e fornecedores é possível
        comprar excelentes marcas a um preço super especial.
      </p>
      <p className="font-bold text-3xl text-center lg:text-left mb-5">
        Nossa Missão
      </p>
      <p className="font-light text-md mb-5 text-justify lg:text-left">
        <span className="font-bold">A nossa missão </span>é democratizar o
        consumo carnes para churrasco proporcionando experiências inesquecíveis
        aos nossos seletos membros. Entregando sempre produtos e serviços de
        excelência. Tornar-se referência em carnes para churrasco, produzir e
        comercializar produtos para satisfazer os nossos exigentes membros do
        clube.
      </p>
      <div className="flex items-center flex-col lg:flex-row justify-between mt-7 lg:mt-10">
        <p className="font-light text-left lg:text-left mb-5 lg:mb-0">
          <span className="font-bold text-xl sm:text-lg">
            Se preferir, fale com a gente!
          </span>
          <br />
          Estaremos feliz em tirar quaisquer dúvidas.
        </p>
        <div className="flex gap-4 flex-col lg:flex-row mt-4 sm:mt-0">
          <a
            href="https://api.whatsapp.com/send?phone=5531991661125"
            target="_blank"
            className="rounded-md bg-primary p-2 pl-8 pr-8 font-Inter font-bold text-sm text-black w-40 flex gap-2 flex-row items-center justify-center"
            rel="noreferrer"
          >
            <BsWhatsapp />
            <span>Whatsapp</span>
          </a>
        </div>
      </div>
    </div>
  </section>
);

export default AboutUS;
