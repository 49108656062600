import axios from 'axios';
import { removeAll } from './storage.helper';

export default class HttpClient {
  private api;

  private baseURL;

  constructor(baseURL: string, authorization?: string) {
    this.baseURL = baseURL;

    this.api = axios.create({
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (authorization) {
      this.api.defaults.headers.common.Authorization = `Bearer ${authorization}`;
    }

    /* eslint-disable consistent-return */
    this.api.interceptors.response.use(
      (response) => response,
      async (error) => {
        if (error.response.status === 401) {
          removeAll();
          window.location.href = window.location.origin;
        }

        if ('400,404,403,429,500'.includes(error.response.status)) {
          return error.response;
        }
      }
    );
  }

  async get(path: string, headers?: any): Promise<any> {
    try {
      return await this.api.get(`${this.baseURL}/${path}`, headers);
    } catch (e: any) {
      if (e.response) {
        throw e.response.data;
      }

      throw new Error(e.message);
    }
  }

  async post(path: string, data: any, headers?: any): Promise<any> {
    try {
      return await this.api.post(`${this.baseURL}/${path}`, data, headers);
    } catch (e: any) {
      if (e.response) {
        throw e.response.data;
      }

      throw new Error(e.message);
    }
  }

  async delete(path: string, headers?: any): Promise<any> {
    try {
      return await this.api.delete(`${this.baseURL}/${path}`, headers);
    } catch (e: any) {
      if (e.response) {
        throw e.response.data;
      }

      throw new Error(e.message);
    }
  }

  async put(path: string, data: any, headers?: any): Promise<any> {
    try {
      return await this.api.put(`${this.baseURL}/${path}`, data, headers);
    } catch (e: any) {
      if (e.response) {
        throw e.response.data;
      }

      throw new Error(e.message);
    }
  }
}
