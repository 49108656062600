import React, { useContext } from 'react';
import { FiLogOut, FiSettings } from 'react-icons/fi';
import { GiPresent } from 'react-icons/gi';
import { MdOutlineRequestQuote } from 'react-icons/md';
import { RiUserSettingsLine } from 'react-icons/ri';
import { VscChecklist } from 'react-icons/vsc';
import { Link, useNavigate } from 'react-router-dom';
import SessionContext from '../contexts/session.context';

type MenuUserProps = {
  /* eslint-disable no-unused-vars */
  setIsMenuUser: (e: boolean) => void;
};

const MenuUser: React.FC<MenuUserProps> = ({ setIsMenuUser }) => {
  const navigate = useNavigate();
  const { user, signOut } = useContext(SessionContext);

  return (
    <div
      onMouseLeave={() => setIsMenuUser(false)}
      className="w-[250px] p-6 pt-5 bg-white absolute top-[73px] right-2 text-black shadow-lg rounded-md"
    >
      <div className="bg-white w-3 h-3 rotate-45 absolute top-[-6px] right-[128px] rounded-sm" />

      <span className="font-light text-md mb-2">
        Seja bem vindo(a) <strong>{user.name.split(' ')[0]}!</strong>
      </span>

      <div className="h-[1px] bg-black my-4 opacity-20" />

      <Link
        to="/account"
        className="flex items-center gap-3 font-light text-md mb-3 hover:text-primary"
      >
        <RiUserSettingsLine size={17} />
        <span>Meus dados</span>
      </Link>
      <Link
        to="/requests"
        className="flex items-center gap-3 font-light text-md mb-3 hover:text-primary"
      >
        <MdOutlineRequestQuote size={17} />
        <span>Meus pedidos</span>
      </Link>
      <Link
        to="/payments"
        className="flex items-center gap-3 font-light text-md mb-3 hover:text-primary"
      >
        <VscChecklist size={17} />
        <span>Clube Carletto</span>
      </Link>
      <Link
        to="/indicated"
        className="flex items-center gap-3 font-light text-md mb-3 hover:text-primary"
      >
        <GiPresent size={17} />
        <span>Indicação</span>
      </Link>
      {user.role_id === 2 && (
        <Link
          to="/dashboard"
          className="flex items-center gap-3 font-light text-md hover:text-primary"
        >
          <FiSettings size={17} />
          <span>Admin</span>
        </Link>
      )}
      <button
        type="button"
        onClick={() => {
          signOut();
          setIsMenuUser(false);
          navigate('/home');
        }}
        className="flex items-center gap-3 font-light text-md mt-8 hover:text-primary"
      >
        <FiLogOut size={17} />
        <span>Sair</span>
      </button>
    </div>
  );
};

export default MenuUser;
