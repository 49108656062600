import HttpClient from '../helpers/httpClient.helper';

class SessionServices {
  private api;

  constructor() {
    this.api = new HttpClient(String(process.env.REACT_APP_API));
  }

  async authentication(email: string, password: string): Promise<void> {
    return await this.api.post('session/auth', {
      email,
      password,
    });
  }

  async isAccount(
    phone: string,
    email: string,
    document: string
  ): Promise<void> {
    return await this.api.post('session/is-account', {
      phone,
      email,
      document,
    });
  }

  async validIdentify(identify: string): Promise<void> {
    return await this.api.get(`session/valid/identify/${identify}`);
  }

  async register(params: any): Promise<void> {
    return await this.api.post('session/register', params);
  }

  async getCategories(): Promise<void> {
    return await this.api.get('session/categories');
  }

  async getBrands(): Promise<void> {
    return await this.api.get('session/brands');
  }

  async getProductsFavorites(): Promise<void> {
    return await this.api.get('session/products/favorite');
  }

  async getProductsPrime(): Promise<void> {
    return await this.api.get('session/products/prime');
  }

  async getFirstProductByCategoryItem(categoryItemsId: number): Promise<void> {
    return await this.api.get(
      `session/product/first/category-item/${categoryItemsId}`
    );
  }

  async getFirstProductByCategoryItemBrand(
    categoryItemsId: number,
    brandId: number
  ): Promise<void> {
    return await this.api.get(
      `session/product/first/category-item/brand/${categoryItemsId}/${brandId}`
    );
  }

  async getStock(productWeightRangeId: number): Promise<void> {
    return await this.api.get(`session/product/stock/${productWeightRangeId}`);
  }

  async verifyExistAccountByEmail(email: string): Promise<void> {
    return await this.api.get(`session/user/exist/email/${email}`);
  }

  async verifyCodeByUser(userId: string, code: number): Promise<void> {
    return await this.api.get(`session/user/valid/code/${userId}/${code}`);
  }

  async sendRecoveryPassByEmail(email: string): Promise<void> {
    return await this.api.get(`session/send-email/recovery/pass/${email}`);
  }

  async changePass(userId: number, password: string): Promise<void> {
    return await this.api.put(`session/change/pass/${userId}`, {
      password,
    });
  }

  async getCompany(): Promise<void> {
    return await this.api.get(`session/company`);
  }

  async getProductById(id: number): Promise<void> {
    return await this.api.get(`session/product/${id}`);
  }
}

export default SessionServices;
