import { useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import bannerHome from '../../../assets/images/banner_home.png';
import seeMore from '../../../assets/images/see_more.png';

import Benefits from '../components/Benefits';
import Club from '../components/Club';
import Newsletter from '../components/Newsletter';
import Footer from '../../../components/Footer';
import Copyright from '../../../components/Copyright';
import Header from '../../../components/Header';

import Accordion from '../components/Accordion';
import CardDetailsProduct from '../components/CardDetailsProduct';
import ProductsBestSeller from '../components/ProductsBestSeller';
import { SessionServices } from '../../../services';
import LoadingContext from '../../../contexts/loading.context';

export default function ProductsPage() {
  const [accordionIndex, setAccordionIndex] = useState<number | null>(null);
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const [categories, setCategories] = useState([]);
  const [brand, setBrand] = useState<any>(null);
  const { loading, setLoading } = useContext(LoadingContext);
  const [loadingCategories, setLoadingCategories] = useState(true);
  const { productId } = useParams();
  const cardRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    (async () => {
      if (productId) {
        const response: any = await new SessionServices().getProductById(
          Number(productId)
        );
        setSelectedItem(response.data.category_items);
        setBrand(response.data.brand);
        setAccordionIndex(response.data.category_items.category_id);
      }
    })();
  }, [productId]);

  useEffect(() => {
    (async () => {
      setLoadingCategories(true);
      const responseCat: any = await new SessionServices().getCategories();
      setCategories(responseCat.data);
      setLoadingCategories(false);
    })();
  }, []);

  useEffect(() => {
    if (brand?.id) {
      (async () => {
        setLoading({
          isOpen: true,
          title: 'Carregando...',
        });
        const response: any =
          await new SessionServices().getFirstProductByCategoryItemBrand(
            selectedItem.id,
            brand.id
          );

        const prod: any = response.data || null;
        prod?.product_weight_range.forEach((item: any, i: number) => {
          if (prod.product_weight_range[i].qty > 0) {
            prod.product_weight_range[i] = {
              ...item,
              title: `${item.weight_start} a ${item.weight_end} ${prod.category_items.unit}`,
            };
          }
        });

        setSelectedItem({
          ...selectedItem,
          productSelected: prod,
        });

        setTimeout(() => {
          setLoading({
            isOpen: false,
          });

          if (productId) {
            cardRef?.current?.scrollIntoView();
          }
        }, 700);

        if (!prod) setSelectedItem(null);
      })();
    }
  }, [brand]);

  return (
    <div className="z-0 h-screen relative flex justify-center">
      <div className="bg-white relative w-full h-screen">
        <Header
          isDivideMarginTop
          title="Nossos produtos"
          description="As melhores peças para você."
          button={{
            title: 'Nossos produtos',
            onClick: () => console.log('aaa'),
          }}
        />

        <img
          alt="Carletto"
          src={bannerHome}
          loading="lazy"
          className="h-[200px] lg:h-[45.2%] 2xl:h-[46%] w-full object-cover"
        />

        <div className="separator" />

        <section className="flex flex-col items-center pt-10 pb-10 bg-background">
          <div
            className="flex items-center justify-between w-full"
            ref={cardRef}
          >
            <div className="bg-primary h-[0.5px] w-[40%]" />
            <p className="font-bold text-2xl text-center">Produtos Carletto</p>
            <div className="bg-primary h-[0.5px] w-[40%]" />
          </div>
          <p className="font-light text-center mb-10">
            Veja como facilita seu churrasco.
            <br />
            Conveniência inteligente para qualquer necessidade
          </p>
          <div className="flex flex-col lg:flex-row justify-center gap-6">
            <div className="flex flex-col w-[85%] self-center lg:w-52 lg:self-start  cursor-pointer">
              {loadingCategories &&
                [1, 2, 3].map(() => (
                  <div
                    className="border border-blue-300 rounded-md max-w-sm w-full mx-auto mb-3"
                    key={Math.random()}
                  >
                    <div className="animate-pulse flex space-x-4">
                      <div className="rounded-full bg-slate-200 h-12 w-12" />
                      <div className="flex-1 space-y-6 py-1 pt-5">
                        <div className="h-2 bg-slate-200 rounded w-28" />
                      </div>
                    </div>
                  </div>
                ))}
              {!loadingCategories &&
                categories.map((category: any) => (
                  <Accordion
                    key={category?.id}
                    index={category?.id}
                    accordionIndex={accordionIndex}
                    setAccordionIndex={setAccordionIndex}
                    setBrand={setBrand}
                    setSelectedItem={setSelectedItem}
                    selectedItem={selectedItem}
                    category={category}
                    brand={brand}
                  />
                ))}
            </div>

            {!selectedItem && (
              <img
                alt="Carletto"
                src={seeMore}
                loading="lazy"
                className="max-h-[450px] w-[770px] 2xl:w-[800px] p-2 lg:p-0"
              />
            )}

            {selectedItem && (
              <CardDetailsProduct
                selectedItem={selectedItem}
                setBrand={setBrand}
                brand={brand}
                loading={loading.isOpen}
              />
            )}
          </div>

          <ProductsBestSeller />

          <div className="items-center justify-between hidden lg:flex w-full mt-14 mb-[-60px]">
            <div className="bg-primary h-[0.5px] w-[42%]" />
            <p className="font-bold text-2xl text-center">Clube Carletto</p>
            <div className="bg-primary h-[0.5px] w-[42%]" />
          </div>
        </section>

        <Club />
        <Benefits path="welcome" />
        <Newsletter isChangeBackgroundWhite />
        <Footer />
        <Copyright />
      </div>
    </div>
  );
}
