import React, { createContext, useMemo } from 'react';
import Loading from '../components/Loading';
import useLoading from './hooks/useLoading';

type LoadingProps = {
  title?: string;
  isOpen: boolean;
};

interface LoadingData {
  loading: LoadingProps;
  /* eslint-disable no-unused-vars */
  setLoading: (e: LoadingProps) => void;
}

const LoadingContext = createContext<LoadingData>({} as LoadingData);

interface LoadingProviderProps {
  children: React.ReactNode;
}

export const LoadingProvider: React.FC<LoadingProviderProps> = ({
  children,
}) => {
  const { loading, setLoading } = useLoading();

  const value = useMemo(
    () => ({
      loading,
      setLoading,
    }),
    [loading, setLoading]
  );

  return (
    <LoadingContext.Provider value={value}>
      {loading.isOpen && <Loading />}

      {children}
    </LoadingContext.Provider>
  );
};

export default LoadingContext;
