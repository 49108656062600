import React from 'react';
import { BsWhatsapp, BsInstagram, BsFacebook } from 'react-icons/bs';
import { ReactSVG } from 'react-svg';

import cardCredit from '../assets/images/card_credit.png';
import bufalo from '../assets/svg/bufalo.svg';

const Footer: React.FC = () => (
  <section className="flex items-center lg:items-start text-center flex-col justify-between gap-10 lg:flex-row lg:gap-10 lg:px-20 p-10 bg-[#0A0A0A] text-white">
    <ReactSVG src={bufalo} className="cursor-pointer" />
    <div className="flex flex-col">
      <p className="font-bold mb-4">Institucional</p>
      <a
        href={`${window.location.origin}/doc/termos_condicoes.pdf`}
        className="font-light mb-1"
        download
      >
        Termos e condições
      </a>
      <a
        href={`${window.location.origin}/doc/politica_privacidade.pdf`}
        className="font-light mb-1"
        download
      >
        Política de Privacidade
      </a>
      <a
        href={`${window.location.origin}/doc/politica_troca_devolucao.pdf`}
        className="font-light mb-1"
        download
      >
        Política de Troca e Devolução
      </a>
      <p className="font-light">sobre nós</p>
    </div>
    <div>
      <p className="font-bold mb-4">Central de Ajuda</p>
      <p className="font-light mb-1">contato@socarletto.com.br</p>
      <a
        href="https://api.whatsapp.com/send?phone=5531991661125"
        target="_blank"
        className="flex items-center justify-center gap-2 mb-1 font-light"
        rel="noreferrer"
      >
        <BsWhatsapp />
        <span>Whatsapp</span>
      </a>
      <a
        href="https://www.instagram.com/socarletto/?hl=pt-br"
        target="_blank"
        className="flex items-center justify-center gap-2 mb-1 font-light"
        rel="noreferrer"
      >
        <BsInstagram />
        <span>Instagram</span>
      </a>
      <a
        href="https://www.facebook.com/socarletto"
        target="_blank"
        className="flex items-center justify-center gap-2 mb-1 font-light"
        rel="noreferrer"
      >
        <BsFacebook />
        <span>Facebook</span>
      </a>
    </div>
    <div>
      <p className="font-bold mb-4">Horário para entrega</p>
      <div className="flex items-center justify-center gap-2 font-light">
        <span>Segunda a domingo, de 09 às 19h</span>
      </div>
    </div>
    <div>
      <p className="font-bold mb-4">Formas de Pagamento</p>
      <img
        alt="Carletto Meat Club"
        src={cardCredit}
        loading="lazy"
        className="w-40"
      />
    </div>
  </section>
);

export default Footer;
