import HttpClient from '../helpers/httpClient.helper';
import { AuthorizationToken } from './common/authorization.token';

class NotificationServices extends AuthorizationToken {
  private api;

  constructor() {
    super();
    this.api = new HttpClient(String(process.env.REACT_APP_API), this.token);
  }

  async create(data: any): Promise<void> {
    return await this.api.post('notification/push', data);
  }

  async reSend(id: number): Promise<void> {
    return await this.api.put(`notification/resend/${id}`, {});
  }

  async getAll(): Promise<void> {
    return await this.api.get('notification');
  }
}

export default NotificationServices;
