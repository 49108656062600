import { disableReactDevTools } from '@fvilers/disable-react-devtools';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function DisableReactDevTools() {
  const { pathname } = useLocation();

  useEffect(() => {
    if (process.env.REACT_APP_ENV === 'production') {
      disableReactDevTools();

      document.addEventListener('contextmenu', (e) => {
        e.preventDefault();
      });

      /* eslint-disable consistent-return */
      document.onkeydown = (e) => {
        if (e.key === 'F12') {
          return false;
        }
        if (e.ctrlKey && e.shiftKey && e.key === 'I') {
          return false;
        }
        if (e.ctrlKey && e.shiftKey && e.key === 'C') {
          return false;
        }
        if (e.ctrlKey && e.shiftKey && e.key === 'J') {
          return false;
        }
        if (e.ctrlKey && e.key === 'u') {
          return false;
        }
      };
    }
  }, [pathname]);

  return null;
}
