import React, { useContext } from 'react';
import { motion } from 'framer-motion';
import SessionContext from '../../../contexts/session.context';
import Drawer from './Drawer';

type LayoutProps = {
  children: React.ReactNode;
  legend?: {
    value: any;
    text: string;
  };
};

export default function Layout({ children, legend }: LayoutProps) {
  const { user } = useContext(SessionContext);

  let name = '';
  if (Object.keys(user).length > 0) {
    name = `${user?.name.split(' ')[0]} ${user?.name.split(' ')[1]} `;
  }

  return (
    <motion.div
      className="flex w-full h-full min-h-fit"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <Drawer />
      <div className="flex flex-col w-full bg-background h-screen min-h-fit px-10 py-11">
        <header className="flex justify-between">
          <div className="flex flex-col">
            <strong>Olá, {name}</strong>
            <p className="text-sm">
              Seja bem vindo a central de operações Carletto Meat Club
            </p>
          </div>
          <p className="flex flex-col items-center">
            <strong className="text-lg">{legend?.value}</strong>
            <p className="text-sm">{legend?.text}</p>
          </p>
        </header>
        <br />
        <div className="bg-white rounded-lg p-9 min-h-[95%] w-full flex flex-col items-center ">
          {children}
        </div>
      </div>
    </motion.div>
  );
}
