import { useState } from 'react';

type AlertProps = {
  title: string;
  description: string;
  button: {
    title: string;
    type?: 'primary' | 'red' | 'black-regular' | 'green';
    onClick?: () => void;
  }[];
  isOpen: boolean;
};

export default function useAlert() {
  const [alert, setAlert] = useState<AlertProps>({
    title: '',
    description: '',
    button: [
      {
        title: '',
      },
    ],
    isOpen: false,
  });

  return {
    alert,
    setAlert,
  };
}
