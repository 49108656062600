import React, { useEffect, useState } from 'react';

import CardProduct from './CardProduct';
import { SessionServices } from '../../../services';

const ProductsBestSeller: React.FC = () => {
  const [products, setProducts] = useState<any[]>([]);
  useEffect(() => {
    (async () => {
      const response: any = await new SessionServices().getProductsFavorites();
      setProducts(response.data);
    })();
  }, []);

  return (
    <>
      <div className="flex items-center justify-between w-full mt-4 lg:mt-14">
        <div className="bg-primary h-[0.5px] w-[39%]" />
        <p className="font-bold text-2xl text-center">Ofertas da semana</p>
        <div className="bg-primary h-[0.5px] w-[39%]" />
      </div>
      <p className="font-light text-center mb-10">
        Seu churrasco com descontos exclusivos!
      </p>

      <div className="flex flex-row flex-wrap rounded-md gap-3 justify-center lg:pl-24 lg:pr:pl-24 max-w-[95%]">
        {products.map((item) => (
          <CardProduct data={item} key={item.id} />
        ))}
      </div>
    </>
  );
};
export default ProductsBestSeller;
