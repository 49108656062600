import HttpClient from '../helpers/httpClient.helper';

class PostalCodeServices {
  private api;

  constructor() {
    this.api = new HttpClient(String(process.env.REACT_APP_API_CEP));
  }

  async get(postalCode: string): Promise<void> {
    return await this.api.get(`ws/${postalCode}/json/`);
  }
}

export default PostalCodeServices;
