import { useContext, useEffect, useState } from 'react';
import ReactLoading from 'react-loading';
import { AiOutlinePlus } from 'react-icons/ai';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import { IoIosSend } from 'react-icons/io';
import moment from 'moment';
import { NotificationServices } from '../../../services';
import Layout from '../components/Layout';
import Modal from '../../../components/Modal';
import AlertContext from '../../../contexts/alert.context';
import LoadingContext from '../../../contexts/loading.context';
import SelectCustom from '../../../components/Select';
import { typesNotifications } from '../mocks';

export default function Notification() {
  const { alert, setAlert } = useContext(AlertContext);
  const { loading, setLoading } = useContext(LoadingContext);
  const [loadingSave, setLoadingSave] = useState(false);
  const [isOpenForm, setIsOpenForm] = useState(false);
  const [showEmoji, setShowEmoji] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [item, setItem] = useState<any>({
    id: '',
    title: '',
    body: '',
    legend: null,
    legend_key: '',
  });

  const [errorForm, setErrorForm] = useState<any>(null);

  async function handleGetAll(): Promise<void> {
    setLoading({
      isOpen: true,
      title: 'Carregando...',
    });
    const response: any = await new NotificationServices().getAll();
    setNotifications(response.data);
    setLoading({ ...loading, isOpen: false });
  }

  async function onSubmit(): Promise<void> {
    try {
      setErrorForm(null);

      let errors = null;
      if (!!item.title === false) {
        errors = { title: true };
      }

      if (!!item.body === false) {
        errors = { ...errors, body: true };
      }

      if (!!item.legend === false) {
        errors = { ...errors, legend: true };
      }

      if (errors) {
        setErrorForm(errors);
        toast.warn('Campo(s) obrigatório(s)!');
        return;
      }

      setLoadingSave(true);

      await toast.promise(
        async () => {
          const response: any = await new NotificationServices().create({
            title: item.title,
            body: item.body,
            legend: item.legend.title,
            legendKey: item.legend.id,
          });

          setIsOpenForm(false);
          await handleGetAll();

          return response.data;
        },
        {
          pending: 'Processando',
          success: {
            render({ data }) {
              if (data?.id) return `Notificação criada e enviada com sucesso!`;
              return `Hoje não temos usuários com este perfil!`;
            },
          },
          error: 'Tente novamente mais tarde :(',
        }
      );
    } catch (e: any) {
      console.log(e);
    } finally {
      setLoadingSave(false);
    }
  }

  async function reSend(id: number): Promise<void> {
    try {
      setLoadingSave(true);

      await toast.promise(
        async () => {
          await new NotificationServices().reSend(id);
          await handleGetAll();

          throw new Error('not_found');
        },
        {
          pending: 'Processando',
          success: `Notificação enviada com sucesso!`,
          error: 'Tente novamente mais tarde :(',
        }
      );
    } catch (e: any) {
      console.log(e);
    } finally {
      setLoadingSave(false);
    }
  }

  useEffect(() => {
    handleGetAll();
  }, []);

  useEffect(() => {
    if (!isOpenForm) {
      setItem({
        id: '',
        title: '',
        body: '',
        legend: null,
        legend_key: '',
      });
    }
  }, [isOpenForm]);

  return (
    <Layout
      legend={{
        value: notifications.length,
        text: 'Notificações',
      }}
    >
      {showEmoji && (
        <Modal
          title="Copiar Emiji 👌"
          setIsOpen={setShowEmoji}
          isOpen={showEmoji}
          size="4xlarge"
        >
          <p>Caras e pessoas</p>
          <p className="my-3">
            😀 😃 😄 😁 😆 😅 😂 🤣 😇 😉 😊 🙂 🙃 ☺ 😋 😌 😍 🥰 😘 😗 😙 😚 🥲
            🤪 😜 😝 😛 🤑 😎 🤓 🥸 🧐 🤠 🥳 🤡 😏 😶 🫥 😐 🫤 😑 😒 🙄 🤨 🤔 🤫
            🤭 🫢 🫡 🤗 🤩 😵 😵‍💫 🥴 😲 🤯 🫠 🤐 😸 😹 😻 😼 😽 🙀 😿 😾 🫶 👐 🤲 🙌
            👏 🙏 🤝 👍 👎 👊 ✊ 🤛 🤜 🤞 ✌ 🫰 🤘 🤟 👌 🤌 🤏 👈 🫳 🫴 👉 👆 👇 ☝
            ✋ 🤚 🖐 🖖 👋 🤙 🫲 🫱 💪 🦾 🖕 🫵 👧 🧒 👦 👩 🧑 👨 👩‍🦱 🧑‍🦱 👨‍🦱 👩‍🦰 🧑‍🦰 👨‍🦰
            👱‍♀️ 👱 👱‍♂️ 👩‍🦳 🧑‍🦳 👨‍🦳 👩‍🦲 🧑‍🦲 👨‍🦲 🧔‍♀️ 🧔 💁 💁‍♂️ 🙅‍♀️ 🙅 🙅‍♂️ 🙆‍♀️ 🙆 🙆‍♂️ 🤷‍♀️ 🤷 🤷‍♂️ 🙋‍♀️
            🙋 🙋‍♂️ 🤦‍♀️ 🤦 🤦‍♂️ 🧏‍♀️ 🧏 🧏‍♂️ 🙎‍♀️ 🙎 🙎‍♂️ 🕺 👫 👭 👬 🧑‍🤝‍🧑 👩‍❤️‍👨 👩‍❤️‍👩 💑 👨‍❤️‍👨 👩‍❤️‍💋‍👨 👩‍❤️‍💋‍👩 💏
            👨‍❤️‍💋‍👨 ❤ 🧡 💛 💚 💙 💜 🤎 🖤 🤍 💔 ❣ 💕 💞 💓 💗 💖 💘 💝 ❤️‍🔥 ❤️‍🩹 💟
          </p>
          <p>Alimentos e Bebidas</p>
          <p className="my-3">
            🥒 🥬 🥦 🧄 🧅 🌽 🥕 🥗 🥔 🍠 🌰 🥜 🫘 🍯 🍞 🥐 🥖 🫓 🥨 🥯 🥞 🧇 🧀
            🍗 🍖 🥩 🍤 🥚 🍳 🥓 🍔 🍟 🌭 🍕 🍝 🥪 🌮 🌯 🫔 🥙 🧆 🍜 🥘 🍲 🫕 🥫
            🫙 🧂 🧈 🍥 🍣 🍱 🍛 🍙 🍚 🍘 🥟 🍢 🍡 🍧 🍨 🍦 🍰 🎂 🧁 🥧 🍮 🍭 🍬
            🍫 🍿 🍩 🍪 🥠 🥮 ☕ 🍵 🫖 🥣 🍼 🥤 🧋 🧃 🧉 🥛 🫗 🍺 🍻 🍷 🥂 🥃 🍸
            🍹 🍾 🍶 🧊 🥄 🍴 🍽 🥢 🥡🚨
          </p>
          <p>Símbolos</p>
          <p className="my-3">
            🆘 ⛔ 📛 🚫 ❌ ⭕ 💢 ♨ 🚷 🚯 🚳 🚱 🔞 📵 🚭 ❗ ❕ ❓ ❔ ‼ ⁉ 💯 🔅 🔆
            🔰 ☑ 🔘 🔴 🟠 🟡 🟢 🔵 🟤 ⚫ ⚪ 🟥 🟧 🟨 🟩 🟦 🟪 🟫 ⬛ ⬜ ◼ ◻ ◾ ◽
            ▪ ▫ 🔸 🔹 🔶 🔷 🔺 🔲 🔳 🔈 🔉 🔊 🔇 � �📢 🔔 🔕 🃏 🀄 ♠ ♣ ♥ ♦ 🎴
            👁‍🗨 🗨 💭 🗯 💬 🕐 🕒 🕓 🕔 🕕 🕖 🕗 🕘 🕙 🕚 🕛 🕜 🕝 🕞 🕟 🕠 🕡 🕢
            🕣 🕤 🕥 🕦 🕧
          </p>
        </Modal>
      )}

      {isOpenForm && (
        <Modal
          title="Nova notificação"
          setIsOpen={setIsOpenForm}
          isOpen={isOpenForm}
        >
          <form id="form" autoComplete="off">
            <div className="flex flex-col mt-4 gap-1 relative">
              <button
                type="button"
                className="font-light text-xs w-full text-start justify-between flex"
                onClick={() => setShowEmoji(!showEmoji)}
              >
                <span>Título</span>
                <span className="text-primary font-bold self-end">emoji</span>
              </button>

              <input
                className="border-solid border-[0.5px] border-[#aaa] p-3 rounded-md text-xs w-full focus:border-primary focus:border"
                placeholder="Ex: Carletto Meat Club 🥩"
                onChange={(e) => setItem({ ...item, title: e.target.value })}
                value={item.title}
              />
              <span
                className={classNames(
                  'absolute text-red text-[0.60rem] left-1 bottom-[-15px]',
                  {
                    hidden: !errorForm?.title,
                  }
                )}
              >
                {errorForm?.title ? 'Obrigatório' : ''}
              </span>
            </div>
            <div className="flex flex-col mt-4 gap-1 relative">
              <button
                type="button"
                className="font-light text-xs w-full text-start justify-between flex"
                onClick={() => setShowEmoji(!showEmoji)}
              >
                <span className="font-light text-xs">Descrição</span>
                <span className="text-primary font-bold self-end">emoji</span>
              </button>
              <textarea
                className="border-solid border-[0.5px] border-[#aaa] p-3 rounded-md text-xs w-full focus:border-primary focus:border"
                placeholder="Ex: Vamos fazer um churraso? Confira os produtos prime! 🍻🍷🍖🥩👌"
                onChange={(e) => setItem({ ...item, body: e.target.value })}
                value={item.body}
              />
              <span
                className={classNames(
                  'absolute text-red text-[0.60rem] left-1 bottom-[-15px]',
                  {
                    hidden: !errorForm?.body,
                  }
                )}
              >
                {errorForm?.body ? 'Obrigatório' : ''}
              </span>
            </div>

            <div className="flex flex-col mt-4 relative">
              <span className="font-light text-xs">Para quem quer enviar?</span>
              <SelectCustom
                placeholder="Selecione um grupo"
                data={typesNotifications}
                borderColor="#ddd"
                defaultValue={
                  !item?.legend
                    ? { id: '', title: 'Selecione um grupo' }
                    : typesNotifications.filter(
                        (i: any) => i.id === item.legend.id
                      )[0]
                }
                noOptionsMessage="Não encontrado"
                marginTop={4}
                onChange={(e: any) =>
                  setItem({
                    ...item,
                    legend: e,
                  })
                }
              />
              <span
                className={classNames(
                  'absolute text-red text-[0.60rem] left-1 bottom-[-15px]',
                  {
                    hidden: !errorForm?.legend,
                  }
                )}
              >
                {errorForm?.legend ? 'Obrigatório' : ''}
              </span>
            </div>
            <div className="flex gap-3 mt-7 justify-end mb-28">
              <button
                type="button"
                form="form"
                disabled={loadingSave}
                onClick={onSubmit}
                className={classNames(
                  'relative rounded-md bg-primary p-2 pl-6 pr-6 font-Inter font-bold text-xs w-36',
                  {
                    'bg-opacity-20 text-gray-400 cursor-not-allowed hover:bg-opacity-20':
                      loadingSave,
                  }
                )}
              >
                <span>Salvar</span>
                <ReactLoading
                  type="spokes"
                  color="#DC7E19"
                  width={15}
                  className={classNames('absolute right-3 top-2', {
                    hidden: !loadingSave,
                  })}
                />
              </button>
            </div>
          </form>
        </Modal>
      )}

      <div className="w-full bg-white rounded-lg shadow-xs sm:min-h-[500px] 2xl:min-h-[700px] h-fit">
        <div className="flex flex-row justify-between items-center mb-8 relative">
          <div className="flex flex-row items-center gap-2">
            <IoIosSend size={22} color="#DC7E19" />
            <span className="font-bold text-lg">Notificações</span>
          </div>

          <button
            type="button"
            onClick={() => {
              setIsOpenForm(true);
            }}
            className="flex items-center gap-1 border-primary text-primary border-solid border-[1px] px-3 py-[0.4rem] rounded-md text-xs transition hover:text-black-light hover:bg-primary"
          >
            <AiOutlinePlus />
            <span>adicionar</span>
          </button>
        </div>
        <table className="table-auto w-full">
          <thead className="border-solid border-b-2 text-xs sm:text-sm bg-primary-light/25">
            <tr>
              <th className="p-1 text-left rounded-tl-sm">Título</th>
              <th className="p-1 text-left rounded-tl-sm">Descrição</th>
              <th className="p-1 text-left rounded-tl-sm">Enviado para</th>
              <th className="p-1 text-left rounded-tl-sm">Data envio</th>
              <th className="p-1 text-center rounded-tr-sm">&nbsp;</th>
            </tr>
          </thead>
          <tbody className="font-light text-xs sm:text-sm ">
            {notifications.map((send: any) => (
              <tr key={send.id}>
                <td className="p-1 text-left">
                  {decodeURIComponent(send.title)}
                </td>
                <td className="p-1 text-left">
                  {decodeURIComponent(send.body)}
                </td>
                <td className="p-1 text-left">{send.legend}</td>
                <td className="p-1 text-left">
                  {moment(send.created_at).format('llll')}
                </td>

                <td className="p-1 text-left">
                  <IoIosSend
                    className="cursor-pointer"
                    color="#DC7E19"
                    size={20}
                    onClick={() =>
                      setAlert({
                        title: 'Confirmação!',
                        description:
                          'Deseja realmente enviar/reenviar esta notificação?',
                        isOpen: true,
                        button: [
                          {
                            title: 'Não',
                            type: 'black-regular',
                            onClick: () =>
                              setAlert({ ...alert, isOpen: false }),
                          },
                          {
                            title: 'Sim, enviar',
                            type: 'red',
                            onClick: () => {
                              reSend(send.id);
                              setAlert({ ...alert, isOpen: false });
                            },
                          },
                        ],
                      })
                    }
                  />
                </td>
              </tr>
            ))}
            {!loading.isOpen && notifications.length === 0 && (
              <tr>
                <td className="p-1 text-left">Nenhuma notificação enviada.</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </Layout>
  );
}
