import { useContext, useEffect, useState } from 'react';
import ReactLoading from 'react-loading';
import { AiOutlinePlus } from 'react-icons/ai';
import { RiDeleteBin6Line, RiProductHuntLine } from 'react-icons/ri';
import { FaEdit } from 'react-icons/fa';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import { useDropzone } from 'react-dropzone';
import { GiWeight } from 'react-icons/gi';
import {
  BrandServices,
  PriceServices,
  ProductServices,
  SubcategoryServices,
} from '../../../services';
import Layout from '../components/Layout';
import Modal from '../../../components/Modal';
import Switch from '../../../components/Switch';
import AlertContext from '../../../contexts/alert.context';
import SelectCustom from '../../../components/Select';
import maskHelper from '../../../helpers/mask.helper';
import LoadingContext from '../../../contexts/loading.context';

export default function Product() {
  const { alert, setAlert } = useContext(AlertContext);
  const { loading, setLoading } = useContext(LoadingContext);
  const [loadingSave, setLoadingSave] = useState(false);
  const [isOpenForm, setIsOpenForm] = useState(false);
  const [isOpenWeight, setIsOpenWeight] = useState(false);
  const [productWeightRange, setProductWeightRange] = useState([]);
  const [productId, setProductId] = useState(null);
  const [weight, setWeight] = useState<any>({
    id: '',
    product_id: '',
    weight_start: '',
    weight_end: '',
    qty: 1,
  });
  const [data, setData] = useState([]);
  const [categorysItems, setCategorysItems] = useState([]);
  const [brands, setBrands] = useState([]);
  const [file, setFile] = useState<any>([]);
  const [item, setItem] = useState<any>({
    id: '',
    category_items: null,
    category_items_id: '',
    brand: null,
    brand_id: '',
    price_by_kg: '',
    discount: '',
    favorite: '',
    stars: 5,
    file: null,
    active: false,
    prime: '',
  });
  const [errorForm, setErrorForm] = useState<any>(null);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    maxFiles: 1,
    multiple: false,
    onDrop: (acceptedFiles: any) => {
      const fileCreateObj = acceptedFiles.map((f: any) =>
        Object.assign(f, {
          preview: URL.createObjectURL(f),
        })
      );

      if (!'image/png,image/jpg,image/jpeg'.includes(fileCreateObj[0].type)) {
        toast.warn('O formato deve ser PNG ou JPEG');
        return;
      }

      setFile(fileCreateObj);
      setItem({ ...item, file: fileCreateObj });
    },
  });

  async function handleGetCategorysItems(): Promise<void> {
    const response: any = await new SubcategoryServices().getAll();
    setCategorysItems(response.data);
  }

  async function handleGetBrands(): Promise<void> {
    const response: any = await new BrandServices().getActive();
    setBrands(response.data);
  }

  async function handleGetAll(): Promise<void> {
    setLoading({
      isOpen: true,
      title: 'Carregando...',
    });
    const response: any = await new ProductServices().getAll();
    setData(response.data);
    setLoading({ ...loading, isOpen: false });
  }

  async function handleRemove(id: number): Promise<void> {
    await toast.promise(
      async () => {
        const response: any = await new ProductServices().delete(id);

        if (response.data === 'error_exist_purshases_items') {
          throw new Error('error_exist_purshases_items');
        }

        await handleGetAll();
      },
      {
        pending: 'Processando',
        success: `Produto removido com sucesso!`,
        error: 'Este Produto possui pedido vinculado!',
      }
    );
  }

  function transformValueInput(value: any) {
    const val = value.replace(',', '');
    const fraction = val.substr(-2);

    return `${val.substring(0, val.length - 2)}.${fraction}`.replace('..', '.');
  }

  async function onSubmit(): Promise<void> {
    try {
      setErrorForm(null);

      let errors = null;
      if (!!item.category_items === false) {
        errors = { category_items: true };
      }

      if (!!item.brand === false) {
        errors = { ...errors, brand: true };
      }

      if (!!item.price_by_kg === false) {
        errors = { ...errors, price_by_kg: true };
      }

      if (!!item.discount === false) {
        errors = { ...errors, discount: true };
      }

      if (!!item.stars === false) {
        errors = { ...errors, stars: true };
      }

      if (!!item.file === false && !!item.path === false) {
        errors = { ...errors, file: true };
      }

      if (errors) {
        setErrorForm(errors);
        toast.warn('Campo(s) obrigatório(s)!');
        return;
      }

      setLoadingSave(true);

      await toast.promise(
        async () => {
          const formData = new FormData();

          formData.append('category_items_id', item.category_items_id);
          formData.append('brand_id', item.brand_id);
          formData.append('price_by_kg', transformValueInput(item.price_by_kg));
          formData.append('discount', transformValueInput(item.discount));
          formData.append('favorite', item.favorite);
          formData.append('stars', item.stars);
          formData.append('active', item.active);
          formData.append('prime', item.prime);

          if (item.file) formData.append('file', item?.file[0]);
          else formData.append('path', item.path);

          if (item.id) {
            await new ProductServices().update(item.id, formData);
          } else {
            await new ProductServices().create(formData);
          }

          setIsOpenForm(false);
          await handleGetAll();
        },
        {
          pending: 'Processando',
          success: `Produto ${item.id ? 'atualizado' : 'criado'} com sucesso!`,
          error: 'Tente novamente mais tarde :(',
        }
      );
    } catch (e: any) {
      console.log(e);
    } finally {
      setLoadingSave(false);
    }
  }

  async function handleRemoveWeight(id: number): Promise<void> {
    await toast.promise(
      async () => {
        await new PriceServices().delete(id);

        setProductWeightRange([
          ...productWeightRange.filter((y: any) => y.id !== id),
        ]);

        await handleGetAll();
      },
      {
        pending: 'Processando',
        success: `Faixa removida com sucesso!`,
        error: 'Ops! Tente novamente mais tarde!',
      }
    );
  }

  function transformValueInputWeight(value: any) {
    const val = value.replace('.', '');
    const fraction = val.substr(-3);

    return `${val.substring(0, val.length - 3)}.${fraction}`.replace('..', '.');
  }

  async function onSubmitWeight(): Promise<void> {
    try {
      setErrorForm(null);

      let errors = null;
      if (!!weight.weight_start === false) {
        errors = { weight_start: true };
      }

      if (!!weight.weight_end === false) {
        errors = { ...errors, weight_end: true };
      }

      if (!!weight.qty === false) {
        errors = { ...errors, qty: true };
      }

      if (errors) {
        setErrorForm(errors);
        toast.warn('Campo(s) obrigatório(s)!');
        return;
      }

      if (Number(weight.weight_end) < Number(weight.weight_start)) {
        setErrorForm(errors);
        toast.warn('O Peso inicial deve ser menor!');
        return;
      }

      const params = {
        product_id: productId,
        qty: weight.qty,
        weight_start: Number(
          transformValueInputWeight(weight.weight_start)
        ).toFixed(3),
        weight_end: Number(
          transformValueInputWeight(weight.weight_end)
        ).toFixed(3),
      };

      setLoadingSave(true);

      await toast.promise(
        async () => {
          if (weight.id) {
            await new PriceServices().update(weight.id, params);
          } else {
            await new PriceServices().create(params);
          }

          const reponse: any = await new PriceServices().getByProduct(
            Number(productId)
          );
          setProductWeightRange(reponse.data);
          setWeight({
            id: '',
            product_id: '',
            weight_start: '',
            weight_end: '',
            qty: 1,
          });

          await handleGetAll();
        },
        {
          pending: 'Processando',
          success: `Faixa ${item.id ? 'atualizada' : 'criada'} com sucesso!`,
          error: 'Tente novamente mais tarde :(',
        }
      );
    } catch (e: any) {
      console.log(e);
    } finally {
      setLoadingSave(false);
    }
  }

  useEffect(() => {
    handleGetCategorysItems();
    handleGetBrands();
    handleGetAll();
  }, []);

  useEffect(() => {
    if (!isOpenForm) {
      setFile([]);
      setItem({
        id: '',
        category_items: null,
        category_items_id: '',
        brand: null,
        brand_id: '',
        price_by_kg: '',
        discount: '',
        favorite: '',
        stars: 5,
        file: null,
        active: false,
        prime: '',
      });
    }
  }, [isOpenForm]);

  return (
    <Layout
      legend={{
        value: data.length,
        text: 'Produtos',
      }}
    >
      {isOpenForm && (
        <Modal
          title={`${item.id ? 'Atualizar' : 'Novo'} Produto`}
          setIsOpen={setIsOpenForm}
          isOpen={isOpenForm}
        >
          <form id="form" autoComplete="off">
            {file.length > 0 ? (
              <div className="flex flex-col items-center gap-1 justify-center">
                <a href={file[0].preview} target="_blank" rel="noreferrer">
                  <img
                    alt="Carletto"
                    src={file[0].preview}
                    className="rounded-md w-[100px] h-[90px]"
                  />
                </a>
                <RiDeleteBin6Line
                  size={17}
                  color="#aaa"
                  onClick={() => {
                    setFile([]);
                    setItem({ ...item, file: null, path: '' });
                  }}
                  className="cursor-pointer"
                />
              </div>
            ) : (
              <div
                {...getRootProps()}
                className={classNames(
                  'border-[1px] border-dashed border-[#aaa] p-4 rounded-md cursor-pointer bg-background mb-4 relative',
                  {
                    'border-[#DC7E19]': isDragActive,
                  }
                )}
              >
                <input {...getInputProps()} />
                {isDragActive ? (
                  <p className="font-light text-sm text-primary">
                    Solte a imagem aqui...
                  </p>
                ) : (
                  <p className="font-light text-sm text-center text-[#555]">
                    Arraste e solte aqui ou clique para selecionar um arquivo
                  </p>
                )}

                <span
                  className={classNames(
                    'absolute text-red text-[0.60rem] left-1 bottom-[-15px]',
                    {
                      hidden: !errorForm?.file,
                    }
                  )}
                >
                  {errorForm?.file ? 'Obrigatório' : ''}
                </span>
              </div>
            )}
            <div className="flex flex-col mt-4 relative">
              <span className="font-light text-xs">Corte</span>
              <SelectCustom
                placeholder="Qual o corte?"
                data={categorysItems}
                borderColor="#ddd"
                defaultValue={
                  !item?.category_items
                    ? { id: '', title: 'Qual o corte?' }
                    : categorysItems.filter(
                        (i: any) => i.id === item.category_items.id
                      )[0]
                }
                noOptionsMessage="Não encontrado"
                marginTop={4}
                onChange={(e: any) =>
                  setItem({
                    ...item,
                    category_items_id: e.id,
                    category_items: e,
                  })
                }
              />
              <span
                className={classNames(
                  'absolute text-red text-[0.60rem] left-1 bottom-[-15px]',
                  {
                    hidden: !errorForm?.category_items,
                  }
                )}
              >
                {errorForm?.category_items ? 'Obrigatório' : ''}
              </span>
            </div>
            <div className="flex flex-col mt-4 relative">
              <span className="font-light text-xs">Marca</span>
              <SelectCustom
                placeholder="Qual a marca?"
                data={brands}
                borderColor="#ddd"
                defaultValue={
                  !item?.brand
                    ? { id: '', title: 'Qual a marca?' }
                    : brands.filter((i: any) => i.id === item.brand.id)[0]
                }
                noOptionsMessage="Não encontrado"
                marginTop={4}
                onChange={(e: any) =>
                  setItem({
                    ...item,
                    brand_id: e.id,
                    brand: e,
                  })
                }
              />
              <span
                className={classNames(
                  'absolute text-red text-[0.60rem] left-1 bottom-[-15px]',
                  {
                    hidden: !errorForm?.brand,
                  }
                )}
              >
                {errorForm?.brand ? 'Obrigatório' : ''}
              </span>
            </div>
            <div className="flex flex-col mt-4 gap-1 relative">
              <span className="font-light text-xs">Preço por Kg</span>
              <input
                className="border-solid border-[0.5px] border-[#aaa] p-3 rounded-md text-xs w-full focus:border-primary focus:border"
                placeholder="Ex: 10,00"
                onChange={(e) =>
                  setItem({
                    ...item,
                    price_by_kg: e.target.value,
                  })
                }
                maxLength={6}
                value={maskHelper.formatCurrencyInput(item.price_by_kg)}
              />
              <span
                className={classNames(
                  'absolute text-red text-[0.60rem] left-1 bottom-[-15px]',
                  {
                    hidden: !errorForm?.price_by_kg,
                  }
                )}
              >
                {errorForm?.price_by_kg ? 'Obrigatório' : ''}
              </span>
            </div>
            <div className="flex flex-col mt-4 gap-1 relative">
              <span className="font-light text-xs">Desconto em R$</span>
              <input
                className="border-solid border-[0.5px] border-[#aaa] p-3 rounded-md text-xs w-full focus:border-primary focus:border"
                placeholder="Ex: 5,00"
                onChange={(e) => setItem({ ...item, discount: e.target.value })}
                value={maskHelper.formatCurrencyInput(item.discount)}
                maxLength={6}
              />
              <span
                className={classNames(
                  'absolute text-red text-[0.60rem] left-1 bottom-[-15px]',
                  {
                    hidden: !errorForm?.discount,
                  }
                )}
              >
                {errorForm?.discount ? 'Obrigatório' : ''}
              </span>
            </div>
            <div className="mt-6 flex items-center gap-2">
              <Switch
                state={item.favorite}
                onChange={() => setItem({ ...item, favorite: !item.favorite })}
                handleDiameter={22}
                width={47}
                height={26}
              />
              <span className="font-light text-sm">
                {item.favorite
                  ? 'Destaque da semana'
                  : 'Não destaque da semana'}
              </span>
            </div>
            <div className="mt-6 flex items-center gap-2">
              <Switch
                state={item.prime}
                onChange={() => setItem({ ...item, prime: !item.prime })}
                handleDiameter={22}
                width={47}
                height={26}
              />
              <span className="font-light text-sm">
                {item.prime ? 'Produto prime' : 'Não é produto prime'}
              </span>
            </div>
            <div className="mt-6 flex items-center gap-2">
              <Switch
                state={item.active}
                onChange={() => setItem({ ...item, active: !item.active })}
                handleDiameter={22}
                width={47}
                height={26}
              />
              <span className="font-light text-sm">
                {item.active ? 'Ativo' : 'Descontinuado'}
              </span>
            </div>
            <div className="flex gap-3 mt-7 justify-end">
              <button
                type="button"
                form="form"
                disabled={loadingSave}
                onClick={onSubmit}
                className={classNames(
                  'relative rounded-md bg-primary p-2 pl-6 pr-6 font-Inter font-bold text-xs w-36',
                  {
                    'bg-opacity-20 text-gray-400 cursor-not-allowed hover:bg-opacity-20':
                      loadingSave,
                  }
                )}
              >
                <span>Salvar</span>
                <ReactLoading
                  type="spokes"
                  color="#DC7E19"
                  width={15}
                  className={classNames('absolute right-3 top-2', {
                    hidden: !loadingSave,
                  })}
                />
              </button>
            </div>
          </form>
        </Modal>
      )}

      {isOpenWeight && (
        <Modal
          // title={`${item.name} - (Peso e estoque)`}
          title="Peso e estoque - "
          setIsOpen={setIsOpenWeight}
          isOpen={isOpenWeight}
          size="2xlarge"
        >
          <strong className="absolute top-[24px] left-40 text-[18px]">
            &nbsp;{`${item.name}`}
          </strong>
          <form id="form" autoComplete="off">
            <div className="flex gap-2">
              <div className="flex flex-col gap-1 relative">
                <span className="font-light text-xs">Peso inicial por kg</span>
                <input
                  className="border-solid border-[0.5px] border-[#aaa] p-3 rounded-md text-xs w-full focus:border-primary focus:border"
                  placeholder="Ex: 1.200"
                  onChange={(e) =>
                    setWeight({
                      ...weight,
                      weight_start: maskHelper.formatWeightInput(
                        e.target.value
                      ),
                    })
                  }
                  maxLength={6}
                  value={weight.weight_start}
                />
                <span
                  className={classNames(
                    'absolute text-red text-[0.60rem] left-1 bottom-[-15px]',
                    {
                      hidden: !errorForm?.weight_start,
                    }
                  )}
                >
                  {errorForm?.weight_start ? 'Obrigatório' : ''}
                </span>
              </div>
              <div className="flex flex-col gap-1 relative">
                <span className="font-light text-xs">Peso final por kg</span>
                <input
                  className="border-solid border-[0.5px] border-[#aaa] p-3 rounded-md text-xs w-full focus:border-primary focus:border"
                  placeholder="Ex: 1.400"
                  onChange={(e) =>
                    setWeight({
                      ...weight,
                      weight_end: maskHelper.formatWeightInput(e.target.value),
                    })
                  }
                  maxLength={6}
                  value={weight.weight_end}
                />
                <span
                  className={classNames(
                    'absolute text-red text-[0.60rem] left-1 bottom-[-15px]',
                    {
                      hidden: !errorForm?.weight_end,
                    }
                  )}
                >
                  {errorForm?.weight_end ? 'Obrigatório' : ''}
                </span>
              </div>
              <div className="flex flex-col gap-1 relative">
                <span className="font-light text-xs">Estoque atual</span>
                <input
                  type="number"
                  className="border-solid border-[0.5px] border-[#aaa] p-3 rounded-md text-xs w-full focus:border-primary focus:border"
                  placeholder="Ex: 999"
                  onChange={(e) =>
                    setWeight({
                      ...weight,
                      qty: e.target.value,
                    })
                  }
                  maxLength={6}
                  value={maskHelper.number(String(weight.qty))}
                />
                <span
                  className={classNames(
                    'absolute text-red text-[0.60rem] left-1 bottom-[-15px]',
                    {
                      hidden: !errorForm?.qty,
                    }
                  )}
                >
                  {errorForm?.qty ? 'Obrigatório' : ''}
                </span>
              </div>

              <div className="flex gap-3 justify-end relative">
                <button
                  type="button"
                  form="form"
                  onClick={onSubmitWeight}
                  className={classNames(
                    'mt-5 flex items-center gap-1 border-primary text-primary border-solid border-[1px] px-5 py-[0.3rem] rounded-md text-xs transition hover:text-black-light hover:bg-primary',
                    {
                      'border-[rgb(170,170,170)] cursor-not-allowed hover:bg-white':
                        loadingSave,
                    }
                  )}
                >
                  {!loadingSave && (
                    <span className="font-Inter font-medium text-xs">
                      salvar
                    </span>
                  )}
                  <ReactLoading
                    type="spokes"
                    color="#DC7E19"
                    width={15}
                    className={classNames('absolute right-[0.8rem] top-8', {
                      hidden: !loadingSave,
                    })}
                  />
                </button>
              </div>
            </div>
            <br />
            <table className="w-full">
              <thead className="flex w-full border-solid border-b-2 text-xs sm:text-sm bg-primary-light/25">
                <tr className="flex w-full">
                  <th className="p-1 text-center rounded-tl-sm w-1/6">
                    Peso inicial
                  </th>
                  <th className="p-1 text-center w-1/6">Peso final</th>
                  <th className="p-1 text-center w-1/6">Estoque atual</th>
                  <th className="p-1 text-center w-1/6">&nbsp;</th>
                  <th className="p-1 text-center rounded-tr-sm  w-1/6">
                    &nbsp;
                  </th>
                </tr>
              </thead>
              <tbody className="font-light text-xs sm:text-sm flex flex-col">
                {productWeightRange.map((w: any) => (
                  <tr className="flex items-center w-full" key={Math.random()}>
                    <td className="p-1 text-center w-1/6">
                      {w.weight_start} kg
                    </td>
                    <td className="p-1 text-center w-1/6">{w.weight_end} kg</td>
                    <td className="p-1 text-center w-1/6">{w.qty}</td>
                    <td className="p-1 text-center w-1/6 pl-10">
                      <FaEdit
                        className="cursor-pointer"
                        color="#DC7E19"
                        size={16}
                        onClick={() => {
                          setWeight(w);
                        }}
                      />
                    </td>
                    <th className="p-1 text-center w-1/6">
                      <RiDeleteBin6Line
                        className="cursor-pointer"
                        color="#B0B0B0"
                        size={16}
                        onClick={() =>
                          setAlert({
                            title: 'Atenção!',
                            description: 'Confirma a exclusão desta faixa?',
                            isOpen: true,
                            button: [
                              {
                                title: 'Não',
                                type: 'black-regular',
                                onClick: () =>
                                  setAlert({ ...alert, isOpen: false }),
                              },
                              {
                                title: 'Sim, confirmar',
                                type: 'red',
                                onClick: () => {
                                  handleRemoveWeight(w.id);
                                  setAlert({ ...alert, isOpen: false });
                                },
                              },
                            ],
                          })
                        }
                      />
                    </th>
                  </tr>
                ))}
              </tbody>
            </table>
          </form>
        </Modal>
      )}

      <div className="w-full bg-white rounded-lg shadow-xs sm:min-h-[500px] 2xl:min-h-[700px]">
        <div className="flex flex-row justify-between items-center mb-8 relative">
          <div className="flex flex-row items-center gap-2">
            <RiProductHuntLine size={22} color="#DC7E19" />
            <span className="font-bold text-lg">Produtos</span>
          </div>

          <button
            type="button"
            onClick={() => {
              setIsOpenForm(true);
            }}
            className="flex items-center gap-1 border-primary text-primary border-solid border-[1px] px-3 py-[0.4rem] rounded-md text-xs transition hover:text-black-light hover:bg-primary"
          >
            <AiOutlinePlus />
            <span>adicionar</span>
          </button>
        </div>

        <table className="text-left w-full">
          <thead className="flex w-full border-solid border-b-2 text-xs sm:text-sm bg-primary-light/25">
            <tr className="flex w-full">
              <th className="p-1 text-left rounded-tl-sm w-1/6">&nbsp;</th>
              <th className="p-1 text-left w-1/6">Corte</th>
              <th className="p-1 text-left w-1/6">Marca</th>
              <th className="p-1 text-left w-1/6">Preço kg</th>
              <th className="p-1 text-left w-1/6">Desconto</th>
              <th className="p-1 text-left w-1/6">Valor venda</th>
              <th className="p-1 text-left w-1/6">Destaque</th>
              <th className="p-1 text-left w-1/6">Prime</th>
              <th className="p-1 text-left w-1/6">Status</th>
              <th className="p-1 text-center  w-1/12">&nbsp;</th>
              <th className="p-1 text-center  w-1/12">&nbsp;</th>
              <th className="p-1 text-center rounded-tr-sm  w-1/12">&nbsp;</th>
            </tr>
          </thead>
          <tbody
            className="font-light text-xs sm:text-sm flex flex-col  overflow-y-auto w-full"
            style={{ height: data.length > 10 ? '60vh' : '' }}
          >
            {data.map((prod: any) => (
              <tr
                key={prod.id}
                className={classNames('flex items-center w-full', {
                  'text-black-regular': !prod.active,
                })}
              >
                <td className="p-1 text-left w-1/6">
                  <a
                    href={`${process.env.REACT_APP_API}/${prod.path}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      alt="Carletto"
                      src={`${process.env.REACT_APP_API}/${prod.path}`}
                      loading="lazy"
                      className="w-[50px] h-[40px] object-cover rounded-md"
                    />
                  </a>
                </td>
                <td className="p-1 text-left w-1/6">
                  {prod.category_items.title}
                </td>
                <td className="p-1 text-left w-1/6">{prod.brand.title}</td>
                <td className="p-1 text-left w-1/6">
                  {maskHelper.formatMoeda(prod.price_by_kg)}
                </td>
                <td className="p-1 text-left w-1/6">
                  {maskHelper.formatMoeda(prod.discount)}
                </td>
                <td className="p-1 text-left w-1/6">
                  {maskHelper.formatMoeda(prod.price_by_kg - prod.discount)}
                </td>
                <td className="p-1 text-left w-1/6">
                  {prod.favorite ? 'Sim' : 'Não'}
                </td>
                <td className="p-1 text-left w-1/6">
                  {prod.prime ? 'Sim' : 'Não'}
                </td>
                <td className="p-1 text-left w-1/6">
                  {prod.active ? 'Ativo' : 'Inativo'}
                </td>
                <td className="p-1 text-left w-1/12">
                  <FaEdit
                    className="cursor-pointer"
                    color="#DC7E19"
                    size={16}
                    onClick={() => {
                      setItem({
                        ...prod,
                        price_by_kg: prod.price_by_kg.substr(-2).includes('.')
                          ? `${prod.price_by_kg}0`
                          : prod.price_by_kg.includes('.')
                          ? prod.price_by_kg
                          : String(Number(prod.price_by_kg) * 100),
                        discount: prod.discount.substr(-2).includes('.')
                          ? `${prod.discount}0`
                          : prod.discount.includes('.')
                          ? prod.discount
                          : String(Number(prod.discount) * 100),
                      });
                      setFile([
                        {
                          preview: `${process.env.REACT_APP_API}/${prod.path}`,
                        },
                      ]);
                      setIsOpenForm(true);
                    }}
                  />
                </td>
                <td className="p-1 text-left w-1/12">
                  <GiWeight
                    className="cursor-pointer"
                    color="#006400"
                    size={20}
                    onClick={() => {
                      setItem({
                        ...prod,
                        name: `${prod.category_items.subtitle} ${prod.brand.title}`,
                      });
                      setProductId(prod.id);
                      setProductWeightRange(prod.product_weight_range);
                      setIsOpenWeight(true);
                    }}
                  />
                </td>
                <td className="p-1 text-left w-1/12">
                  <RiDeleteBin6Line
                    className="cursor-pointer"
                    color="#B0B0B0"
                    size={16}
                    onClick={() =>
                      setAlert({
                        title: 'Atenção!',
                        description: 'Confirma a exclusão deste Produto?',
                        isOpen: true,
                        button: [
                          {
                            title: 'Não',
                            type: 'black-regular',
                            onClick: () =>
                              setAlert({ ...alert, isOpen: false }),
                          },
                          {
                            title: 'Sim, confirmar',
                            type: 'red',
                            onClick: () => {
                              handleRemove(prod.id);
                              setAlert({ ...alert, isOpen: false });
                            },
                          },
                        ],
                      })
                    }
                  />
                </td>
              </tr>
            ))}

            {!loading.isOpen && data.length === 0 && (
              <tr>
                <td className="p-1 text-left">Nenhum Produto cadastrado.</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </Layout>
  );
}
