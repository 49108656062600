import HttpClient from '../helpers/httpClient.helper';

class GoogleApiServices {
  private api;

  constructor() {
    this.api = new HttpClient(String(process.env.REACT_APP_API_GOOGLE));
  }

  async getInfoAddress(address: string): Promise<void> {
    return await this.api.get(
      `maps/api/geocode/json?address=${address}&sensor=false&key=${process.env.REACT_APP_API_GOOGLE_KEY}`
    );
  }
}

export default GoogleApiServices;
