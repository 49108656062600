import { VscChecklist } from 'react-icons/vsc';
import { useContext, useEffect, useState } from 'react';
import ReactLoading from 'react-loading';
import moment from 'moment';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { BsPrinter } from 'react-icons/bs';
import { MdOutlineVerifiedUser } from 'react-icons/md';
import { motion } from 'framer-motion';
import Footer from '../../../components/Footer';
import Copyright from '../../../components/Copyright';
import Header from '../../../components/Header';
import { SubscriptionServices, UserServices } from '../../../services';
import maskHelper from '../../../helpers/mask.helper';
import { translateStatusPay } from '../../../helpers/utils.helper';
import yup from '../../../config/yup';
import Modal from '../../../components/Modal';
import LoadingContext from '../../../contexts/loading.context';
import AlertContext from '../../../contexts/alert.context';

interface IForm {
  holderName: string;
  number: string;
  expiry: string;
  cvv: string;
}

export default function PaymentsPage() {
  const [data, setData] = useState<any[]>([]);
  const [loadingPage, setLoadingPage] = useState(true);
  const { loading, setLoading } = useContext(LoadingContext);
  const { alert, setAlert } = useContext(AlertContext);
  const [active, setActive] = useState(true);
  const [isOpenForm, setIsOpenForm] = useState(false);
  const navigate = useNavigate();

  const schema = yup
    .object({
      holderName: yup.string().required(),
      number: yup.string().length(19, 'Obrigatório 16 dígitos').required(),
      expiry: yup
        .string()
        .length(7, 'Valida inválida')
        .required()
        .test('valid-expiry', 'Mês/Ano inválido', (value) => {
          const expiryMonth = Number(value?.substring(0, 2));
          const expiryYear = Number(value?.substring(3, 7));
          const date = new Date();
          const year = date.getFullYear();
          const month = new Date().getMonth() + 1;

          if (expiryMonth > 0 && expiryMonth <= 12 && expiryYear >= year) {
            if (year === expiryYear && month > expiryMonth) return false;

            return true;
          }

          return false;
        }),
      cvv: yup
        .string()
        .min(3, 'Mínimo 3 dígitos')
        .max(4, 'Máximo 4 dígitos')
        .required(),
    })
    .required();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<IForm>({
    resolver: yupResolver(schema),
  });

  async function handleGetAll(): Promise<void> {
    const response: any = await new UserServices().getSubscriptionsPays();
    setData(response.data);

    const subs = response.data[0].subscription.status;
    setActive(subs === 'ACTIVE');

    setLoadingPage(false);
  }

  async function onSubmit(dataForm: IForm) {
    try {
      setLoading({
        isOpen: true,
        title: 'Processando',
      });

      const response: any = await new SubscriptionServices().renovate(
        data[0].id,
        JSON.parse(data[0].pay_json).subscription,
        {
          holderName: dataForm.holderName.toUpperCase(),
          number: dataForm.number.replace(/\s/g, ''),
          expiryMonth: dataForm.expiry.substring(0, 2),
          expiryYear: dataForm.expiry.substring(3, 7),
          ccv: dataForm.cvv,
        }
      );

      if (response.data?.errors) {
        toast.warn(response.data?.errors[0].description);
        return;
      }

      setIsOpenForm(false);

      setAlert({
        title: 'Parábens, assinatura concluída!',
        description: 'Aproveite e confira nossos produtos :)',
        isOpen: true,
        button: [
          {
            title: 'Ok, vamos lá!',
            type: 'green',
            onClick: () => {
              setAlert({ ...alert, isOpen: false });
              navigate('/products');
            },
          },
        ],
      });
    } catch (e: any) {
      console.log('error', e);
    } finally {
      setLoading({
        isOpen: false,
      });
    }
  }

  useEffect(() => {
    handleGetAll();
  }, []);

  return (
    <div className="z-0 h-screen relative flex justify-center">
      {isOpenForm && (
        <Modal
          title="Ativar assinatura"
          header={false}
          setIsOpen={setIsOpenForm}
          isOpen={isOpenForm}
          size="medium"
        >
          <form
            id="form-step-"
            onSubmit={handleSubmit(onSubmit)}
            autoComplete="off"
            className="flex flex-col w-full items-center"
          >
            <div className="flex items-center justify-between w-[85%]">
              <div className="flex items-center gap-2">
                <MdOutlineVerifiedUser size={20} color="#DC7E19" />
                <span className="text-xl font-bold">Ativar sua assinatura</span>
              </div>
              <button
                type="button"
                className="text-2xl font-bold"
                onClick={() => setIsOpenForm(false)}
              >
                x
              </button>
            </div>
            <span className="w-[85%] text-sm font-light mb-6 mt-4">
              Para finalizar, preencha as informações para assinatura
            </span>
            <div className="flex flex-col gap-[1.20rem] w-[85%] mb-6">
              <div className="relative">
                <input
                  className={classNames(
                    'uppercase w-[100%] border-[1px] border-solid border-black-regular placeholder:text-black-regular p-[0.65rem] rounded-md text-xs focus:border-primary',
                    {
                      'border-red': !!errors.holderName,
                    }
                  )}
                  placeholder="Nome no Cartão de Crédito"
                  {...register('holderName')}
                />
                <span
                  className={classNames(
                    'absolute text-red text-[0.60rem] left-1 bottom-[-15px]',
                    {
                      hidden: !!errors.holderName === false,
                    }
                  )}
                >
                  {errors.holderName?.message}
                </span>
              </div>
              <div className="relative">
                <input
                  className={classNames(
                    'w-[100%] border-[1px] border-solid border-black-regular placeholder:text-black-regular p-[0.65rem] rounded-md text-xs focus:border-primary',
                    {
                      'border-red': !!errors.number,
                    }
                  )}
                  placeholder="Número do Cartão"
                  {...register('number')}
                  value={maskHelper.creditCard(String(watch('number')))}
                />
                <span
                  className={classNames(
                    'absolute text-red text-[0.60rem] left-1 bottom-[-15px]',
                    {
                      hidden: !!errors.number === false,
                    }
                  )}
                >
                  {errors.number?.message}
                </span>
              </div>
              <div className="flex justify-between gap-4">
                <div className="relative">
                  <input
                    className={classNames(
                      'w-[100%] border-[1px] border-solid border-black-regular placeholder:text-black-regular p-[0.65rem] rounded-md text-xs focus:border-primary',
                      {
                        'border-red': !!errors.expiry,
                      }
                    )}
                    placeholder="mm/aaaa"
                    {...register('expiry')}
                    value={maskHelper.monthYear(String(watch('expiry')))}
                  />
                  <span
                    className={classNames(
                      'absolute text-red text-[0.60rem] left-1 bottom-[-15px]',
                      {
                        hidden: !!errors.expiry === false,
                      }
                    )}
                  >
                    {errors.expiry?.message}
                  </span>
                </div>
                <div className="relative">
                  <input
                    className={classNames(
                      'w-[100%] border-[1px] border-solid border-black-regular placeholder:text-black-regular p-[0.65rem] rounded-md text-xs focus:border-primary',
                      {
                        'border-red': !!errors.cvv,
                      }
                    )}
                    placeholder="CVV"
                    {...register('cvv')}
                    value={maskHelper.number(String(watch('cvv')))}
                    maxLength={4}
                  />
                  <span
                    className={classNames(
                      'absolute text-red text-[0.60rem] left-1 bottom-[-15px]',
                      {
                        hidden: !!errors.cvv === false,
                      }
                    )}
                  >
                    {errors.cvv?.message}
                  </span>
                </div>
              </div>
            </div>
            <button
              type="submit"
              form="form-step-"
              className={classNames(
                'relative bg-primary p-[0.65rem] pl-4 pr-4 rounded-md text-xs focus:border-primary w-[85%] font-bold transition shadow-lg hover:bg-opacity-85',
                {
                  'bg-opacity-20 text-gray-400 cursor-not-allowed hover:bg-opacity-20':
                    loadingPage,
                }
              )}
            >
              <span>Finalizar</span>
              <ReactLoading
                type="spokes"
                color="#DC7E19"
                width={17}
                className={classNames('absolute right-3 top-2', {
                  hidden: !loadingPage,
                })}
              />
            </button>
          </form>
        </Modal>
      )}
      <div className="bg-white relative w-full h-fit">
        <Header
          isDivideMarginTop
          title="Nossos produtos"
          description="As melhores peças para você."
          isApresentation={false}
        />

        <motion.section
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="flex flex-col justify-center items-center gap-2 px-0 lg:px-56 bg-background h-fit lg:h-[90%] p-0 sm:p-20"
        >
          <div className="w-[100%] sm:w-[900px] bg-white rounded-lg shadow-xs p-7 sm:min-h-[500px] 2xl:min-h-[700px] h-fit">
            <div className="flex justify-between items-center mb-4">
              <div className="flex flex-row items-center gap-2 relative">
                <VscChecklist size={22} color="#DC7E19" />
                <span className="font-bold text-lg">Clube Carletto</span>
                {loadingPage && (
                  <ReactLoading
                    type="spokes"
                    color="#DC7E19"
                    width={17}
                    className="absolute top-[0.35rem] left-48"
                  />
                )}
              </div>
              <button
                type="button"
                disabled={active}
                onClick={() => setIsOpenForm(true)}
                className={`rounded-md border  h-7 ${
                  active
                    ? 'borde-[#aaa] text-[#aaa] cursor-not-allowed'
                    : 'border-red text-red hover:bg-red hover:text-black cursor-pointer'
                }  border-solid pl-6 pr-6 font-Inter font-bold text-xs `}
              >
                {active ? 'Ativa' : 'Reativar'}
              </button>
            </div>
            <table className="table-auto w-full">
              <thead className="border-solid border-b-2 text-xs sm:text-sm">
                <tr>
                  <th className="p-1 text-left rounded-tl-sm">Vencimento</th>
                  <th className="p-1 rounded-tl-sm text-left">Pagamento</th>
                  <th className="p-1 text-left rounded-tr-sm">
                    Próxima cobrança
                  </th>
                  <th className="p-1 text-left rounded-tr-sm">imprimir</th>
                </tr>
              </thead>
              <tbody className="font-light text-xs sm:text-sm ">
                {data.map((item: any) => {
                  const json = JSON.parse(item.pay_json);

                  return (
                    <tr key={item.id}>
                      <td className="p-1 text-left">
                        {maskHelper.formatDateDMY(json.dueDate)}
                      </td>
                      <td className="p-1 text-left">
                        {translateStatusPay(item.status)}
                      </td>
                      <td className="p-1 text-left">
                        {maskHelper.formatDateDMY(
                          String(
                            moment(json.dueDate)
                              .add(1, 'months')
                              .format('DD/MM/YYYY')
                          )
                        )}
                      </td>
                      <td className="p-1 pl-6 text-center">
                        {translateStatusPay(item.status) === 'Confirmado' ? (
                          <a
                            href={json.transactionReceiptUrl || json.invoiceUrl}
                            target="_blank"
                            rel="noreferrer"
                            className="cursor-pointer"
                          >
                            <BsPrinter size={17} color="#DC7E19" />
                          </a>
                        ) : (
                          <BsPrinter
                            size={17}
                            color="#aaa"
                            className="cursor-not-allowed"
                          />
                        )}
                      </td>
                    </tr>
                  );
                })}
                {!loading && data.length === 0 && (
                  <tr>
                    <td className="p-1 text-left">
                      Nenhum pagamento registrado
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </motion.section>

        <Footer />
        <Copyright />
      </div>
    </div>
  );
}
