import HttpClient from '../helpers/httpClient.helper';
import { AuthorizationToken } from './common/authorization.token';

class BrandServices extends AuthorizationToken {
  private api;

  constructor() {
    super();
    this.api = new HttpClient(String(process.env.REACT_APP_API), this.token);
  }

  async create(data: any): Promise<void> {
    return await this.api.post('brand', data);
  }

  async update(id: number, data: any): Promise<void> {
    return await this.api.put(`brand/${id}`, data);
  }

  async delete(id: number): Promise<void> {
    return await this.api.delete(`brand/${id}`);
  }

  async getAll(): Promise<void> {
    return await this.api.get('brand');
  }

  async getActive(): Promise<void> {
    return await this.api.get('brand/active');
  }
}

export default BrandServices;
