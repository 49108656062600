import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

// import slide00 from '../../../assets/images/slide00.png';
import slide01 from '../../../assets/images/slide01.png';
import slide02 from '../../../assets/images/slide02.png';
import slide03 from '../../../assets/images/slide03.png';
import slide04 from '../../../assets/images/slide04.png';
import slide05 from '../../../assets/images/slide05.jpg';

const Slider: React.FC = () => {
  const navigate = useNavigate();
  const [index, setIndex] = useState(0);
  const [showArrow, setShowArrow] = useState(false);

  const bannerLeft = () => {
    if (index > 0) {
      const banner: any = document.getElementById('banner');
      banner.scrollLeft -= window.screen.width;
      setIndex((prev) => prev - 1);
    }
  };

  const bannerRight = () => {
    if (index < 5) {
      const banner: any = document.getElementById('banner');
      banner.scrollLeft += window.screen.width;
      setIndex((prev) => prev + 1);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      bannerRight();
    }, 4000);
    setTimeout(() => {
      bannerRight();
    }, 8000);
    setTimeout(() => {
      bannerRight();
    }, 12000);
    setTimeout(() => {
      bannerRight();
      setShowArrow(true);
    }, 16000);
    // setTimeout(() => {
    //   bannerRight();
    //   setShowArrow(true);
    // }, 20000);
  }, []);

  return (
    <>
      <div className="relative flex items-center bg-black top-0 sm:-top-6 2xl:-top-3">
        {showArrow && (
          <MdChevronLeft
            size={70}
            color="#DC7E19"
            onClick={bannerLeft}
            className={classNames(
              'absolute left-0 active:animate-ping cursor-pointer hover:opacity-100 hover:scale-125 ease-in-out duration-300 hidden sm:inline-block',
              {
                'opacity-20': index === 0,
              }
            )}
          />
        )}
        <div
          id="banner"
          className="overflow-x-scroll scroll whitespace-nowrap scroll-smooth no-scrollbar lg:h-[1-vh]"
        >
          {/* <div
            key={Math.random()}
            className="inline-block sm:ease-in-out sm:duration-300"
          >
            <img
              alt="Carletto"
              src={slide00}
              loading="lazy"
              className="h-[200px] lg:h-[90.5vh] cursor-pointer w-screen"
            />
          </div> */}
          <div
            key={Math.random()}
            className="inline-block sm:ease-in-out sm:duration-300"
          >
            <img
              alt="Carletto"
              src={slide05}
              loading="lazy"
              className="h-[200px] lg:h-[90.5vh] cursor-pointer w-screen"
            />
          </div>
          <div
            key={Math.random()}
            className="inline-block sm:ease-in-out sm:duration-300"
          >
            <img
              alt="Carletto"
              src={slide01}
              loading="lazy"
              className="h-[200px] lg:h-[94vh] cursor-pointer w-screen"
            />
          </div>
          <div
            key={Math.random()}
            className="inline-block sm:ease-in-out sm:duration-300"
          >
            <img
              alt="Carletto"
              src={slide02}
              loading="lazy"
              className="h-[200px] lg:h-[94vh] cursor-pointer w-screen"
            />
          </div>
          <div
            key={Math.random()}
            className="inline-block sm:ease-in-out sm:duration-300"
          >
            <img
              alt="Carletto"
              src={slide03}
              loading="lazy"
              className="h-[200px] lg:h-[94vh] cursor-pointer w-screen"
            />
          </div>
          <div
            key={Math.random()}
            className="inline-block sm:ease-in-out sm:duration-300"
          >
            <img
              alt="Carletto"
              src={slide04}
              loading="lazy"
              onClick={() => navigate('/Register')}
              className="h-[200px] lg:h-[94vh] cursor-pointer w-screen"
            />
          </div>
        </div>

        <div className="absolute -mb-14 hidden sm:flex sm:bottom-24 w-full items-center justify-center sm:gap-2 gap-1 z-10">
          <div
            className={`sm:h-4 sm:w-4 rounded-full cursor-pointer ${
              index === 0 ? 'bg-primary' : 'bg-white'
            }`}
          />
          <div
            className={`sm:h-4 sm:w-4 rounded-full cursor-pointer ${
              index === 1 ? 'bg-primary' : 'bg-white'
            }`}
          />
          <div
            className={`sm:h-4 sm:w-4 rounded-full cursor-pointer ${
              index === 2 ? 'bg-primary' : 'bg-white'
            }`}
          />
          <div
            className={`sm:h-4 sm:w-4 rounded-full cursor-pointer ${
              index === 3 ? 'bg-primary' : 'bg-white'
            }`}
          />
          <div
            className={`sm:h-4 sm:w-4 rounded-full cursor-pointer ${
              index === 4 ? 'bg-primary' : 'bg-white'
            }`}
          />
          {/* <div
            className={`sm:h-4 sm:w-4 rounded-full cursor-pointer ${
              index === 5 ? 'bg-primary' : 'bg-white'
            }`}
          /> */}
        </div>

        {showArrow && (
          <MdChevronRight
            size={70}
            color="#DC7E19"
            onClick={bannerRight}
            className={classNames(
              'absolute right-0 cursor-pointer active:animate-ping hover:opacity-100 hover:scale-125 ease-in-out duration-300 hidden sm:inline-block',
              {
                'opacity-20': index === 3,
              }
            )}
          />
        )}
      </div>

      <div className="bg-primary h-[0.4rem] w-[100%] -mt-6" />
    </>
  );
};

export default Slider;
