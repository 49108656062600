import { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { FiUnlock } from 'react-icons/fi';
import { BiLockAlt } from 'react-icons/bi';
import { RiUserSettingsLine } from 'react-icons/ri';
import classNames from 'classnames';
import { AiFillApple, AiOutlineAndroid } from 'react-icons/ai';
import { UserServices } from '../../../services';
import Layout from '../components/Layout';
import maskHelper from '../../../helpers/mask.helper';
import AlertContext from '../../../contexts/alert.context';
import LoadingContext from '../../../contexts/loading.context';

export default function User() {
  const { alert, setAlert } = useContext(AlertContext);
  const { loading, setLoading } = useContext(LoadingContext);
  const [data, setData] = useState([]);

  async function handleGetAll(): Promise<void> {
    setLoading({
      isOpen: true,
      title: 'Carregando...',
    });
    const response: any = await new UserServices().getAll();
    setData(response.data);
    setLoading({ ...loading, isOpen: false });
  }

  async function handleChangeRole(id: number, roleId: number) {
    await toast.promise(
      async () => {
        await new UserServices().changeRole(id, roleId);
        await handleGetAll();
      },
      {
        pending: 'Processando',
        success: `Pedido atualizado com sucesso!`,
        error: 'Ops! Tente novamente mais tarde!',
      }
    );
  }

  useEffect(() => {
    handleGetAll();
  }, []);

  return (
    <Layout
      legend={{
        value: data.length,
        text: 'Usuários',
      }}
    >
      <div className="w-full bg-white rounded-lg shadow-xs sm:min-h-[500px] 2xl:min-h-[700px] h-fit">
        <div className="flex flex-row justify-between items-center mb-8 relative">
          <div className="flex flex-row items-center gap-2">
            <RiUserSettingsLine size={22} color="#DC7E19" />
            <span className="font-bold text-lg">Usuários</span>
          </div>
        </div>
        <table className="text-left w-full">
          <thead className="flex w-full border-solid border-b-2 text-xs sm:text-sm bg-primary-light/25">
            <tr className="flex w-full">
              <th className="p-2 text-left rounded-tl-sm w-2/6">Nome</th>
              <th className="p-2 text-left w-2/6">E-mail</th>
              <th className="p-2 text-left w-1/6">Telefone</th>
              <th className="p-2 text-left w-1/6">Aniversário</th>
              <th className="p-2 text-left w-1/6">CPF</th>
              <th className="p-2 text-left w-1/6">Plataforma</th>
              <th className="p-2 text-left w-1/6">Perfil</th>
              <th className="p-2 text-left rounded-tr-sm w-1/6">&nbsp;</th>
            </tr>
          </thead>
          <tbody
            className="font-light text-xs sm:text-sm flex flex-col  overflow-y-auto w-full"
            style={{ height: data.length > 10 ? '60vh' : '' }}
          >
            {data.map((item: any) => {
              let indicatedName = 'Nenhuma';

              if (item.user?.name) {
                indicatedName = item.user?.name;

                if (indicatedName.includes(' ')) {
                  const indicatedArr = indicatedName.split(' ');

                  if (
                    indicatedArr[1] === 'de' ||
                    indicatedArr[1] === 'da' ||
                    indicatedArr[1] === 'do'
                  ) {
                    indicatedName = `${indicatedArr[0]} ${indicatedArr[2]}`;
                  } else {
                    indicatedName = `${indicatedArr[0]} ${indicatedArr[1]}`;
                  }
                }
              }
              return (
                <tr key={Math.random()} className="flex items-center w-full">
                  <td className="p-2 text-left w-2/6">
                    <p>{item.name}</p>
                    <p
                      className={classNames(
                        'text-xs fo text-primary font-bold',
                        {
                          'text-black-regular': indicatedName === 'Nenhuma',
                        }
                      )}
                    >
                      Indicação: {indicatedName}
                    </p>
                  </td>
                  <td className="p-2 text-left w-2/6">{item.email}</td>
                  <td className="p-2 text-left w-1/6">
                    {maskHelper.phone(item.phone)}
                  </td>
                  <td className="p-2 text-left w-1/6">
                    {maskHelper.formatDateDMY(item.birth)}
                  </td>
                  <td className="p-2 text-left w-1/6">
                    {maskHelper.cpf(item.document)}
                  </td>
                  <td className="pl-10 text-center w-1/6">
                    {item.os ? (
                      item.os === 'ios' ? (
                        <AiFillApple size={25} color="#b103009a" />
                      ) : (
                        <AiOutlineAndroid size={25} color="#A4C639" />
                      )
                    ) : (
                      ''
                    )}
                  </td>
                  <td className="p-2 text-left w-1/6">
                    {item.role_id === 1 ? 'Cliente' : 'Admin'}
                  </td>
                  <td className="p-2 text-left w-1/6 flex items-center gap-2">
                    {item.role_id === 1 ? (
                      <BiLockAlt
                        size={22}
                        color="#aaa"
                        className="cursor-pointer"
                        onClick={() => {
                          setAlert({
                            title: 'Atenção!',
                            description:
                              'Deseja tornar este usuário ADMIN do sistema?',
                            isOpen: true,
                            button: [
                              {
                                title: 'Não',
                                type: 'black-regular',
                                onClick: () =>
                                  setAlert({ ...alert, isOpen: false }),
                              },
                              {
                                title: 'Sim, confirmar',
                                type: 'red',
                                onClick: () => {
                                  handleChangeRole(item.id, 2);
                                  setAlert({ ...alert, isOpen: false });
                                },
                              },
                            ],
                          });
                        }}
                      />
                    ) : (
                      <FiUnlock
                        size={22}
                        color="#32BC50"
                        className="cursor-pointer"
                        onClick={() => {
                          setAlert({
                            title: 'Atenção!',
                            description:
                              'Deseja tornar este usuário apenas CLIENTE do sistema?',
                            isOpen: true,
                            button: [
                              {
                                title: 'Não',
                                type: 'black-regular',
                                onClick: () =>
                                  setAlert({ ...alert, isOpen: false }),
                              },
                              {
                                title: 'Sim, confirmar',
                                type: 'red',
                                onClick: () => {
                                  handleChangeRole(item.id, 1);
                                  setAlert({ ...alert, isOpen: false });
                                },
                              },
                            ],
                          });
                        }}
                      />
                    )}
                  </td>
                </tr>
              );
            })}
            {!loading.isOpen && data.length === 0 && (
              <tr>
                <td className="p-1 text-left">Nenhum usuário realizado.</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </Layout>
  );
}
