import { MdOutlineRequestQuote } from 'react-icons/md';
import { BsEye, BsFillEyeFill } from 'react-icons/bs';
import { useEffect, useState } from 'react';
import ReactLoading from 'react-loading';
import { TiArrowBack } from 'react-icons/ti';
import { motion } from 'framer-motion';
import Footer from '../../../components/Footer';
import Copyright from '../../../components/Copyright';
import Header from '../../../components/Header';
import PurshaseServices from '../../../services/purshase.service';
import maskHelper from '../../../helpers/mask.helper';
import { translateTypePay } from '../../../helpers/utils.helper';

export default function RequestsPage() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [details, setDetails] = useState(false);
  const [dataDetails, setDataDetails] = useState<any>(null);

  useEffect(() => {
    (async () => {
      const response: any = await new PurshaseServices().getByUser();
      setData(response.data);
      setLoading(false);
    })();
  }, []);

  return (
    <div className="z-0 h-screen relative flex justify-center">
      <div className="bg-white relative w-full h-fit">
        <Header
          isDivideMarginTop
          title="Nossos produtos"
          description="As melhores peças para você."
          isApresentation={false}
        />

        <motion.section
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="flex flex-col justify-center items-center gap-2 px-0 lg:px-56 bg-background h-fit lg:h-[90%] p-0 sm:p-20"
        >
          <div className="w-[100%] sm:w-[900px] bg-white rounded-lg shadow-xs p-4 sm:p-7 sm:min-h-[500px] 2xl:min-h-[700px] h-fit">
            <div className="mb-6">
              {details ? (
                <div className="flex flex-col">
                  <div className="flex flex-row items-center justify-between">
                    <div className="flex flex-row items-center gap-2">
                      <BsFillEyeFill size={22} color="#DC7E19" />
                      <span className="font-bold text-md sm:text-lg">
                        Detalhes do pedido #{dataDetails?.number}
                      </span>
                    </div>{' '}
                    <button
                      type="button"
                      onClick={() => setDetails(false)}
                      className="flex items-center justify-center gap-1 rounded-md p-2 pl-6 pr-6 font-Inter font-bold text-xs text-primary"
                    >
                      <TiArrowBack size={22} />
                      <span>Voltar</span>
                    </button>
                  </div>
                  <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between mt-6 mb-4">
                    <div className="flex flex-col mb-6 sm:mb-0">
                      <span className="font-bold mb-3 text-sm">
                        Dados do Pedido
                      </span>
                      <div className="flex flex-col font-light text-xs gap-1">
                        <span>
                          Data da Compra:{' '}
                          {maskHelper.formatDateDMY(dataDetails?.created_at)}
                        </span>
                        <span>Status do Pedido: {dataDetails?.status}</span>
                        <span>
                          Método de Pagamento:{' '}
                          {translateTypePay(dataDetails?.pay.billingType)}
                        </span>
                      </div>
                    </div>
                    <div className="flex flex-col mb-6 sm:mb-0">
                      <span className="font-bold mb-3 text-sm">
                        Dados do Cliente
                      </span>
                      <div className="flex flex-col font-light text-xs gap-1">
                        <span>Nome: {dataDetails?.user.name}</span>
                        <span>E-mail: {dataDetails?.user.email}</span>
                        <span>
                          Telefone: {maskHelper.phone(dataDetails?.user.phone)}
                        </span>
                      </div>
                    </div>
                    <div className="flex flex-col mb-0">
                      <span className="font-bold mb-3 text-sm">
                        Dados da Entrega
                      </span>
                      <div className="flex flex-col font-light text-xs gap-1">
                        <span>
                          {dataDetails?.user.address},{' '}
                          {dataDetails?.user.number},{' '}
                          {dataDetails?.user.complement}
                        </span>
                        <span>
                          {dataDetails?.user.province} -{' '}
                          {dataDetails?.user.city}/{dataDetails?.user.state}
                        </span>
                        <span>
                          {maskHelper.cep(
                            String(dataDetails?.user.postal_code)
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="flex flex-row items-center gap-2 relative">
                  <MdOutlineRequestQuote size={22} color="#DC7E19" />
                  <span className="font-bold text-lg">Meus pedidos</span>
                  {loading && (
                    <ReactLoading
                      type="spokes"
                      color="#DC7E19"
                      width={17}
                      className="absolute top-[0.35rem] left-40"
                    />
                  )}
                </div>
              )}
            </div>

            {!details ? (
              <table className="table-auto w-full">
                <thead className="border-solid border-b-2 text-xs sm:text-sm">
                  <tr>
                    <th className="p-2 text-left rounded-tl-sm">Pedido</th>
                    <th className="p-2 text-left">Status</th>
                    <th className="p-2 text-left">Total</th>
                    <th className="p-2 text-left">Data do Pedido</th>
                    <th className="p-2 text-left rounded-tr-sm">ver</th>
                  </tr>
                </thead>
                <tbody className="font-light text-xs sm:text-sm ">
                  {data.map((item: any) => (
                    <tr key={Math.random()}>
                      <td className="p-2 text-left">{item.number}</td>
                      <td className="p-2 text-left">{item.status}</td>
                      <td className="p-2 text-left">
                        {maskHelper.formatMoeda(item.total)}
                      </td>
                      <td className="p-2 text-left">
                        {maskHelper.formatDateDMY(item.created_at)}
                      </td>
                      <td
                        className="p-2 text-left cursor-pointer"
                        onClick={() => {
                          setDetails(true);
                          setDataDetails({
                            ...item,
                            user: JSON.parse(item.user_json),
                            pay: JSON.parse(item.pay_json),
                          });
                        }}
                        aria-hidden="true"
                      >
                        <BsEye color="#DC7E19" size={20} />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <>
                <table className="table-auto w-full">
                  <thead className="border-solid border-b-2 text-xs sm:text-sm bg-primary-light/25">
                    <tr>
                      <th className="p-1 pl-2 text-left rounded-tl-md">Item</th>
                      <th className="p-1 pl-2 text-center">Qtd.</th>
                      <th className="p-1 pl-2 text-center">Peso</th>
                      <th className="p-1 pl-2 text-center">Valor Total</th>
                      <th className="p-1 pl-2 text-center">Desconto</th>
                      <th className="p-1 pl-2 text-center rounded-tr-md">
                        Total Pago
                      </th>
                    </tr>
                  </thead>
                  <tbody className="font-light text-xs sm:text-sm">
                    {dataDetails.purshases_items.map((item: any) => {
                      const prod = JSON.parse(item.product_json);
                      const weight = JSON.parse(item.product_weight_range_json);

                      return (
                        <tr>
                          <td className="p-1 pl-2 text-left">
                            {prod.category_items.subtitle}{' '}
                            <strong>{prod.brand.title}</strong>
                          </td>
                          <td className="p-1 pl-2 text-center">{item.qty}</td>
                          <td className="p-1 pl-2 text-center">
                            {weight.weight_start} até {weight.weight_end}{' '}
                            {prod.category_items.unit
                              ? prod.category_items.unit
                              : 'Kg'}
                          </td>
                          <td className="p-1 pl-2 text-center">
                            {maskHelper.formatMoeda(item.subtotal)}
                          </td>
                          <td className="p-1 pl-2 text-center">
                            {maskHelper.formatMoeda(item.discount)}
                          </td>
                          <td className="p-1 pl-2 text-center">
                            {maskHelper.formatMoeda(item.total)}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <div className="flex flex-col w-full items-end mt-6 gap-1">
                  <span className="text-sm font-light">
                    Subtotal: {maskHelper.formatMoeda(dataDetails.subtotal)}
                  </span>
                  <span className="text-sm font-light text-green">
                    Desconto: {maskHelper.formatMoeda(dataDetails.discount)}
                  </span>
                  <span className="text-sm font-light">
                    Frete: {maskHelper.formatMoeda(dataDetails.delivery)}
                  </span>
                  <span className="text-md font-bold">
                    Total: {maskHelper.formatMoeda(Number(dataDetails.total))}
                  </span>
                </div>
              </>
            )}
          </div>
        </motion.section>

        <Footer />
        <Copyright />
      </div>
    </div>
  );
}
