import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { getItem, removeItem, setItem } from '../../helpers/storage.helper';
import { SessionServices } from '../../services';

export default function useSession() {
  const [menu, setMenu] = useState('');
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  function reCalculeDelivery(delivery: number) {
    const purshase = getItem('purshase', true);

    if (purshase) {
      const total = Number(purshase.total) - Number(purshase.delivery);

      setItem('purshase', {
        ...purshase,
        delivery,
        total: Number((total + delivery).toFixed(2)),
      });
    }
  }

  async function signIn(email: string, password: string) {
    try {
      setLoading(true);
      removeItem('user');

      const responseAuth: any = await new SessionServices().authentication(
        email,
        password
      );

      if (!responseAuth || responseAuth.status === 409) {
        return 'error_credential_invalid';
      }

      setIsAuthenticated(true);
      setItem('token', responseAuth.data.token, false);

      delete responseAuth.data.token;
      setUser(responseAuth.data);
      setItem('user', responseAuth.data, true);

      reCalculeDelivery(Number(responseAuth.data.delivery_value));

      return responseAuth.data;
    } catch (e: any) {
      console.log('error', e);
      if (e?.message === 'Validation failed') {
        toast.warn(`O ${e?.validation.body.message}`);
        return null;
      }

      return e;
    } finally {
      setLoading(false);
    }
  }

  function signOut() {
    setUser({});
    setIsAuthenticated(false);
    removeItem('token');
    removeItem('user');
    removeItem('pathname');
  }

  useEffect(() => {
    async function loadStorageData() {
      setLoading(true);

      const storagedUser = getItem('user', true);
      const storagedToken = getItem('token');

      if (storagedUser && storagedToken) {
        setUser(storagedUser);
        setIsAuthenticated(true);
      } else {
        signOut();
      }

      setLoading(false);
    }

    loadStorageData();
  }, []);

  return {
    user,
    menu,
    setMenu,
    loading,
    isAuthenticated,
    signIn,
    signOut,
  };
}
