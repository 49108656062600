import { convertDistance, getPreciseDistance } from 'geolib';
import { useCallback } from 'react';
import { toast } from 'react-toastify';
import { normalizeNFD } from '../helpers/utils.helper';
import { GoogleApiServices, SessionServices } from '../services';

export default function useGoogleApi() {
  const calculeDistanceDelivery = useCallback(
    async (addressDestination: string, convertKm = false): Promise<number> => {
      const responseCompany: any = await new SessionServices().getCompany();

      const responseDestination: any =
        await new GoogleApiServices().getInfoAddress(
          normalizeNFD(addressDestination)
        );

      const latDestination =
        responseDestination.data.results[0].geometry.location.lat;
      const lngDestination =
        responseDestination.data.results[0].geometry.location.lng;

      const distance = getPreciseDistance(
        {
          latitude: responseCompany.data.lat,
          longitude: responseCompany.data.lng,
        },
        {
          latitude: latDestination,
          longitude: lngDestination,
        }
      );

      if (distance > 35000) {
        toast.warn('Não entregamos nesta localidade');
        return 0;
      }

      return convertKm ? convertDistance(distance, 'km') : distance;
    },
    []
  );

  const calculeDelivery = useCallback((km: number): number => {
    if (km <= 225) {
      return 0;
    }

    if (km <= 7000) {
      return 9.99;
    }

    return 15;
  }, []);

  return { calculeDistanceDelivery, calculeDelivery };
}
