export const keysCategory = [
  {
    id: 'bull',
    title: 'bull',
  },
  {
    id: 'pig',
    title: 'pig',
  },
  {
    id: 'ticken',
    title: 'ticken',
  },
  {
    id: 'sausage',
    title: 'sausage',
  },
  {
    id: 'vinho',
    title: 'vinho',
  },
  {
    id: 'massa',
    title: 'massa',
  },
  {
    id: 'tempero',
    title: 'tempero',
  },
];

export const statusPurshase = [
  {
    id: 'ALL',
    title: 'Todos status pedidos',
  },
  {
    id: 'Ag. pagamento',
    title: 'Ag. pagamento',
  },
  {
    id: 'Ag. entrega',
    title: 'Ag. entrega',
  },
  {
    id: 'Cancelado',
    title: 'Cancelado',
  },
  {
    id: 'Entregue',
    title: 'Entregue',
  },
];

export const statusPayment = [
  {
    id: 'ALL',
    title: 'Todos status pagamentos',
  },
  {
    id: 'PENDING',
    title: 'Pendente',
  },
  {
    id: 'CONFIRMED',
    title: 'Confirmado',
  },
  {
    id: 'DELETED',
    title: 'Excluído',
  },
  {
    id: 'OVERDUE',
    title: 'Vencido',
  },
  {
    id: 'REFUNDED',
    title: 'Estornado',
  },
];

export const statusSubscription = [
  {
    id: 'ALL',
    title: 'Todos status',
  },
  {
    id: 'ACTIVE',
    title: 'Ativas',
  },
  {
    id: 'INACTIVE',
    title: 'Inativas ou Expiradas',
  },
  {
    id: 'CANCEL',
    title: 'Cancelada',
  },
];

export const units = [
  {
    id: 'Kg',
    title: 'Kg',
  },
  {
    id: 'Un',
    title: 'Un',
  },
];

export const typesNotifications = [
  {
    id: 'subscribers_active',
    title: 'Usuarios com assinatura ATIVA',
  },
  {
    id: 'subscribers_inactive',
    title: 'Usuarios com assinatura INATIVA',
  },
  {
    id: 'users_not_subscribers',
    title: 'Usuarios que NÃO assinaram',
  },
  {
    id: 'all',
    title: 'TODOS',
  },
];
