import classNames from 'classnames';
import React, { useContext, useEffect, useState } from 'react';
import { MdOutlineConfirmationNumber } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import PurshaseContext from '../contexts/purshase.context';
import SessionContext from '../contexts/session.context';
import maskHelper from '../helpers/mask.helper';
import { CupomServices } from '../services';

type ShoppingSummaryProps = {
  /* eslint-disable no-unused-vars */
  setIsShoppingSummary: (e: boolean) => void;
};

const ShoppingSummary: React.FC<ShoppingSummaryProps> = ({
  setIsShoppingSummary,
}) => {
  const [cupom, setCupom] = useState('');
  const [loading, setLoading] = useState(false);
  const { isAuthenticated } = useContext(SessionContext);
  const {
    addQtdPurshase,
    delQtdPurshase,
    purshase,
    getPurshase,
    addCupomPurshase,
  } = useContext(PurshaseContext);

  useEffect(() => {
    getPurshase();
  }, []);

  async function isValidCupom() {
    try {
      if (cupom.trim() !== '') {
        if (!purshase?.cupom_value) {
          setLoading(true);
          const response: any = await new CupomServices().isValid(cupom.trim());

          if (response.data === 'error_cupom_invalid') {
            toast.warn('Cupom inválido');
            return;
          }

          addCupomPurshase(response.data);
          setCupom('');
        } else {
          toast.warn('Você já adicionou cupom neste pedido');
        }
      }
    } catch (e: any) {
      console.log('error', e);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div
      onMouseLeave={() => setIsShoppingSummary(false)}
      className="w-[450px] p-8 pt-5 bg-white absolute top-[73px] right-2 text-black shadow-lg rounded-md"
    >
      {purshase && (
        <>
          <div className="bg-white w-3 h-3 rotate-45 absolute top-[-6px] right-[177px] rounded-sm" />
          <span className="font-bold text-lg mb-2">Resumo do Carrinho</span>
          {purshase?.items.map((item: any) => (
            <div
              className="flex items-center gap-1 w-full mt-1"
              key={Math.random()}
            >
              <img
                alt="Carletto"
                src={`${process.env.REACT_APP_API}/${item.product_json.path}`}
                loading="lazy"
                className="w-[100px] object-fill rounded-lg bg-background"
              />
              <div className="flex flex-col w-full">
                <span className="font-bold">
                  {item.product_json.category_items.subtitle}
                </span>
                <span className="font-light text-sm">
                  {item.product_weight_range_json.weight_start} a{' '}
                  {item.product_weight_range_json.weight_end}{' '}
                  {item.product_json.category_items.unit}
                </span>
                <div className="flex flex-row items-center justify-between mt-1">
                  <div className="flex gap-1">
                    <p className="text-[#868686] text-sm line-through">
                      {maskHelper.formatMoeda(item.subtotal)}
                    </p>
                    <p className="font-bold text-primary text-md">
                      {maskHelper.formatMoeda(item.total)}
                    </p>
                  </div>
                  <div className="flex flex-row items-center ">
                    <button
                      type="button"
                      onClick={() =>
                        delQtdPurshase(
                          item.product_json.id,
                          item.product_weight_range_json.id
                        )
                      }
                      disabled={item.qty === 1}
                      className={classNames(
                        'pl-3 pr-3 bg-primary text-center text-lg rounded-md cursor-pointer',
                        {
                          'opacity-50 cursor-not-allowed': item.qty === 1,
                        }
                      )}
                    >
                      -
                    </button>
                    <span className="ml-2 mr-2">{item.qty}</span>
                    <button
                      type="button"
                      disabled={item.product_weight_range_json.qty === item.qty}
                      onClick={() =>
                        addQtdPurshase(
                          item.product_json.id,
                          item.product_weight_range_json.id
                        )
                      }
                      className={classNames(
                        'pl-3 pr-3 bg-primary text-center text-lg rounded-md cursor-pointer',
                        {
                          'opacity-50 cursor-not-allowed':
                            item.product_weight_range_json.qty === item.qty,
                        }
                      )}
                    >
                      +
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}

          <div className="flex justify-betwee mt-6 gap-2">
            <div className="relative w-[75%]">
              <input
                disabled={loading}
                onBlur={(e: any) => setCupom(e.target.value)}
                className={classNames(
                  'border-solid border-[0.5px] border-primary placeholder:text-primary p-3 pl-7 rounded-md text-xs w-[100%]',
                  {
                    'border-gray-400 bg-[#ddd] text-gray-400': loading,
                  }
                )}
                placeholder="Cupom de Desconto"
                defaultValue={cupom}
              />
              <MdOutlineConfirmationNumber
                size={15}
                color={loading ? '#aaa' : '#DC7E19'}
                className="absolute top-[13.5px] left-2"
              />
            </div>
            <button
              disabled={loading}
              type="button"
              onClick={(e: any) => isValidCupom()}
              className={classNames(
                'border-solid border-[0.5px] border-primary text-primary p-3 pl-4 pr-4 rounded-md text-xs w-[23%]',
                {
                  'border-gray-400 text-gray-400': loading,
                }
              )}
            >
              {loading ? 'Aguarde...' : 'Aplicar'}
            </button>
          </div>
          <div className="flex flex-col font-light text-sm mt-6">
            <div className="flex justify-between mb-1">
              <span>Subtotal</span>
              <span className="font-bold">
                {maskHelper.formatMoeda(purshase.subtotal)}
              </span>
            </div>
            <div className="flex justify-between mb-1">
              <span>Taxa de Entrega</span>
              <span className="font-bold">
                {purshase.delivery > 0
                  ? maskHelper.formatMoeda(purshase.delivery)
                  : isAuthenticated
                  ? maskHelper.formatMoeda(0)
                  : 'à calcular'}
              </span>
            </div>
            <div className="flex justify-between mb-1">
              <span>Desconto</span>
              <span className="font-bold text-primary">
                {maskHelper.formatMoeda(purshase.discount)}
              </span>
            </div>
            <div className="flex justify-between mt-4">
              <span>Total</span>
              <span className="font-bold text-lg">
                {maskHelper.formatMoeda(purshase.total)}
              </span>
            </div>
            <div className="flex flex-col gap-4 mt-7">
              <Link
                to="/shopping-summary"
                className="rounded-md bg-primary p-3 pl-6 pr-6 font-bold text-md text-black text-center"
              >
                Finalizar Compra
              </Link>
              <Link
                to="/products"
                onClick={() => setIsShoppingSummary(false)}
                className="rounded-md border-primary border-solid border-[1px] p-3 pl-6 pr-6 font-bold text-md text-primary text-center"
              >
                Continuar Comprando
              </Link>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ShoppingSummary;
