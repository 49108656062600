import { Route, Routes, useLocation } from 'react-router-dom';

import { AnimatePresence } from 'framer-motion';
import {
  HomePage,
  ProductsPage,
  ShoppingSummaryPage,
  LoginPage,
  RegisterPage,
  PixPage,
  AccountPage,
  IndicatedPage,
  PaymentsPage,
  RequestsPage,
  WarningStockPage,
  RecoveryPassPage,
  RecoveryPassCodePage,
  RecoveryPassChangePage,
  Dashboard,
  Brand,
  Category,
  Product,
  Purshase,
  SubCategory,
  Subscription,
  User,
  Notification,
  Cupom,
} from '../modules/pages';

function AppRoutes() {
  const location = useLocation();

  return (
    <AnimatePresence>
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<HomePage />} />
        <Route path="/:identify" element={<HomePage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/recovery-pass" element={<RecoveryPassPage />} />
        <Route
          path="/recovery-pass-code/:idenfify"
          element={<RecoveryPassCodePage />}
        />
        <Route
          path="/recovery-pass-change/:idenfify/:code"
          element={<RecoveryPassChangePage />}
        />
        <Route path="/home" element={<HomePage />} />
        <Route path="/home/:identify" element={<HomePage />} />
        <Route path="/welcome" element={<HomePage />} />
        <Route path="/welcome/:identify" element={<HomePage />} />
        <Route path="/invite" element={<HomePage />} />
        <Route path="/invite/:identify" element={<HomePage />} />
        <Route path="/register/" element={<RegisterPage />} />
        <Route path="/register/:identify" element={<RegisterPage />} />
        <Route path="/products" element={<ProductsPage />} />
        <Route path="/products/:productId" element={<ProductsPage />} />
        <Route path="/shopping-summary" element={<ShoppingSummaryPage />} />
        <Route path="/pix" element={<PixPage />} />
        <Route path="/warning-stock" element={<WarningStockPage />} />
        <Route path="/account" element={<AccountPage />} />
        <Route path="/indicated" element={<IndicatedPage />} />
        <Route path="/payments" element={<PaymentsPage />} />
        <Route path="/requests" element={<RequestsPage />} />

        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/cupom" element={<Cupom />} />
        <Route path="/brand" element={<Brand />} />
        <Route path="/category" element={<Category />} />
        <Route path="/subcategory" element={<SubCategory />} />
        <Route path="/product" element={<Product />} />
        <Route path="/subscription" element={<Subscription />} />
        <Route path="/purshase" element={<Purshase />} />
        <Route path="/user" element={<User />} />
        <Route path="/notification" element={<Notification />} />
      </Routes>
    </AnimatePresence>
  );
}

export default AppRoutes;
