import HttpClient from '../helpers/httpClient.helper';
import { AuthorizationToken } from './common/authorization.token';

class PurshaseServices extends AuthorizationToken {
  private api;

  constructor() {
    super();
    this.api = new HttpClient(String(process.env.REACT_APP_API), this.token);
  }

  async getByFilters(
    userId = '',
    status = '',
    paymentStatus = ''
  ): Promise<void> {
    return await this.api.get(
      `purshase/filter?user_id=${userId}&status=${status}&payment_status=${paymentStatus}`
    );
  }

  async getByUser(): Promise<void> {
    return await this.api.get('purshase/user');
  }

  async sendCreditCard(data: any): Promise<void> {
    return await this.api.post('purshase/credit-card', data);
  }

  async sendPix(data: any): Promise<void> {
    return await this.api.post('purshase/pix', data);
  }

  async changeStatus(
    id: number,
    status: string,
    cancel: boolean
  ): Promise<void> {
    return await this.api.put(`purshase/status/${id}`, { status, cancel });
  }

  async getStatusByIdAsaas(paymentId: string): Promise<void> {
    return await this.api.get(`purshase/confirm/${paymentId}`);
  }
}

export default PurshaseServices;
