import React from 'react';

const Copyright: React.FC = () => (
  <section className="bg-primary p-1 flex items-center justify-center">
    <p className="text-black text-xs">
      Copyright 2022 © All rights reserved by Carletto Meat Club. | Made by Laks
    </p>
  </section>
);

export default Copyright;
