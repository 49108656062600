import { BsChevronLeft } from 'react-icons/bs';

import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import ReactLoading from 'react-loading';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { HiOutlineLockClosed } from 'react-icons/hi';
import { motion } from 'framer-motion';
import Footer from '../../../components/Footer';
import Copyright from '../../../components/Copyright';
import Header from '../../../components/Header';
import lock from '../../../assets/images/lock.png';
import yup from '../../../config/yup';
import { SessionServices } from '../../../services';
import AlertContext from '../../../contexts/alert.context';

interface IForm {
  password: string;
  confirmPassword: string;
}

export default function RecoveryChangePassPage() {
  const params: any = useParams();
  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState(0);
  const { alert, setAlert } = useContext(AlertContext);
  const navigate = useNavigate();
  let render = false;

  const schema = yup
    .object({
      password: yup
        .string()
        .required()
        .min(4, 'Mínimo 4 caracteres')
        .oneOf([yup.ref('confirmPassword'), null], 'As senhas não são iguais!'),
      confirmPassword: yup
        .string()
        .required()
        .min(4, 'Mínimo 4 caracteres')
        .oneOf([yup.ref('password'), null], 'As senhas não são iguais!'),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IForm>({
    resolver: yupResolver(schema),
  });

  async function onSubmit(data: IForm): Promise<void> {
    try {
      setLoading(true);

      const response: any = await new SessionServices().changePass(
        userId,
        data.password
      );

      if (!response?.data?.id) {
        toast.warn(`Código inválido, confira o e-mail.`);
        return;
      }

      setAlert({
        title: 'Senha alterada com sucesso!',
        description: 'Estamos redirecionando para o login :)',
        isOpen: true,
        button: [
          {
            title: 'Ok, entendi!',
            type: 'green',
            onClick: () => {
              setAlert({ ...alert, isOpen: false });
              navigate('/login');
            },
          },
        ],
      });
    } catch (e: any) {
      console.log('error', e);
      if (e?.message === 'Validation failed') {
        toast.warn(`O ${e?.validation.body.message}`);
        return;
      }

      if (e?.errors) {
        toast.warn(e?.errors[0]?.description);
        return;
      }
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (!render) {
      (async () => {
        render = true;

        const responseIdentify: any = await new SessionServices().validIdentify(
          params.idenfify
        );

        if (!responseIdentify?.data?.id) {
          toast.warn(`Link inválido. Informe o e-mail novamente!`);
          navigate(`/recovery-pass`);
          return;
        }

        const responseCode: any = await new SessionServices().verifyCodeByUser(
          responseIdentify?.data?.id,
          params.code
        );

        if (!responseCode?.data?.id) {
          toast.warn(`Link inválido. Informe o e-mail novamente!`);
          navigate(`/recovery-pass`);
          return;
        }

        setUserId(responseIdentify?.data?.id);
      })();
    }
  }, []);

  return (
    <div className="z-0 h-screen relative flex justify-center">
      <div className="bg-white relative w-full h-screen">
        <Header
          isDivideMarginTop
          title="Nossos produtos"
          description="As melhores peças para você."
          isApresentation={false}
        />

        <motion.section
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="flex justify-center items-center px-0 lg:px-56 bg-background h-fit p-0 sm:p-20"
        >
          <div className="flex flex-col items-center w-[100%] sm:w-[400px] bg-white rounded-md shadow-lg p-7 h-fit">
            <div className="flex justify-between items-center w-full">
              <Link to="/shopping-summary" className="flex items-center gap-1">
                <BsChevronLeft size={15} />
                <span className="font-bold text-xs">Voltar</span>
              </Link>

              <p className="font-bold text-xs">Recuperar Senha</p>
            </div>

            <img
              alt="Carletto"
              src={lock}
              loading="lazy"
              width="50"
              className="my-6"
            />

            <div>
              <p className="font-bold text-lg text-left">
                Cadastrar nova senha
              </p>
              <p className="font-light text-xs text-left">
                Sua nova senha deve conter no mínimo 8 caracteres e ao menos uma
                letra maíuscula.
              </p>
            </div>

            <form
              id="form-change"
              onSubmit={handleSubmit(onSubmit)}
              autoComplete="off"
              className="flex flex-col w-full gap-5 mt-6"
            >
              <div className="relative">
                <input
                  type="password"
                  className={classNames(
                    'border-[1px] border-solid border-black-regular placeholder:text-black-regular p-[0.65rem] pl-7 rounded-md text-xs w-[100%] focus:border-primary',
                    {
                      'border-red': !!errors.password,
                    }
                  )}
                  placeholder="Insira nova senha"
                  {...register('password')}
                />
                <HiOutlineLockClosed
                  size={15}
                  color={errors.password ? '#df4759' : '#B0B0B0'}
                  className="absolute top-[12px] left-2"
                />
                <span
                  className={classNames(
                    'absolute text-red text-[0.60rem] left-1 bottom-[-15px]',
                    {
                      hidden: !!errors.password === false,
                    }
                  )}
                >
                  {errors.password?.message}
                </span>
              </div>
              <div className="relative">
                <input
                  type="password"
                  className={classNames(
                    'border-[1px] border-solid border-black-regular placeholder:text-black-regular p-[0.65rem] pl-7 rounded-md text-xs w-[100%] focus:border-primary',
                    {
                      'border-red': !!errors.confirmPassword,
                    }
                  )}
                  placeholder="Confirmar nova senha"
                  {...register('confirmPassword')}
                />
                <HiOutlineLockClosed
                  size={15}
                  color={errors.confirmPassword ? '#df4759' : '#B0B0B0'}
                  className="absolute top-[12px] left-2"
                />
                <span
                  className={classNames(
                    'absolute text-red text-[0.60rem] left-1 bottom-[-15px]',
                    {
                      hidden: !!errors.confirmPassword === false,
                    }
                  )}
                >
                  {errors.confirmPassword?.message}
                </span>
              </div>
              <button
                type="submit"
                form="form-change"
                className={classNames(
                  'mt-[-0.1rem] bg-primary p-[0.65rem] pl-4 pr-4 rounded-md text-xs focus:border-primary font-bold transition shadow-lg hover:bg-opacity-80 relative',
                  {
                    'bg-opacity-20 text-gray-400 cursor-not-allowed hover:bg-opacity-20':
                      loading,
                  }
                )}
              >
                <span>Redefinir Senha</span>
                <ReactLoading
                  type="spokes"
                  color="#DC7E19"
                  width={17}
                  className={classNames('absolute right-3 top-2', {
                    hidden: !loading,
                  })}
                />
              </button>
            </form>
          </div>
        </motion.section>

        <Footer />
        <Copyright />
      </div>
    </div>
  );
}
