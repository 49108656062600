import React from 'react';
import { BsPatchCheck } from 'react-icons/bs';

import imageClub from '../../../assets/images/club.png';

const Club: React.FC = () => (
  <section className="flex items-center gap-12 p-10 flex-col lg:flex-row lg:p-20 lg:pl-32 lg:pr-32 bg-background">
    <img
      alt="Carletto"
      src={imageClub}
      loading="lazy"
      className="cursor-pointer hidden lg:flex"
      width="400"
    />
    <div>
      <p className="font-bold text-3xl mb-5 text-center lg:text-left">
        Clube Carletto
      </p>

      <p className="font-light text-md text-center lg:text-left mb-5">
        Chega de ficar horas a procura da carne certa, deixe que a gente cuida
        de tudo para você. Com o Carletto Meat Club, as carnes são selecionadas
        e entregues em suas mãos, de forma prática e segura.
      </p>
      <p className="font-light mb-5 text-center lg:text-left">
        <span className="font-bold">Não fique sem churrasco</span>, carnes
        separadas por kilo, dia e horário.
      </p>
      <div className="flex items-center flex-col lg:flex-row gap-2 mb-3">
        <BsPatchCheck color="#DC7E19" className="w-10 h-10 lg:w-5 lg:h-5" />
        <p className="font-bold">As melhores dicas para preparar sua carne.</p>
      </div>
      <div className="flex items-center flex-col lg:flex-row gap-2 mb-3">
        <BsPatchCheck color="#DC7E19" className="w-10 h-10 lg:w-5 lg:h-5" />
        <p className="font-bold">Cancelamento grátis.</p>
      </div>

      <img
        alt="Carletto"
        src={imageClub}
        loading="lazy"
        className="cursor-pointer mt-5 lg:hidden"
        width="400"
      />
    </div>
  </section>
);

export default Club;
