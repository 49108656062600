import { useContext, useEffect, useState } from 'react';
import ReactLoading from 'react-loading';
import { AiOutlinePlus } from 'react-icons/ai';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { FaEdit } from 'react-icons/fa';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import { TbTicket } from 'react-icons/tb';
import { CupomServices } from '../../../services';
import Layout from '../components/Layout';
import Modal from '../../../components/Modal';
import Switch from '../../../components/Switch';
import AlertContext from '../../../contexts/alert.context';
import LoadingContext from '../../../contexts/loading.context';
import Mask from '../../../helpers/mask.helper';
import SessionContext from '../../../contexts/session.context';

export default function Cupom() {
  const { alert, setAlert } = useContext(AlertContext);
  const { user } = useContext(SessionContext);
  const { loading, setLoading } = useContext(LoadingContext);
  const [loadingSave, setLoadingSave] = useState(false);
  const [isOpenForm, setIsOpenForm] = useState(false);
  const [data, setData] = useState([]);
  const [item, setItem] = useState<any>({
    id: '',
    user_id: '',
    cupom: '',
    qty: '',
    value: '',
    limited: '',
    is_active: false,
  });

  const [errorForm, setErrorForm] = useState<any>(null);

  async function handleGetAll(): Promise<void> {
    setLoading({
      isOpen: true,
      title: 'Carregando...',
    });
    const response: any = await new CupomServices().getAll();
    setData(response.data);
    setLoading({ ...loading, isOpen: false });
  }

  async function handleRemove(id: number): Promise<void> {
    await toast.promise(
      async () => {
        const response: any = await new CupomServices().delete(id);

        if (
          response.data === 'error_cupom_not_exist' ||
          response.data === 'error_exist_purshase'
        ) {
          throw new Error('error_cupom_not_exist');
        }

        await handleGetAll();
      },
      {
        pending: 'Processando',
        success: `Cupom removido com sucesso!`,
        error: 'Este cupom foi utilizado em algum pedido!',
      }
    );
  }

  function transformValueInput(value: any) {
    const val = value.replace(',', '');
    const fraction = val.substr(-2);

    return `${val.substring(0, val.length - 2)}.${fraction}`.replace('..', '.');
  }

  async function onSubmit(): Promise<void> {
    try {
      setErrorForm(null);

      let errors = null;
      if (!!item.cupom === false) {
        errors = { cupom: true };
      }

      if (!!item.value === false) {
        errors = { ...errors, value: true };
      }

      if (errors) {
        setErrorForm(errors);
        toast.warn('Campo(s) obrigatório(s)!');
        return;
      }

      setLoadingSave(true);

      await toast.promise(
        async () => {
          if (item.id) {
            await new CupomServices().update(item.id, {
              cupom: item.cupom,
              qty: item.qty ? Mask.number(String(item.qty)) : null,
              value: transformValueInput(item.value),
              limited: item.limited ? Mask.formatDateYMD(item.limited) : null,
              is_active: item.is_active,
            });
          } else {
            await new CupomServices().create({
              user_id: user.id,
              cupom: item.cupom,
              qty: item.qty ? Mask.number(String(item.qty)) : null,
              value: transformValueInput(item.value),
              limited: item.limited ? Mask.formatDateYMD(item.limited) : null,
              is_active: item.is_active,
            });
          }
        },
        {
          pending: 'Processando',
          success: `Cupom ${item.id ? 'atualizado' : 'criado'} com sucesso!`,
          error: 'Tente novamente mais tarde :(',
        }
      );

      setLoadingSave(false);
      setIsOpenForm(false);
      await handleGetAll();
    } catch (e: any) {
      console.log(e);
      setLoadingSave(false);
      setIsOpenForm(false);
    }
  }

  useEffect(() => {
    handleGetAll();
  }, []);

  useEffect(() => {
    if (!isOpenForm) {
      setItem({
        id: '',
        user_id: '',
        cupom: '',
        qty: '',
        value: '',
        limited: '',
        is_active: false,
      });
    }
  }, [isOpenForm]);

  return (
    <Layout
      legend={{
        value: data.length,
        text: 'Cupom de desconto',
      }}
    >
      {isOpenForm && (
        <Modal
          title={`${item.id ? 'Atualizar' : 'Novo'} cupom`}
          setIsOpen={setIsOpenForm}
          isOpen={isOpenForm}
        >
          <form id="form" autoComplete="off">
            <div className="flex flex-col mt-4 gap-1 relative">
              <span className="font-light text-xs">Nome do Cupom</span>
              <input
                className="border-solid border-[0.5px] border-[#aaa] p-3 rounded-md text-xs w-full focus:border-primary focus:border"
                placeholder="Ex: #natal2022"
                onChange={(e) => setItem({ ...item, cupom: e.target.value })}
                value={item.cupom}
              />
              <span
                className={classNames(
                  'absolute text-red text-[0.60rem] left-1 bottom-[-15px]',
                  {
                    hidden: !errorForm?.cupom,
                  }
                )}
              >
                {errorForm?.cupom ? 'Obrigatório' : ''}
              </span>
            </div>
            <div className="flex flex-col mt-4 gap-1 relative">
              <span className="font-light text-xs">Valor do desconto</span>
              <input
                className="border-solid border-[0.5px] border-[#aaa] p-3 rounded-md text-xs w-full focus:border-primary focus:border"
                placeholder="Ex: 100"
                onChange={(e) => setItem({ ...item, value: e.target.value })}
                value={Mask.formatCurrencyInput(item.value)}
                maxLength={6}
              />
              <span
                className={classNames(
                  'absolute text-red text-[0.60rem] left-1 bottom-[-15px]',
                  {
                    hidden: !errorForm?.value,
                  }
                )}
              >
                {errorForm?.value ? 'Obrigatório' : ''}
              </span>
            </div>
            <div className="flex flex-col mt-4 gap-1 relative">
              <span className="font-light text-xs">
                Existe quantidade máxima?{' '}
                <strong>(caso fique em branco, a qtde é infinita)</strong>
              </span>
              <input
                type="number"
                className="border-solid border-[0.5px] border-[#aaa] p-3 rounded-md text-xs w-full focus:border-primary focus:border"
                placeholder="Ex: 100"
                onChange={(e) => setItem({ ...item, qty: e.target.value })}
                value={item.qty}
              />
            </div>
            <div className="flex flex-col mt-4 gap-1 relative">
              <span className="font-light text-xs">
                Possui data limite ?{' '}
                <strong>(caso fique em branco, não existe data limite)</strong>
              </span>
              <input
                type="date"
                className="border-solid border-[0.5px] border-[#aaa] p-3 rounded-md text-xs w-full focus:border-primary focus:border"
                placeholder="Ex: dd/mm/yyyy"
                onChange={(e) => setItem({ ...item, limited: e.target.value })}
                value={item.limited}
              />
              <span
                className={classNames(
                  'absolute text-red text-[0.60rem] left-1 bottom-[-15px]',
                  {
                    hidden: !errorForm?.limited,
                  }
                )}
              >
                {errorForm?.limited ? 'Obrigatório' : ''}
              </span>
            </div>

            <div className="mt-6 flex items-center gap-2">
              <Switch
                state={item.is_active}
                onChange={() =>
                  setItem({ ...item, is_active: !item.is_active })
                }
                handleDiameter={22}
                width={47}
                height={26}
              />
              <span className="font-light text-sm">
                {item.is_active ? 'Ativo' : 'Descontinuado'}
              </span>
            </div>
            <div className="flex gap-3 mt-7 justify-end">
              <button
                type="button"
                form="form"
                disabled={loadingSave}
                onClick={onSubmit}
                className={classNames(
                  'relative rounded-md bg-primary p-2 pl-6 pr-6 font-Inter font-bold text-xs w-36',
                  {
                    'bg-opacity-20 text-gray-400 cursor-not-allowed hover:bg-opacity-20':
                      loadingSave,
                  }
                )}
              >
                <span>Salvar</span>
                <ReactLoading
                  type="spokes"
                  color="#DC7E19"
                  width={15}
                  className={classNames('absolute right-3 top-2', {
                    hidden: !loadingSave,
                  })}
                />
              </button>
            </div>
          </form>
        </Modal>
      )}

      <div className="w-full bg-white rounded-lg shadow-xs sm:min-h-[500px] 2xl:min-h-[700px] h-fit">
        <div className="flex flex-row justify-between items-center mb-8 relative">
          <div className="flex flex-row items-center gap-2">
            <TbTicket size={22} color="#DC7E19" />
            <span className="font-bold text-lg">Cupom de desconto</span>
          </div>

          <button
            type="button"
            onClick={() => {
              setIsOpenForm(true);
            }}
            className="flex items-center gap-1 border-primary text-primary border-solid border-[1px] px-3 py-[0.4rem] rounded-md text-xs transition hover:text-black-light hover:bg-primary"
          >
            <AiOutlinePlus />
            <span>adicionar</span>
          </button>
        </div>
        <table className="table-auto w-full">
          <thead className="border-solid border-b-2 text-xs sm:text-sm bg-primary-light/25">
            <tr>
              <th className="p-1 text-left rounded-tl-sm">Criado por</th>
              <th className="p-1 text-left rounded-tl-sm">Cupom</th>
              <th className="p-1 text-center rounded-tl-sm">Qtde máxima</th>
              <th className="p-1 text-center rounded-tl-sm">Qtde utilizada</th>
              <th className="p-1 text-center rounded-tl-sm">Valor</th>
              <th className="p-1 text-center rounded-tl-sm">Data limite</th>
              <th className="p-1 text-center rounded-tl-sm">Status</th>
              <th className="p-1 text-center">&nbsp;</th>
              <th className="p-1 text-center rounded-tr-sm">&nbsp;</th>
            </tr>
          </thead>
          <tbody className="font-light text-xs sm:text-sm ">
            {data.map((cat: any) => (
              <tr
                key={cat.id}
                className={classNames('', {
                  'text-black-regular': !cat.is_active,
                })}
              >
                <td className="p-1 text-left">{cat.user.name}</td>
                <td className="p-1 text-left">{cat.cupom}</td>
                <td className="p-1 text-center">{cat.qty}</td>
                <td className="p-1 text-center">
                  {cat.purshases_cupom_discount.length}
                </td>
                <td className="p-1 text-center">
                  {Mask.formatMoeda(cat.value)}
                </td>
                <td className="p-1 text-center">
                  {Mask.formatDateDMY(cat.limited)}
                </td>
                <td className="p-1 text-center">
                  {cat.is_active ? 'Ativo' : 'Inativo'}
                </td>
                <td className="p-1 text-left">
                  <FaEdit
                    className="cursor-pointer"
                    color="#DC7E19"
                    size={16}
                    onClick={() => {
                      setItem({
                        ...cat,
                        limited: cat.limited.substring(0, 10),
                        value: cat.value.substr(-2).includes('.')
                          ? `${cat.value}0`
                          : cat.value.includes('.')
                          ? cat.value
                          : String(Number(cat.value) * 100),
                      });
                      setIsOpenForm(true);
                    }}
                  />
                </td>
                <td className="p-1 text-left">
                  <RiDeleteBin6Line
                    className="cursor-pointer"
                    color="#B0B0B0"
                    size={16}
                    onClick={() => {
                      if (cat.purshases_cupom_discount.length > 0) {
                        setAlert({
                          title: 'Atenção!',
                          description:
                            'Este cupom não pode ser excluído porque ja foi utilizado!',
                          isOpen: true,
                          button: [
                            {
                              title: 'Ok',
                              type: 'red',
                              onClick: () =>
                                setAlert({ ...alert, isOpen: false }),
                            },
                          ],
                        });
                      } else {
                        setAlert({
                          title: 'Atenção!',
                          description: 'Confirma a exclusão deste cupom?',
                          isOpen: true,
                          button: [
                            {
                              title: 'Não',
                              type: 'black-regular',
                              onClick: () =>
                                setAlert({ ...alert, isOpen: false }),
                            },
                            {
                              title: 'Sim, confirmar',
                              type: 'red',
                              onClick: () => {
                                handleRemove(cat.id);
                                setAlert({ ...alert, isOpen: false });
                              },
                            },
                          ],
                        });
                      }
                    }}
                  />
                </td>
              </tr>
            ))}
            {!loading.isOpen && data.length === 0 && (
              <tr>
                <td className="p-1 text-left">Nenhum cupom cadastrado.</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </Layout>
  );
}
