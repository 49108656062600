import { useContext, useEffect, useState } from 'react';
import { MdOutlineRequestQuote } from 'react-icons/md';
import { BsEye, BsFillEyeFill } from 'react-icons/bs';
import { TiArrowBack } from 'react-icons/ti';
import { TbTruckDelivery } from 'react-icons/tb';
import { toast } from 'react-toastify';
import { ImCancelCircle } from 'react-icons/im';
import classNames from 'classnames';
import { PurshaseServices, UserServices } from '../../../services';
import Layout from '../components/Layout';
import Modal from '../../../components/Modal';
import maskHelper from '../../../helpers/mask.helper';
import {
  translateStatusPay,
  translateTypePay,
} from '../../../helpers/utils.helper';
import AlertContext from '../../../contexts/alert.context';
import SelectCustom from '../../../components/Select';
import { statusPayment, statusPurshase } from '../mocks';
import LoadingContext from '../../../contexts/loading.context';

export default function Purshase() {
  const { alert, setAlert } = useContext(AlertContext);
  const { loading, setLoading } = useContext(LoadingContext);
  const [details, setDetails] = useState(false);
  const [dataDetails, setDataDetails] = useState<any>(null);
  const [clients, setClients] = useState<any[]>([]);
  const [filterClient, setFilterClient] = useState<any>(null);
  const [filterStatus, setFilterStatus] = useState<any>(null);
  const [filterStatusPurshase, setFilterStatusPurshase] = useState<any>(null);
  const [data, setData] = useState({
    items: [],
    total: 0,
  });

  async function handleByFilter(): Promise<void> {
    setLoading({
      isOpen: true,
      title: 'Carregando...',
    });
    const responsePurshase: any = await new PurshaseServices().getByFilters(
      filterClient?.id === 'ALL' ? '' : filterClient?.id,
      filterStatus?.id === 'ALL' ? '' : filterStatus?.id,
      filterStatusPurshase?.id === 'ALL' ? '' : filterStatusPurshase?.id
    );

    let total = 0;
    responsePurshase.data.forEach((item: any) => {
      total += JSON.parse(item.pay_json).value;
    });

    setData({ items: responsePurshase.data, total });
    setLoading({ ...loading, isOpen: false });
  }

  async function handleClients(): Promise<void> {
    const responseCli: any = await new UserServices().getAll();

    const clientsPopulate: any = [
      {
        id: 'ALL',
        title: 'Todos clientes',
      },
    ];
    responseCli.data.forEach((item: any) => {
      clientsPopulate.push({
        id: item.id,
        title: item.name,
      });
    });

    setClients(clientsPopulate);
  }

  async function handleChangeStatus(
    id: number,
    status: string,
    cancel = false
  ) {
    await toast.promise(
      async () => {
        await new PurshaseServices().changeStatus(id, status, cancel);
        await handleByFilter();
      },
      {
        pending: 'Processando',
        success: `Pedido ${status.toLowerCase()} com sucesso!`,
        error: 'Ops! Tente novamente mais tarde!',
      }
    );
  }

  useEffect(() => {
    handleClients();
  }, []);

  useEffect(() => {
    handleByFilter();
  }, [filterClient, filterStatus, filterStatusPurshase]);

  return (
    <Layout
      legend={{
        value: maskHelper.formatMoeda(data.total),
        text:
          !filterStatusPurshase || filterStatusPurshase?.id === 'ALL'
            ? `${data?.items.length} pedidos`
            : `${data?.items.length} ${filterStatusPurshase?.title}`,
      }}
    >
      {details && (
        <Modal
          title="Dados do pedido"
          header={false}
          setIsOpen={setDetails}
          isOpen={details}
          size="4xlarge"
        >
          <div className="flex flex-col">
            <div className="flex flex-row items-center justify-between">
              <div className="flex flex-row items-center gap-2">
                <BsFillEyeFill size={22} color="#DC7E19" />
                <span className="font-bold text-md sm:text-lg">
                  Detalhes do pedido #{dataDetails?.number}
                </span>
              </div>{' '}
              <button
                type="button"
                onClick={() => setDetails(false)}
                className="flex items-center justify-center gap-1 rounded-md p-2 pl-6 pr-6 font-Inter font-bold text-xs text-primary"
              >
                <TiArrowBack size={22} />
                <span>Voltar</span>
              </button>
            </div>
            <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between mt-6 mb-4">
              <div className="flex flex-col mb-6 sm:mb-0">
                <span className="font-bold mb-3 text-sm">Dados do Pedido</span>
                <div className="flex flex-col font-light text-xs gap-1">
                  <span>
                    Data da Compra:{' '}
                    {maskHelper.formatDateDMY(dataDetails?.created_at)}
                  </span>
                  <span>Status do Pedido: {dataDetails?.status}</span>
                  <span>
                    Método de Pagamento:{' '}
                    {translateTypePay(dataDetails?.pay.billingType)}
                  </span>
                </div>
              </div>
              <div className="flex flex-col mb-6 sm:mb-0">
                <span className="font-bold mb-3 text-sm">Dados do Cliente</span>
                <div className="flex flex-col font-light text-xs gap-1">
                  <span>Nome: {dataDetails?.user.name}</span>
                  <span>E-mail: {dataDetails?.user.email}</span>
                  <span>
                    Telefone: {maskHelper.phone(dataDetails?.user.phone)}
                  </span>
                </div>
              </div>
              <div className="flex flex-col mb-0">
                <span className="font-bold mb-3 text-sm">Dados da Entrega</span>
                <div className="flex flex-col font-light text-xs gap-1">
                  <span>
                    {dataDetails?.user.address}, {dataDetails?.user.number},{' '}
                    {dataDetails?.user.complement}
                  </span>
                  <span>
                    {dataDetails?.user.province} - {dataDetails?.user.city}/
                    {dataDetails?.user.state}
                  </span>
                  <span>
                    {maskHelper.cep(String(dataDetails?.user.postal_code))}
                  </span>
                </div>
              </div>
            </div>
            <table className="table-auto w-full mt-4">
              <thead className="border-solid border-b-2 text-xs sm:text-sm bg-primary-light/25">
                <tr>
                  <th className="p-1 pl-2 text-left rounded-tl-md">Item</th>
                  <th className="p-1 pl-2 text-center">Qtd.</th>
                  <th className="p-1 pl-2 text-center">Peso</th>
                  <th className="p-1 pl-2 text-center">Valor Total</th>
                  <th className="p-1 pl-2 text-center">Desconto</th>
                  <th className="p-1 pl-2 text-center rounded-tr-md">
                    Total Pago
                  </th>
                </tr>
              </thead>
              <tbody className="font-light text-xs sm:text-sm">
                {dataDetails.purshases_items.map((item: any) => {
                  const prod = JSON.parse(item.product_json);
                  const weight = JSON.parse(item.product_weight_range_json);

                  return (
                    <tr>
                      <td className="p-1 pl-2 text-left">
                        {prod.category_items.subtitle}{' '}
                        <strong>{prod.brand.title}</strong>
                      </td>
                      <td className="p-1 pl-2 text-center">{item.qty}</td>
                      <td className="p-1 pl-2 text-center">
                        {weight.weight_start} até {weight.weight_end}{' '}
                        {prod.category_items.unit
                          ? prod.category_items.unit
                          : 'Kg'}
                      </td>
                      <td className="p-1 pl-2 text-center">
                        {maskHelper.formatMoeda(item.subtotal)}
                      </td>
                      <td className="p-1 pl-2 text-center">
                        {maskHelper.formatMoeda(item.discount)}
                      </td>
                      <td className="p-1 pl-2 text-center">
                        {maskHelper.formatMoeda(item.total)}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div className="flex flex-col w-full items-end mt-6 gap-1">
              <span className="text-sm font-light">
                Subtotal: {maskHelper.formatMoeda(dataDetails.subtotal)}
              </span>
              <span className="text-sm font-normal text-green">
                Desconto: {maskHelper.formatMoeda(dataDetails.discount)}
              </span>
              <span className="text-sm font-light">
                Frete: {maskHelper.formatMoeda(dataDetails.delivery)}
              </span>
              <span className="text-md font-bold">
                Total: {maskHelper.formatMoeda(Number(dataDetails.total))}
              </span>
              {dataDetails.cupom_value > 0 && (
                <span className="text-sm font-light mt-5">
                  <strong className="font-bold">Aplicou Cupom:</strong>{' '}
                  {dataDetails.cupom_json?.cupom},{' '}
                  <span className="text-primary font-normal">
                    desconto de{' '}
                    {maskHelper.formatMoeda(dataDetails.cupom_value)}
                  </span>
                </span>
              )}
            </div>
          </div>
        </Modal>
      )}

      <div className="w-full bg-white rounded-lg shadow-xs sm:min-h-[500px] 2xl:min-h-[700px] h-fit">
        <div className="flex flex-row justify-between items-center mb-8 relative">
          <div className="flex flex-row items-center gap-2">
            <MdOutlineRequestQuote size={22} color="#DC7E19" />
            <span className="font-bold text-lg">Pedidos</span>
          </div>
          <div className="flex gap-2">
            <SelectCustom
              placeholder="Pesquise pelo nome"
              data={clients}
              defaultValue={
                !filterClient
                  ? { id: 0, title: 'Pesquise pelo nome' }
                  : filterClient
              }
              noOptionsMessage="Cliente não encontrado"
              onChange={setFilterClient}
              width="250px"
            />
            <SelectCustom
              placeholder="Status do pedido"
              data={statusPurshase}
              defaultValue={
                !filterStatus
                  ? { id: 0, title: 'Status do pedido' }
                  : filterStatus
              }
              noOptionsMessage="Status não encontrado"
              onChange={setFilterStatus}
              width="170px"
            />
            <SelectCustom
              placeholder="Status do pagamento"
              data={statusPayment}
              defaultValue={
                !filterStatusPurshase
                  ? { id: 0, title: 'Status do pagamento' }
                  : filterStatusPurshase
              }
              noOptionsMessage="Status não encontrado"
              onChange={setFilterStatusPurshase}
              width="200px"
            />
          </div>
        </div>
        <table className="text-left w-full">
          <thead className="flex w-full border-solid border-b-2 text-xs sm:text-sm bg-primary-light/25">
            <tr className="flex w-full">
              <th className="p-2 text-left w-1/6 rounded-tl-sm">Pedido</th>
              <th className="p-2 text-left w-1/6">Data</th>
              <th className="p-2 text-left w-[29%]">Cliente</th>
              <th className="p-2 text-left w-[22%]">Status</th>
              <th className="p-2 text-left w-1/6">Tipo</th>
              <th className="p-2 text-left w-1/6">Pagamento</th>
              <th className="p-2 text-left w-1/6">Total</th>
              <th className="p-2 text-left w-[5%] rounded-tr-sm">ver</th>
            </tr>
          </thead>
          <tbody
            className="font-light text-xs sm:text-sm flex flex-col  overflow-y-auto w-full"
            style={{ height: data.items.length > 10 ? '60vh' : '' }}
          >
            {data.items.map((item: any) => (
              <tr key={Math.random()} className="flex items-center w-full">
                <td className="p-2 text-left w-1/6">{item.number}</td>
                <td className="p-2 text-left w-1/6">
                  {maskHelper.formatDateDMY(item.created_at)}
                </td>
                <td className="p-2 text-left w-[29%]">
                  {JSON.parse(item.user_json).name}
                </td>
                <td className="p-2 text-left w-[22%] flex items-center gap-2">
                  {item.status === 'Cancelado' ? (
                    <ImCancelCircle
                      size={20}
                      color="#ddd"
                      className="cursor-not-allowed"
                    />
                  ) : (
                    <ImCancelCircle
                      size={20}
                      color="#aaa"
                      className="cursor-pointer"
                      onClick={() => {
                        setAlert({
                          title: 'Cancelamento!',
                          description:
                            'Ao cancelar um pedido, voltamos o estoque dos itens. Deseja realmente cancelar o pedido',
                          isOpen: true,
                          button: [
                            {
                              title: 'Não',
                              type: 'black-regular',
                              onClick: () =>
                                setAlert({ ...alert, isOpen: false }),
                            },
                            {
                              title: 'Sim, cancelar',
                              type: 'red',
                              onClick: () => {
                                handleChangeStatus(item.id, 'Cancelado', true);
                                setAlert({ ...alert, isOpen: false });
                              },
                            },
                          ],
                        });
                      }}
                    />
                  )}
                  <span>{item.status}</span>{' '}
                  {item.status === 'Ag. entrega' && (
                    <TbTruckDelivery
                      size={22}
                      color="#DC7E19"
                      className="cursor-pointer"
                      onClick={() => {
                        setAlert({
                          title: 'Entrega!',
                          description: 'O pedido realmente foi entregue?',
                          isOpen: true,
                          button: [
                            {
                              title: 'Não',
                              type: 'black-regular',
                              onClick: () =>
                                setAlert({ ...alert, isOpen: false }),
                            },
                            {
                              title: 'Sim, pedido entregue',
                              type: 'red',
                              onClick: () => {
                                handleChangeStatus(item.id, 'Entregue');
                                setAlert({ ...alert, isOpen: false });
                              },
                            },
                          ],
                        });
                      }}
                    />
                  )}
                </td>
                <td className="p-2 text-left w-1/6">
                  {translateTypePay(item.payment_method)}
                </td>
                <td
                  className={classNames('p-2 text-left w-1/6', {
                    'text-green font-medium':
                      item.payment_status === 'CONFIRMED' ||
                      item.payment_status === 'RECEIVED',
                  })}
                >
                  {translateStatusPay(item.payment_status)}
                </td>
                <td className="p-2 text-left w-1/6">
                  {maskHelper.formatMoeda(item.total)}
                </td>
                <td
                  className="p-2 text-left w-[5%] cursor-pointer"
                  onClick={() => {
                    setDetails(true);
                    setDataDetails({
                      ...item,
                      user: JSON.parse(item.user_json),
                      pay: JSON.parse(item.pay_json),
                    });
                  }}
                  aria-hidden="true"
                >
                  <BsEye color="#DC7E19" size={20} />
                </td>
              </tr>
            ))}
            {!loading.isOpen && data.items.length === 0 && (
              <tr>
                <td className="p-1 text-left">Nenhum pedido encontrado.</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </Layout>
  );
}
