import React from 'react';
import { AiOutlineMail } from 'react-icons/ai';
import { BsInstagram } from 'react-icons/bs';
import { ImFacebook } from 'react-icons/im';
import { MdKeyboardArrowRight } from 'react-icons/md';

type NewsletterProps = {
  isChangeBackgroundWhite?: boolean;
};

const Newsletter: React.FC<NewsletterProps> = ({ isChangeBackgroundWhite }) => (
  <section
    className={`flex flex-col lg:flex-row items-center justify-center gap-3 p-10 lg:p-24 lg:24 lg:pl-32 lg:pr-32 ${
      isChangeBackgroundWhite ? 'bg-background' : 'pt-10 lg:pt-24 bg-primary'
    }`}
  >
    <a
      href="https://www.facebook.com/Sô-Carletto-108769008616243"
      target="_blank"
      rel="noreferrer"
    >
      <ImFacebook color="#000" size={28} className="hidden lg:flex" />
    </a>
    <a
      href="https://www.instagram.com/socarletto/"
      target="_blank"
      rel="noreferrer"
    >
      <BsInstagram color="#000" size={28} className="hidden lg:flex" />
    </a>

    <AiOutlineMail color="#000" size={38} className="hidden lg:flex" />

    <div className="text-sm p-0 m-0 text-center lg:text-left">
      <p className="font-Inter font-bold text-xl lg:text-sm">
        CADASTRE-SE EM NOSSA NEWSLETTER
        <br />
        <span className="font-Inter font-light">
          e fique por dentro de tudo que esta por vir.
        </span>
      </p>
    </div>

    <div className="flex flex-row items-center gap-3 lg:hidden mt-2 mb-3">
      <ImFacebook color="#000" size={28} />
      <BsInstagram color="#000" size={28} />
      <AiOutlineMail color="#000" size={38} />
    </div>

    <div className="w-full lg:w-[62%] relative">
      <input
        type="text"
        placeholder="Seu melhor e-mail"
        className={`bg-white rounded-md p-4 lg:p-2 pl-3 pr-0 w-[100%] ml-4 placeholder: text-md ${
          isChangeBackgroundWhite
            ? 'border-[1px] border-solid border-black'
            : ''
        }`}
      />
      <MdKeyboardArrowRight
        className="absolute right-[-2%] top-4 lg:top-2.5"
        size={23}
        color="#aaa"
      />
    </div>
  </section>
);

export default Newsletter;
