import { Link } from 'react-router-dom';
import Menu from './Menu';
import logoEllipse from '../../../assets/images/logo_alternative.png';

export default function Drawer() {
  return (
    <div className="flex flex-col items-center w-56 h-screen min-h-fit border-solid border-1 border-r-2 border-primary pt-6">
      <Link to="/">
        <img alt="Carletto" src={logoEllipse} loading="lazy" width="170" />
      </Link>
      <Menu />
    </div>
  );
}
