import HttpClient from '../helpers/httpClient.helper';
import { AuthorizationToken } from './common/authorization.token';

class SubscriptionServices extends AuthorizationToken {
  private api;

  constructor() {
    super();
    this.api = new HttpClient(String(process.env.REACT_APP_API), this.token);
  }

  async getAll(): Promise<void> {
    return await this.api.get('subscription');
  }

  async getByFilters(userId = '', status = ''): Promise<void> {
    return await this.api.get(
      `subscription/filter?user_id=${userId}&status=${status}`
    );
  }

  async renovate(
    id: number,
    subscription: string,
    creditCard: any
  ): Promise<void> {
    return await this.api.put(`subscription/renovate/${id}`, {
      subscription,
      creditCard,
    });
  }
}

export default SubscriptionServices;
