import React from 'react';
import { AiFillStar } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import maskHelper from '../../../helpers/mask.helper';

type CardProductProps = {
  data: {
    id: number;
    path: any;
    category_items: {
      category_id: number;
      subtitle: string;
      unit: string;
    };
    stars: number;
    price_by_kg: number;
    discount: number;
    brand: {
      id: number;
      title: string;
      active: boolean;
    };
    product_weight_range: {
      weight_end: string;
    }[];
  };
};

const CardProduct: React.FC<CardProductProps> = ({ data }) => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col w-[96%] sm:w-auto   items-center justify-center bg-white rounded-md p-4 shadow-md">
      <img
        alt="Carletto"
        src={`${process.env.REACT_APP_API}/${data.path}`}
        loading="lazy"
        className="w-[200px] h-[200px] lg:w-[200px] rounded-lg object-cover"
      />
      <p className="font-bold text-md text-center mt-3">
        {data.category_items.subtitle}
      </p>
      <div className="flex flex-row gap-1 mt-2">
        {[...Array(data.stars)].map(() => (
          <AiFillStar key={Math.random()} color="#DC7E19" />
        ))}
      </div>
      <p className="text-[#868686] text-sm mt-3 text-left mb-[-4px]">
        a partir de
      </p>
      <p className="font-bold text-2xl">
        {maskHelper.formatMoeda(
          data.category_items.unit === 'Kg'
            ? Number(data.price_by_kg) - Number(data.discount)
            : (Number(data.price_by_kg) - Number(data.discount)) *
                Number(data.product_weight_range[0].weight_end)
        )}
        <span className="font-light text-base">
          /{data.category_items.unit}
        </span>
      </p>
      <button
        type="button"
        onClick={() => navigate(`/products/${data.id}`)}
        className="font-bold first-line:flex flex-row items-center justify-center text-sm gap-2 p-[10.2px] pl-4 pr-4 mt-3 bg-primary w-52 rounded-md"
      >
        Ver produto
      </button>
    </div>
  );
};

export default CardProduct;
