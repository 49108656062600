import React, { useContext } from 'react';
import {
  AiOutlineClose,
  AiOutlineSetting,
  AiOutlineStar,
} from 'react-icons/ai';
import { IoMdLogIn } from 'react-icons/io';
import {
  MdOutlineContactSupport,
  MdOutlineRequestQuote,
  MdProductionQuantityLimits,
} from 'react-icons/md';
import { RiProductHuntLine, RiUserSettingsLine } from 'react-icons/ri';
import { TiArrowBack } from 'react-icons/ti';
import { FaGrinStars } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import { VscChecklist } from 'react-icons/vsc';
import { GiPresent } from 'react-icons/gi';
import logoMobile from '../assets/images/logo_alternative.png';
import SessionContext from '../contexts/session.context';

type DrawerProps = {
  purshase: any;
  /* eslint-disable no-unused-vars */
  setDrawer: (e: boolean) => void;
};

const Drawer: React.FC<DrawerProps> = ({ purshase, setDrawer }) => {
  const navigate = useNavigate();
  const { isAuthenticated, signOut, user } = useContext(SessionContext);
  const pathname = window.location.pathname.substr(1);

  console.log(isAuthenticated, user.role_id);

  function logoff() {
    setDrawer(false);
    signOut();
    navigate('/');
  }

  return (
    <div className="w-full h-full bg-black/95 z-20 p-8 pt-6 absolute">
      <div className="flex flex-row items-center justify-center relative">
        <AiOutlineClose
          size={25}
          className="absolute right-0 top-0"
          color="#FFC369"
          onClick={() => setDrawer(false)}
        />
        <img src={logoMobile} alt="" className="w-48" />
      </div>
      <div className="flex flex-col items-center mt-12 font-light text-xl">
        <div className="flex flex-col gap-4">
          <Link
            to="/"
            onClick={() => setDrawer(false)}
            className="flex items-center gap-3 font-light text-primary"
          >
            <TiArrowBack size={22} />
            <span>Loja</span>
          </Link>

          <Link
            to="/products"
            onClick={() => setDrawer(false)}
            className="flex items-center gap-3 font-light text-primary"
          >
            <RiProductHuntLine size={22} />
            <span>Nossos Produtos</span>
          </Link>

          {pathname === '' || pathname === 'home' ? (
            <a
              href="#benefits"
              onClick={() => setDrawer(false)}
              className="flex items-center gap-3 font-light text-primary"
            >
              <AiOutlineStar size={22} />
              <span>Benefícios</span>
            </a>
          ) : (
            <Link
              to="/#benefits"
              onClick={() => setDrawer(false)}
              className="flex items-center gap-3 font-light text-primary"
            >
              <AiOutlineStar size={22} />
              <span>Benefícios</span>
            </Link>
          )}

          {pathname === '' || pathname === 'home' ? (
            <a
              href="#benefits"
              onClick={() => setDrawer(false)}
              className="flex items-center gap-3 font-light text-primary"
            >
              <FaGrinStars size={22} />
              <span>Vantagens</span>
            </a>
          ) : (
            <Link
              to="/#benefits"
              onClick={() => setDrawer(false)}
              className="flex items-center gap-3 font-light text-primary"
            >
              <FaGrinStars size={22} />
              <span>Vantagens</span>
            </Link>
          )}

          {pathname === '' || pathname === 'home' ? (
            <a
              href="#help"
              onClick={() => setDrawer(false)}
              className="flex items-center gap-3 font-light text-primary"
            >
              <MdOutlineContactSupport size={25} />
              <span>Ajuda</span>
            </a>
          ) : (
            <Link
              to="/#help"
              onClick={() => setDrawer(false)}
              className="flex items-center gap-3 font-light text-primary"
            >
              <FaGrinStars size={22} />
              <span>Ajuda</span>
            </Link>
          )}

          {isAuthenticated && (
            <>
              <div className="h-[1px] bg-primary my-4 opacity-20" />

              <Link
                to="/account"
                className="flex items-center gap-3 font-light text-primary"
              >
                <RiUserSettingsLine size={22} />
                <span>Meus dados</span>
              </Link>
              <Link
                to="/requests"
                className="flex items-center gap-3 font-light text-primary"
              >
                <MdOutlineRequestQuote size={22} />
                <span>Meus pedidos</span>
              </Link>
              <Link
                to="/payments"
                className="flex items-center gap-3 font-light text-primary"
              >
                <VscChecklist size={22} />
                <span>Clube Carletto</span>
              </Link>
              <Link
                to="/indicated"
                className="flex items-center gap-3 font-light text-primary"
              >
                <GiPresent size={22} />
                <span>Indicação</span>
              </Link>
              <Link
                to="/shopping-summary"
                className="relative flex items-center gap-3 font-light text-primary"
                onClick={() => setDrawer(false)}
              >
                <MdProductionQuantityLimits size={22} />
                <span>Meu Carrinho</span>
                {purshase && (
                  <span className="animate-bounce font-bold w-4 bg-black text-white text-center text-xs rounded-full cursor-pointer absolute top-[-2px] right-7">
                    {purshase.qty}
                  </span>
                )}
              </Link>

              {user.role_id === 2 && (
                <Link
                  to="/dashboard"
                  className="flex items-center gap-3 font-light text-primary"
                >
                  <AiOutlineSetting size={22} />
                  <span>Admin</span>
                </Link>
              )}
            </>
          )}
        </div>

        {isAuthenticated ? (
          <button
            type="button"
            onClick={logoff}
            className="bg-[#FFC369] w-[85%] p-3 px-8 absolute bottom-6 rounded-md font-bold flex flex-row items-center justify-center"
          >
            <IoMdLogIn size={25} />
            <span className="ml-2 text-sm">Desconectar</span>
          </button>
        ) : (
          <Link
            to="/login"
            onClick={() => setDrawer(false)}
            className="bg-[#FFC369] w-[85%] p-3 px-8 absolute bottom-6 rounded-md font-bold flex flex-row items-center justify-center"
          >
            <IoMdLogIn size={25} />
            <span className="ml-2 text-sm">Entrar</span>
          </Link>
        )}
      </div>
    </div>
  );
};

export default Drawer;
