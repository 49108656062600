import React, { useState } from 'react';
import { BsFillPlayFill } from 'react-icons/bs';
import { MdOutlinePause } from 'react-icons/md';
import ReactPlayer from 'react-player';

import videoPromo from '../../../assets/mp4/promo.mp4';

const IS_MOBILE = window.screen.width < 600;

const PromotionalVideo: React.FC = () => {
  const [playing, setPlaying] = useState(false);

  const handlePlayPause = () => {
    setPlaying(!playing);
  };

  return (
    <>
      <div className="flex items-center justify-between w-full sm:pt-12 bg-background pt-8 relative">
        <div className="bg-primary h-[0.5px] w-[18%] sm:w-[32%] md:w-[37%] lg:w-[40%] xl:w-[42%] 2xl:w-[45%]" />
        <p className="font-bold text-2xl sm:text-2xl text-center">
          Lançamentos
        </p>
        <div className="bg-primary h-[0.5px] w-[18%] sm:w-[32%] md:w-[37%] lg:w-[40%] xl:w-[42%] 2xl:w-[45%]" />
      </div>
      <div className="relative w-full px-5 sm:px-32 py-12 bg-background">
        <ReactPlayer
          url={videoPromo}
          playing={playing}
          width="100%"
          height="90%"
          onPause={() => setPlaying(false)}
        />
        <button
          id="bottom"
          type="button"
          onClick={handlePlayPause}
          className="flex justify-between active:animate-ping items-center absolute top-12 sm:top-12 left-5 right-5 sm:left-32 sm:right-32 h-48 sm:h-[87%] bg-black/10"
        >
          {!playing ? (
            <div className="p-2 bg-black ml-[46%] rounded-full cursor-pointer sm:hover:bg-primary-light">
              <BsFillPlayFill color="#DC7E19" size={IS_MOBILE ? 20 : 30} />
            </div>
          ) : (
            <div className="p-[0.15rem] sm:p-2 ml-[46%] bg-primary/40 active:animate-ping rounded-full cursor-pointer sm:hover:bg-primary-light">
              <MdOutlinePause color="#000" size={30} />
            </div>
          )}
        </button>
      </div>

      {/* <div className="relative w-full px-5 sm:px-32 py-12 bg-background">
        <ReactPlayer
          url={videoPromo}
          playing={playing}
          width="100%"
          height="90%"
          onPause={() => setPlaying(false)}
        />
        <div className="flex justify-between items-center absolute top-[47%] left-5 right-5 sm:left-32 sm:right-32">
          {!playing ? (
            <button
              type="button"
              onClick={handlePlayPause}
              className="p-2 bg-black ml-[46%] rounded-full active:animate-ping cursor-pointer sm:hover:bg-primary-light"
            >
              <BsFillPlayFill color="#DC7E19" size={IS_MOBILE ? 20 : 30} />
            </button>
          ) : (
            <button
              type="button"
              onClick={handlePlayPause}
              className="p-[0.15rem] sm:p-2 ml-[46%] bg-primary/40 active:animate-ping rounded-full cursor-pointer sm:hover:bg-primary-light"
            >
              <MdOutlinePause color="#000" size={30} />
            </button>
          )}
        </div>
      </div> */}
    </>
  );
};

export default PromotionalVideo;
