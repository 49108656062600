import maskHelper from './mask.helper';

export const translateStatusPay = (status: string): string => {
  if (status === 'PENDING') return 'Pendente';
  if (
    status === 'RECEIVED' ||
    status === 'CONFIRMED' ||
    status === 'RECEIVED_IN_CASH'
  )
    return 'Confirmado';
  if (status === 'OVERDUE') return 'Vencida';
  if (status === 'DELETED') return 'Excluída';
  if (status === 'REFUNDED') return 'Estornada';
  return status;
};

export const translateTypePay = (status: string): string => {
  if (status === 'CREDIT_CARD') return 'Cartão de Crédito';

  return 'PIX';
};

export const translateStatusSubscription = (status: string): string => {
  if (status === 'ACTIVE') return 'Ativa';

  return 'Inativa';
};

export const calculePrice = (
  priceByKg: string | number,
  weightEnd: string | number
) =>
  maskHelper.formatMoeda(
    (Number(priceByKg) * Number(String(weightEnd).replace('.', ''))) / 1000
  );

export const calculePriceDiscount = (
  priceByKg: string | number,
  weightEnd: string | number,
  discount: string | number
) =>
  maskHelper.formatMoeda(
    (Number(priceByKg) * Number(String(weightEnd).replace('.', ''))) / 1000 -
      Number(discount)
  );

export const normalizeNFD = (value: string) =>
  value.normalize('NFD').replace(/[^a-zA-Z\s]/g, '');
