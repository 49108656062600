import React, { memo, useEffect } from 'react';
import { BsChevronDown, BsChevronRight } from 'react-icons/bs';
import CardDetailsProduct from './CardDetailsProduct';
import bull from '../../../assets/images/bull.png';
import pig from '../../../assets/images/pig.png';
import ticken from '../../../assets/images/ticken.png';
import sausage from '../../../assets/images/sausage.png';
import vinho from '../../../assets/images/vinho.png';
import massa from '../../../assets/images/massa.png';
import tempero from '../../../assets/images/tempero.png';

type Items = {
  id: number;
  title: string;
};

type AccordionProps = {
  category: {
    title: string;
    icon: any;
    key: string;
    category_items: Items[];
  };
  index: number;
  accordionIndex: number | null;
  brand?: any;
  selectedItem: any;

  /* eslint-disable no-unused-vars */
  setAccordionIndex: (e: number | null) => void;
  setSelectedItem: (e: any) => void;
  setBrand: (e: any) => void;
};

const Accordion: React.FC<AccordionProps> = ({
  category,
  index,
  accordionIndex,
  setBrand,
  setAccordionIndex,
  setSelectedItem,
  selectedItem,
  brand,
}) => {
  useEffect(() => {
    if (!accordionIndex) {
      setBrand(null);
      setSelectedItem(null);
    }
  }, [accordionIndex]);

  function getIcon(icon: string) {
    switch (icon) {
      case 'bull':
        return <img alt="Carletto" src={bull} loading="lazy" />;
      case 'sausage':
        return <img alt="Carletto" src={sausage} loading="lazy" />;
      case 'pig':
        return <img alt="Carletto" src={pig} loading="lazy" />;
      case 'ticken':
        return <img alt="Carletto" src={ticken} loading="lazy" />;
      case 'vinho':
        return <img alt="Carletto" src={vinho} loading="lazy" />;
      case 'massa':
        return <img alt="Carletto" src={massa} loading="lazy" />;
      case 'tempero':
        return <img alt="Carletto" src={tempero} loading="lazy" />;
      default: {
        return '';
      }
    }
  }

  return (
    <div>
      <button
        type="button"
        className="flex items-center gap-2 relative w-full"
        onClick={() => {
          setBrand(null);
          setSelectedItem(null);
          setAccordionIndex(accordionIndex === index ? null : index);
        }}
      >
        <div className="bg-primary p-3 rounded-full flex items-center justify-center w-12">
          {getIcon(category.key)}
        </div>
        <span className="font-bold text-lg lg:text-sm">{category.title}</span>
        {accordionIndex === index ? (
          <BsChevronDown size={15} className="absolute top-4 right-0" />
        ) : (
          <BsChevronRight size={15} className="absolute top-4 right-0" />
        )}
      </button>

      <ul
        className={`${
          accordionIndex === index
            ? window.screen.width < 1024 && selectedItem
              ? 'hidden'
              : ''
            : 'hidden'
        } pl-[70px] font-regular text-lg lg:text-sm text-left`}
      >
        {category.category_items?.map((item) => (
          <li
            key={Math.random()}
            onClickCapture={() => {
              setBrand(null);
              setSelectedItem(item);
            }}
            className={`mb-2 hover:text-primary ${
              item.id === selectedItem?.id ? 'text-primary' : 'text-black'
            }`}
          >
            {item.title}
          </li>
        ))}
      </ul>

      {selectedItem && accordionIndex === index && (
        <CardDetailsProduct
          isMobile
          setBrand={setBrand}
          brand={brand}
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
        />
      )}

      <div className="h-[0.5px] solid bg-[#aaa] mt-4 mb-4" />
    </div>
  );
};

export default memo(Accordion);
