import { useCallback, useContext, useEffect, useState } from 'react';
import { FiCreditCard } from 'react-icons/fi';
import { BsChevronLeft, BsTrash, BsTruck } from 'react-icons/bs';
import { MdOutlineConfirmationNumber } from 'react-icons/md';
import { ReactSVG } from 'react-svg';
import ReactLoading from 'react-loading';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { yupResolver } from '@hookform/resolvers/yup';
import { motion } from 'framer-motion';
import yup from '../../../config/yup';

import pix from '../../../assets/svg/pix.svg';

import Footer from '../../../components/Footer';
import Copyright from '../../../components/Copyright';
import Header from '../../../components/Header';
import PurshaseContext from '../../../contexts/purshase.context';
import { CupomServices, SessionServices } from '../../../services';
import maskHelper from '../../../helpers/mask.helper';
import AlertContext from '../../../contexts/alert.context';
import SessionContext from '../../../contexts/session.context';
import PurshaseServices from '../../../services/purshase.service';
import LoadingContext from '../../../contexts/loading.context';
import { getItem } from '../../../helpers/storage.helper';

interface IForm {
  holderName: string;
  number: string;
  expiry: string;
  cvv: string;
}

export default function ShoppingSummaryPage() {
  const [cupom, setCupom] = useState('');
  const [loadingCupom, setLoadingCupom] = useState(false);
  const [optionPay, setOptionPay] = useState('');
  const { loading, setLoading } = useContext(LoadingContext);
  const { alert, setAlert } = useContext(AlertContext);
  const navigate = useNavigate();
  const { user, isAuthenticated } = useContext(SessionContext);
  const {
    purshase,
    delQtdPurshase,
    delPurshase,
    delAllPurshase,
    addCupomPurshase,
  } = useContext(PurshaseContext);
  let render = false;

  const schema = yup
    .object({
      holderName: yup.string().required(),
      number: yup.string().length(19, 'Obrigatório 16 dígitos').required(),
      expiry: yup
        .string()
        .length(7, 'Valida inválida')
        .required()
        .test('valid-expiry', 'Mês/Ano inválido', (value) => {
          const expiryMonth = Number(value?.substring(0, 2));
          const expiryYear = Number(value?.substring(3, 7));
          const date = new Date();
          const year = date.getFullYear();
          const month = new Date().getMonth() + 1;

          if (expiryMonth > 0 && expiryMonth <= 12 && expiryYear >= year) {
            if (year === expiryYear && month > expiryMonth) return false;

            return true;
          }

          return false;
        }),
      cvv: yup
        .string()
        .min(3, 'Mínimo 3 dígitos')
        .max(4, 'Máximo 4 dígitos')
        .required(),
    })
    .required();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<IForm>({
    resolver: yupResolver(schema),
  });

  async function isValidCupom() {
    try {
      if (cupom.trim() !== '') {
        if (!purshase?.cupom_value) {
          setLoadingCupom(true);
          const response: any = await new CupomServices().isValid(cupom.trim());

          if (response.data === 'error_cupom_invalid') {
            toast.warn('Cupom inválido');
            return;
          }

          addCupomPurshase(response.data);
          setCupom('');
        } else {
          toast.warn('Você já adicionou cupom neste pedido');
        }
      }
    } catch (e: any) {
      console.log('error', e);
    } finally {
      setLoadingCupom(false);
    }
  }

  const handleSendPurshase = async (data?: any) => {
    try {
      setLoading({
        title: 'Estamos processanndo...',
        isOpen: true,
      });

      delete purshase?.createdAt;

      if (optionPay === 'PIX') {
        const responsePix: any = await new PurshaseServices().sendPix(purshase);

        if (responsePix?.data === 'error_not_subscription_active') {
          toast.warn(`Ops! Estamos com pendência na sua assinatura.`);
          setTimeout(() => navigate('/payments'), 2000);
          return;
        }

        delAllPurshase();
        navigate('/pix', { state: responsePix.data });
        return;
      }

      const params = {
        ...purshase,
        creditCard: {
          holderName: data?.holderName.toUpperCase(),
          number: data?.number.replace(/\s/g, ''),
          expiryMonth: data?.expiry.substring(0, 2),
          expiryYear: data?.expiry.substring(3, 7),
          ccv: data?.cvv,
        },
      };

      const responseAuth: any = await new PurshaseServices().sendCreditCard(
        params
      );

      if (responseAuth?.data === 'error_not_subscription_active') {
        toast.warn(`Ops! Estamos com pendência na sua assinatura.`);
        setTimeout(() => navigate('/payments'), 2000);
        return;
      }

      setAlert({
        title: 'Parábens, pedido concluído!',
        description:
          'Estamos finalizando a conferência do pagamento e logo enviaremos seu pedido :)',
        isOpen: true,
        button: [
          {
            title: 'Ok, entendi!',
            type: 'green',
            onClick: () => {
              setAlert({ ...alert, isOpen: false });
              delAllPurshase();
              navigate('/');
            },
          },
        ],
      });
    } catch (e: any) {
      console.log('error', e);
      if (e?.message === 'Validation failed') {
        toast.warn(`O ${e?.validation.body.message}`);
        return;
      }

      if (e?.message === 'error_not_subscription_active') {
        toast.warn(`Ops! Estamos com pendência na sua assinatura.`);
        setTimeout(() => navigate('/payments'), 2000);
        return;
      }

      if (e?.errors) {
        toast.warn(e?.errors[0]?.description);
        return;
      }

      console.log('error', e);
    } finally {
      setLoading({
        ...loading,
        isOpen: false,
      });
    }
  };

  const repairStock = useCallback(() => {
    purshase?.items.forEach(async (item: any) => {
      const response: any = await new SessionServices().getStock(
        item.product_weight_range_json.id
      );

      if (item.qty > response.data.qty) {
        delQtdPurshase(
          item.product_id,
          item.product_weight_range_json.id,
          response.data.qty,
          false
        );
        navigate('/warning-stock');
      }
    });
  }, []);

  useEffect(() => {
    const storagedUser = getItem('user', true);
    const storagedToken = getItem('token');
    const storagedPurshase = getItem('purshase', true);

    if (!isAuthenticated && (!storagedUser || !storagedToken)) {
      navigate('/login');
    } else if (!storagedPurshase) {
      navigate('/products');
    } else if (!render) {
      repairStock();
      render = true;
    }
  }, []);

  return (
    <div className="z-0 h-screen relative flex justify-center">
      <div className="bg-white relative w-full h-screen">
        <Header
          isDivideMarginTop
          title="Nossos produtos"
          description="As melhores peças para você."
          isApresentation={false}
        />

        <motion.section
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="flex justify-center flex-col-reverse xl:flex-row gap-4 p-3 sm:pt-10 2xl:pt-24 sm:px-24 lg:px-56 bg-background min-h-full h-fit"
        >
          <div className="w-[100%] xl:w-[500px] bg-white rounded-md shadow-xs p-7 h-fit">
            <button
              type="button"
              className="hidden xl:flex items-center gap-1 mb-6"
            >
              <BsChevronLeft size={15} />
              <span className="font-bold text-xs">Voltar</span>
            </button>

            <p className="flex xl:hidden font-bold text-sm mb-5">
              Pagamento e envio
            </p>

            <button
              type="button"
              onClick={() => navigate('/account')}
              className="cursor-pointer flex items-center gap-2 border-[1px] rounded-md border-primary border-solid p-3 bg-primary-light bg-opacity-20"
            >
              <BsTruck size={14} color="#DC7E19" />
              <span className="font-light text-xs">
                Entrega: {user.address}, {user.number}, {user.province},{' '}
                {user.city}/{user.state}
              </span>
            </button>

            <div className="flex flex-col mt-6 gap-1">
              <span className="font-bold text-xs mb-2">
                Formas de Pagamento
              </span>
              <div className="flex gap-4">
                <button
                  type="button"
                  onClick={() => setOptionPay('CREDIT_CARD')}
                  className="flex flex-col items-center gap-1 cursor-pointer"
                >
                  {optionPay === 'CREDIT_CARD' ? (
                    <>
                      <div className="rounded-md p-2 border-[1px] bg-primary-light bg-opacity-25 border-primary border-solid w-[47px] flex justify-center">
                        <FiCreditCard size={26} color="#DC7E19" />
                      </div>
                      <span className="font-light text-xs text-primary">
                        Cartão
                      </span>
                    </>
                  ) : (
                    <>
                      <div className="rounded-md p-2 border-[1px] bg-white bg-opacity-25 border-[#aaa] border-solid w-[47px] flex justify-center">
                        <FiCreditCard size={26} color="#aaa" />
                      </div>
                      <span className="font-light text-xs ">Cartão</span>
                    </>
                  )}
                </button>
                <button
                  type="button"
                  onClick={() => setOptionPay('PIX')}
                  className="flex flex-col items-center gap-1 cursor-pointer"
                >
                  {optionPay === 'PIX' ? (
                    <>
                      <div className="rounded-md p-2 border-[1px] bg-primary-light bg-opacity-25 border-primary border-solid w-[47px] flex justify-center">
                        <ReactSVG src={pix} className="cursor-pointer" />
                      </div>
                      <span className="font-light text-xs text-primary">
                        Pix
                      </span>
                    </>
                  ) : (
                    <>
                      <div className="rounded-md p-2 border-[1px] bg-white bg-opacity-25 border-[#aaa] border-solid w-[47px] flex justify-center">
                        <ReactSVG src={pix} className="cursor-pointer" />
                      </div>
                      <span className="font-light text-xs ">Pix</span>
                    </>
                  )}
                </button>
              </div>
            </div>

            {optionPay === 'CREDIT_CARD' && (
              <form
                id="form"
                onSubmit={handleSubmit(handleSendPurshase)}
                autoComplete="off"
              >
                <div className="flex flex-col mt-6 gap-1 relative">
                  <span className="font-light text-xs">
                    Nome no Cartão de Crédito
                  </span>
                  <input
                    className={classNames(
                      'uppercase border-solid border-[0.5px] border-[#aaa] p-3 rounded-md text-xs w-full sm:w-[77%]',
                      {
                        'border-red': !!errors.holderName,
                      }
                    )}
                    placeholder="Ex: CARLETTO R S SILVA"
                    {...register('holderName')}
                  />
                  <span
                    className={classNames(
                      'absolute text-red text-[0.60rem] left-1 bottom-[-15px]',
                      {
                        hidden: !!errors.holderName === false,
                      }
                    )}
                  >
                    {errors.holderName?.message}
                  </span>
                </div>

                <div className="flex flex-col mt-5 gap-1 relative">
                  <span className="font-light text-xs">Número do Cartão</span>
                  <input
                    className={classNames(
                      'border-solid border-[0.5px] border-[#aaa] p-3 rounded-md text-xs w-full sm:w-[77%]',
                      {
                        'border-red': !!errors.number,
                      }
                    )}
                    placeholder="0000 0000 0000 0000"
                    {...register('number')}
                    value={maskHelper.creditCard(String(watch('number')))}
                  />
                  <span
                    className={classNames(
                      'absolute text-red text-[0.60rem] left-1 bottom-[-15px]',
                      {
                        hidden: !!errors.number === false,
                      }
                    )}
                  >
                    {errors.number?.message}
                  </span>
                </div>

                <div className="flex flex-row gap-3 2xl:gap-4 mt-5">
                  <div className="flex flex-col gap-1 w-[48%] sm:w-[37.5%] relative">
                    <span className="font-light text-xs">Validade</span>
                    <input
                      className={classNames(
                        'border-solid border-[0.5px] border-[#aaa] p-3 rounded-md text-xs',
                        {
                          'border-red': !!errors.expiry,
                        }
                      )}
                      placeholder="mm/aaaa"
                      {...register('expiry')}
                      value={maskHelper.monthYear(String(watch('expiry')))}
                    />
                    <span
                      className={classNames(
                        'absolute text-red text-[0.60rem] left-1 bottom-[-15px]',
                        {
                          hidden: !!errors.expiry === false,
                        }
                      )}
                    >
                      {errors.expiry?.message}
                    </span>
                  </div>
                  <div className="flex flex-col gap-1 w-[48%] sm:w-[37.5%] relative">
                    <span className="font-light text-xs">CVV</span>
                    <input
                      className={classNames(
                        'border-solid border-[0.5px] border-[#aaa] p-3 rounded-md text-xs',
                        {
                          'border-red': !!errors.cvv,
                        }
                      )}
                      placeholder="0000"
                      value={maskHelper.number(String(watch('cvv')))}
                      {...register('cvv')}
                    />
                    <span
                      className={classNames(
                        'absolute text-red text-[0.60rem] left-1 bottom-[-15px]',
                        {
                          hidden: !!errors.cvv === false,
                        }
                      )}
                    >
                      {errors.cvv?.message}
                    </span>
                  </div>
                </div>
                <div className="flex flex-col-reverse sm:flex-row gap-3 mt-8">
                  <button
                    type="button"
                    disabled={loading.isOpen}
                    onClick={() => navigate('/products')}
                    className={classNames(
                      'rounded-md border-primary border-solid border-[1px] p-2 pl-6 pr-6 font-Inter font-bold text-xs text-primary',
                      {
                        'bg-opacity-20 text-gray-400 border-gray-400 cursor-not-allowed':
                          loading.isOpen,
                      }
                    )}
                  >
                    Continuar Comprando
                  </button>
                  <button
                    type="submit"
                    form="form"
                    disabled={loading.isOpen}
                    className={classNames(
                      'rounded-md bg-primary p-2 pl-6 pr-6 font-Inter font-bold text-xs text-black relative',
                      {
                        'bg-opacity-20 text-gray-400 cursor-not-allowed hover:bg-opacity-20':
                          loading.isOpen,
                      }
                    )}
                  >
                    <span>Confirmar Pagamento</span>
                    <ReactLoading
                      type="spokes"
                      color="#DC7E19"
                      width={15}
                      className={classNames(
                        'absolute right-[0.28rem] top-[0.6rem]',
                        {
                          hidden: !loading.isOpen,
                        }
                      )}
                    />
                  </button>
                </div>
              </form>
            )}

            {optionPay === 'PIX' && (
              <div className="flex flex-col-reverse sm:flex-row gap-3 mt-7">
                <button
                  type="button"
                  onClick={() => navigate('/products')}
                  className="rounded-md border-primary border-solid border-[1px] p-2 pl-6 pr-6 font-Inter font-bold text-xs text-primary"
                >
                  Continuar Comprando
                </button>
                <button
                  type="submit"
                  form="form"
                  disabled={loading.isOpen}
                  onClick={handleSendPurshase}
                  className={classNames(
                    'rounded-md bg-primary p-2 pl-6 pr-6 font-Inter font-bold text-xs text-black relative',
                    {
                      'bg-opacity-20 text-gray-400 cursor-not-allowed hover:bg-opacity-20':
                        loading.isOpen,
                    }
                  )}
                >
                  <span>Confirmar Pagamento</span>
                  <ReactLoading
                    type="spokes"
                    color="#DC7E19"
                    width={15}
                    className={classNames(
                      'absolute right-[0.28rem] top-[0.6rem]',
                      {
                        hidden: !loading.isOpen,
                      }
                    )}
                  />
                </button>
              </div>
            )}
          </div>
          <div className="w-[100%] xl:w-[450px] bg-white rounded-md shadow-xs p-3 h-fit">
            <p className="font-bold text-sm p-3">Resumo do Pedido</p>
            <div className="p-3">
              {purshase?.items.map((item: any) => (
                <div
                  className="flex items-center justify-between mb-2"
                  key={Math.random()}
                >
                  <p className="text-sm font-regular w-[40%]">
                    <span className="font-light mr-2 text-slate-400">
                      {item.qty}x
                    </span>{' '}
                    {item.product_json.category_items.subtitle}
                  </p>
                  <span className="text-sm font-light hidden sm:flex">
                    {item.product_weight_range_json.weight_start} a{' '}
                    {item.product_weight_range_json.weight_end}{' '}
                    {item.product_json.category_items.unit}
                  </span>
                  <span className="text-sm font-regular">
                    {maskHelper.formatMoeda(item.subtotal)}
                  </span>
                  <BsTrash
                    size={14}
                    color="#DC7E19"
                    className="cursor-pointer"
                    onClick={() =>
                      setAlert({
                        title: 'Atenção!',
                        description:
                          'Confirma a exclusão deste produto do pedido?',
                        isOpen: true,
                        button: [
                          {
                            title: 'Não',
                            type: 'black-regular',
                            onClick: () =>
                              setAlert({ ...alert, isOpen: false }),
                          },
                          {
                            title: 'Sim, confirmar',
                            type: 'red',
                            onClick: () => {
                              if (purshase?.items.length === 1)
                                navigate('/products');

                              delPurshase(item.product_weight_range_id);
                              setAlert({ ...alert, isOpen: false });
                            },
                          },
                        ],
                      })
                    }
                  />
                </div>
              ))}
            </div>
            <div className="h-[2px] bg-black-regular w-full mt-1 mb-1" />
            <div className="flex flex-col p-3 font-light text-sm">
              <div className="flex justify-between mb-1">
                <span className="font-bold">Subtotal</span>
                <span className="font-bold">
                  {maskHelper.formatMoeda(purshase?.subtotal)}
                </span>
              </div>
              <div className="flex justify-between mb-1">
                <span>Taxa de Entrega</span>
                <span>{maskHelper.formatMoeda(purshase?.delivery)}</span>
              </div>
              <div className="flex justify-between">
                <span>Desconto</span>
                <span className="text-green">
                  {maskHelper.formatMoeda(purshase?.discount)}
                </span>
              </div>
            </div>
            <div className="h-[2px] bg-black-regular w-full mt-1 mb-1" />
            <div className="flex flex-col p-3 font-bold text-sm">
              <div className="flex items-center justify-between mb-3">
                <span>Total</span>
                <span className="text-lg">
                  {maskHelper.formatMoeda(purshase?.total)}
                </span>
              </div>
              <div className="flex justify-between p-3 text-xs text-green bg-green bg-opacity-10 rounded-md border-solid border-[1px] border-green mb-4">
                <span className="font-light">
                  Desconto total para este pedido
                </span>
                <p>{maskHelper.formatMoeda(purshase?.discount)}</p>
              </div>
              <div className="flex justify-between">
                <div className="relative w-[75%]">
                  <input
                    disabled={loadingCupom}
                    onBlur={(e: any) => setCupom(e.target.value)}
                    className={classNames(
                      'border-solid border-[0.5px] border-[#aaa] p-3 pl-7 rounded-md text-xs w-[100%]',
                      {
                        'bg-[#ddd]': loadingCupom,
                      }
                    )}
                    placeholder="Cupom de Desconto"
                  />
                  <MdOutlineConfirmationNumber
                    size={14}
                    color="#aaa"
                    className="absolute top-[13.5px] left-2"
                  />
                </div>
                <button
                  disabled={loadingCupom}
                  type="button"
                  onClick={() => isValidCupom()}
                  className={classNames(
                    'border-solid border-[0.5px] border-[#aaa] p-3 pl-4 pr-4 rounded-md text-xs w-[23%]',
                    {
                      'text-[#ddd]': loadingCupom,
                    }
                  )}
                >
                  {loadingCupom ? 'Aguarde...' : 'Aplicar'}
                </button>
              </div>
            </div>
          </div>
        </motion.section>

        <Footer />
        <Copyright />
      </div>
    </div>
  );
}
