import classNames from 'classnames';
import React from 'react';
import { BsCalendar3, BsTruck } from 'react-icons/bs';
import { GiForkKnifeSpoon } from 'react-icons/gi';
import { GrGroup } from 'react-icons/gr';
import { useNavigate } from 'react-router-dom';

type BenefitsProps = {
  colorInversePrimary?: boolean;
  path: string;
};

const Benefits: React.FC<BenefitsProps> = ({
  colorInversePrimary = false,
  path,
}) => {
  const navigate = useNavigate();

  return (
    <section
      className={classNames(
        'flex flex-col items-center p-10 lg:pl-32 lg:pr-32 pt-14 pb-14 bg-primary ',
        {
          'bg-white': colorInversePrimary,
        }
      )}
      id="benefits"
    >
      <p className="font-bold text-center mb-14 text-2xl">
        Benefícios Sócios Carletto Meat Club
      </p>
      <div className="flex justify-between flex-col md:flex-row items-center gap-10 lg:gap-28 w-full">
        <div className="flex items-center flex-col md:flex-row gap-3 w-full md:w-[50%] ">
          <GrGroup color="#000" size={70} />
          <div className="text-center md:text-left">
            <p className="font-bold lg:text-md text-xl">
              O que precisa pra ser Membro?
            </p>
            <p className="font-light">
              Super simples. Basta gostar de um bom churrasco e ser convidado
              por algum dos nossos membros.
            </p>
          </div>
        </div>
        <div className="flex items-center flex-col md:flex-row gap-3  w-full md:w-[50%]">
          <GiForkKnifeSpoon color="#000" size={70} />
          <div className="text-center md:text-left">
            <p className="font-bold lg:text-md text-xl">Cortes</p>
            <p className="font-light">
              Para os apaixonados por comida e carne bovina. Curadoria, conforto
              e conteúdo são garantidos.
            </p>
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      <div className="flex justify-between flex-col md:flex-row items-center gap-10 lg:gap-28 w-full">
        <div className="flex items-center flex-col md:flex-row gap-3 w-full md:w-[50%] ">
          <BsCalendar3 color="#000" size={70} />
          <div className="text-center md:text-left">
            <p className="font-bold lg:text-md text-xl">
              Faça sua adesão ao Clube Carletto
            </p>
            <p className="font-light">
              Processo de assinatura é simples, fácil e seguro. Faça seu
              cadastro, ative sua assinatura e pronto. Já pode planejar seu
              churrasco.
            </p>
          </div>
        </div>
        <div className="flex items-center flex-col md:flex-row gap-3  w-full md:w-[50%]">
          <BsTruck color="#000" size={70} />
          <div className="text-center md:text-left">
            <p className="font-bold lg:text-md text-xl">
              Entregamos na sua casa
            </p>
            <p className="font-light">Segunda a domingo, de 09 às 19h</p>
          </div>
        </div>
      </div>

      <br />
      <br />

      <p className="text-center lg:text-left">
        <span className="font-bold">A Nossa missão</span> é democratizar o
        consumo de carnes para churrasco, proporcionando experiências
        inesquecíveis aos nossos churrasqueiros. Entregar sempre os melhores
        produtos e serviços de excelência. Tornar- se referência para os
        apreciadores de carnes em todo o território nacional.
      </p>

      <br />
      <br />

      <button
        type="button"
        onClick={() => navigate(`/${path}`)}
        className={classNames(
          'bg-white flex flex-col lg:flex-row text-center lg:text-left items-center justify-between gap-3 p-5 w-full rounded-md',
          {
            'bg-orange-400': colorInversePrimary,
          }
        )}
      >
        <div>
          <p className="font-bold text-2xl lg:text-xl">Não fique de fora!</p>
          <p className="font-light mt-2 lg:mt-0">
            Assine agora e obtenha os melhores cortes a preço de custo.
          </p>
        </div>
        <button
          onClick={() => navigate(`/${path}`)}
          type="button"
          className={classNames(
            'rounded-md bg-primary p-3 mt-2 lg:mt-0 lg:pl-8 lg:pr-8 font-Inter font-bold text-md text-black',
            {
              'bg-white': colorInversePrimary,
            }
          )}
        >
          Quero fazer parte!
        </button>
      </button>
    </section>
  );
};

export default Benefits;
