import { useContext, useEffect, useState } from 'react';
import ReactLoading from 'react-loading';
import { BiCategoryAlt } from 'react-icons/bi';
import { AiOutlinePlus } from 'react-icons/ai';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { FaEdit } from 'react-icons/fa';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import { CategoryServices } from '../../../services';
import Layout from '../components/Layout';
import Modal from '../../../components/Modal';
import SelectCustom from '../../../components/Select';
import Switch from '../../../components/Switch';
import { keysCategory } from '../mocks';
import AlertContext from '../../../contexts/alert.context';
import LoadingContext from '../../../contexts/loading.context';

export default function Category() {
  const { alert, setAlert } = useContext(AlertContext);
  const { loading, setLoading } = useContext(LoadingContext);
  const [loadingSave, setLoadingSave] = useState(false);
  const [isOpenForm, setIsOpenForm] = useState(false);
  const [data, setData] = useState([]);
  const [item, setItem] = useState<any>({
    id: '',
    title: '',
    key: '',
    active: false,
  });

  const [errorForm, setErrorForm] = useState<any>(null);

  async function handleGetAll(): Promise<void> {
    setLoading({
      isOpen: true,
      title: 'Carregando...',
    });
    const response: any = await new CategoryServices().getAll();
    setData(response.data);
    setLoading({ ...loading, isOpen: false });
  }

  async function handleRemove(id: number): Promise<void> {
    await toast.promise(
      async () => {
        const response: any = await new CategoryServices().delete(id);

        if (response.data === 'error_exist_subcategory') {
          throw new Error('error_exist_subcategory');
        }

        await handleGetAll();
      },
      {
        pending: 'Processando',
        success: `Categoria removida com sucesso!`,
        error: 'Esta categoria possui corte vinculado!',
      }
    );
  }

  async function onSubmit(): Promise<void> {
    try {
      setErrorForm(null);

      let errors = null;
      if (!!item.title === false) {
        errors = { title: true };
      }

      if (!!item.key === false) {
        errors = { ...errors, key: true };
      }

      if (errors) {
        setErrorForm(errors);
        toast.warn('Campo(s) obrigatório(s)!');
        return;
      }

      setLoadingSave(true);

      await toast.promise(
        async () => {
          if (item.id) {
            await new CategoryServices().update(item.id, {
              title: item.title,
              key: item.key.id ? item.key.id : item.key,
              active: item.active,
            });
          } else {
            await new CategoryServices().create({
              title: item.title,
              key: item.key.id ? item.key.id : item.key,
              active: item.active,
            });
          }
        },
        {
          pending: 'Processando',
          success: `Categoria ${
            item.id ? 'atualizada' : 'criada'
          } com sucesso!`,
          error: 'Tente novamente mais tarde :(',
        }
      );
    } catch (e: any) {
      console.log(e);
    } finally {
      setLoadingSave(false);
      setIsOpenForm(false);
      await handleGetAll();
    }
  }

  useEffect(() => {
    handleGetAll();
  }, []);

  useEffect(() => {
    if (!isOpenForm) {
      setItem({
        id: '',
        title: '',
        key: '',
        active: false,
      });
    }
  }, [isOpenForm]);

  return (
    <Layout
      legend={{
        value: data.length,
        text: 'Categorias',
      }}
    >
      {isOpenForm && (
        <Modal
          title={`${item.id ? 'Atualizar' : 'Nova'} categoria`}
          setIsOpen={setIsOpenForm}
          isOpen={isOpenForm}
        >
          <form id="form" autoComplete="off">
            <div className="flex flex-col mt-4 relative">
              <span className="font-light text-xs">Ícone</span>
              <SelectCustom
                placeholder="Qual o ícone?"
                data={keysCategory}
                borderColor="#ddd"
                defaultValue={
                  !item?.key
                    ? { id: '', title: 'Qual o ícone?' }
                    : keysCategory.filter((i) => i.id === item.key)[0]
                }
                noOptionsMessage="Não encontrado"
                marginTop={4}
                onChange={(e: any) =>
                  setItem({
                    ...item,
                    key: e,
                  })
                }
              />
              <span
                className={classNames(
                  'absolute text-red text-[0.60rem] left-1 bottom-[-15px]',
                  {
                    hidden: !errorForm?.key,
                  }
                )}
              >
                {errorForm?.key ? 'Obrigatório' : ''}
              </span>
            </div>
            <div className="flex flex-col mt-4 gap-1 relative">
              <span className="font-light text-xs">Título</span>
              <input
                className="border-solid border-[0.5px] border-[#aaa] p-3 rounded-md text-xs w-full focus:border-primary focus:border"
                placeholder="Ex: CARLETTO R S SILVA"
                onChange={(e) => setItem({ ...item, title: e.target.value })}
                value={item.title}
              />
              <span
                className={classNames(
                  'absolute text-red text-[0.60rem] left-1 bottom-[-15px]',
                  {
                    hidden: !errorForm?.title,
                  }
                )}
              >
                {errorForm?.title ? 'Obrigatório' : ''}
              </span>
            </div>

            <div className="mt-6 flex items-center gap-2">
              <Switch
                state={item.active}
                onChange={() => setItem({ ...item, active: !item.active })}
                handleDiameter={22}
                width={47}
                height={26}
              />
              <span className="font-light text-sm">
                {item.active ? 'Ativo' : 'Descontinuado'}
              </span>
            </div>
            <div className="flex gap-3 mt-7 justify-end">
              <button
                type="button"
                form="form"
                disabled={loadingSave}
                onClick={onSubmit}
                className={classNames(
                  'relative rounded-md bg-primary p-2 pl-6 pr-6 font-Inter font-bold text-xs w-36',
                  {
                    'bg-opacity-20 text-gray-400 cursor-not-allowed hover:bg-opacity-20':
                      loadingSave,
                  }
                )}
              >
                <span>Salvar</span>
                <ReactLoading
                  type="spokes"
                  color="#DC7E19"
                  width={15}
                  className={classNames('absolute right-3 top-2', {
                    hidden: !loadingSave,
                  })}
                />
              </button>
            </div>
          </form>
        </Modal>
      )}

      <div className="w-full bg-white rounded-lg shadow-xs sm:min-h-[500px] 2xl:min-h-[700px] h-fit">
        <div className="flex flex-row justify-between items-center mb-8 relative">
          <div className="flex flex-row items-center gap-2">
            <BiCategoryAlt size={22} color="#DC7E19" />
            <span className="font-bold text-lg">Categorias</span>
          </div>

          <button
            type="button"
            onClick={() => {
              setIsOpenForm(true);
            }}
            className="flex items-center gap-1 border-primary text-primary border-solid border-[1px] px-3 py-[0.4rem] rounded-md text-xs transition hover:text-black-light hover:bg-primary"
          >
            <AiOutlinePlus />
            <span>adicionar</span>
          </button>
        </div>
        <table className="table-auto w-full">
          <thead className="border-solid border-b-2 text-xs sm:text-sm bg-primary-light/25">
            <tr>
              <th className="p-1 text-left rounded-tl-sm">Título</th>
              <th className="p-1 text-left rounded-tl-sm">Ícone</th>
              <th className="p-1 rounded-tl-sm text-left">Status</th>
              <th className="p-1 text-center">&nbsp;</th>
              <th className="p-1 text-center rounded-tr-sm">&nbsp;</th>
            </tr>
          </thead>
          <tbody className="font-light text-xs sm:text-sm ">
            {data.map((cat: any) => (
              <tr
                key={cat.id}
                className={classNames('', {
                  'text-black-regular': !cat.active,
                })}
              >
                <td className="p-1 text-left">{cat.title}</td>
                <td className="p-1 text-left">{cat.key}</td>
                <td className="p-1 text-left">
                  {cat.active ? 'Ativo' : 'Inativo'}
                </td>
                <td className="p-1 text-left">
                  <FaEdit
                    className="cursor-pointer"
                    color="#DC7E19"
                    size={16}
                    onClick={() => {
                      setItem({ ...cat });
                      setIsOpenForm(true);
                    }}
                  />
                </td>
                <td className="p-1 text-left">
                  <RiDeleteBin6Line
                    className="cursor-pointer"
                    color="#B0B0B0"
                    size={16}
                    onClick={() =>
                      setAlert({
                        title: 'Atenção!',
                        description: 'Confirma a exclusão desta categora?',
                        isOpen: true,
                        button: [
                          {
                            title: 'Não',
                            type: 'black-regular',
                            onClick: () =>
                              setAlert({ ...alert, isOpen: false }),
                          },
                          {
                            title: 'Sim, confirmar',
                            type: 'red',
                            onClick: () => {
                              handleRemove(cat.id);
                              setAlert({ ...alert, isOpen: false });
                            },
                          },
                        ],
                      })
                    }
                  />
                </td>
              </tr>
            ))}
            {!loading.isOpen && data.length === 0 && (
              <tr>
                <td className="p-1 text-left">Nenhuma categoria cadastrada.</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </Layout>
  );
}
