import moment from 'moment';

class Date {
  isValid(date: string): boolean {
    const validateDate = moment(date, 'DD/MM/YYYY');
    const isValid = validateDate?.isValid();
    const isBefore = validateDate.isBefore();
    return isValid && isBefore;
  }
}

export default new Date();
