import { GiPresent } from 'react-icons/gi';
import { VscChecklist } from 'react-icons/vsc';
import { useContext, useEffect, useState } from 'react';
import ReactLoading from 'react-loading';
import { motion } from 'framer-motion';
import Footer from '../../../components/Footer';
import Copyright from '../../../components/Copyright';
import Header from '../../../components/Header';
import { UserServices } from '../../../services';
import SessionContext from '../../../contexts/session.context';
import maskHelper from '../../../helpers/mask.helper';

export default function IndicatedPage() {
  const [copy, setCopy] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { user } = useContext(SessionContext);

  useEffect(() => {
    (async () => {
      const response: any = await new UserServices().getInvitions();
      setData(response.data);
      setLoading(false);
    })();
  }, []);

  return (
    <div className="z-0 h-screen relative flex justify-center">
      <div className="bg-white relative w-full h-min-screen h-fit">
        <Header
          isDivideMarginTop
          title="Nossos produtos"
          description="As melhores peças para você."
          isApresentation={false}
        />

        <motion.section
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="flex flex-col justify-center items-center gap-2 px-0 lg:px-56 bg-background h-fit lg:h-[90%] p-0 sm:p-20"
        >
          <div className="flex flex-col w-[100%] sm:w-[900px] bg-white rounded-lg shadow-xs p-7 h-fit">
            <div className="flex flex-row items-center gap-2 mb-6">
              <GiPresent size={22} color="#DC7E19" />
              <span className="font-bold text-lg">Convite</span>
            </div>

            <div className="flex flex-col sm:flex-row justify-between items-center">
              <div className="flex flex-col items-center">
                <span className="font-bold text-md w-full">
                  Link para indicação:
                </span>
                <span className="font-light text-md">
                  {`${window.location.origin}/invite/${user.identify}`}
                </span>
              </div>

              <button
                type="button"
                onClick={() => {
                  navigator.clipboard.writeText(
                    `${window.location.origin}/invite/${user.identify}`
                  );
                  setCopy(true);
                }}
                className={`rounded-md border mt-6 sm:mt-0 ${
                  copy
                    ? 'border-green text-green hover:bg-green hover:text-white'
                    : 'border-primary text-primary hover:bg-primary hover:text-black'
                }  border-solid  p-2 pl-6 pr-6 font-Inter font-bold text-xs `}
              >
                {copy ? 'Link copiado' : 'Copiar link'}
              </button>
            </div>
          </div>
          <div className="w-[100%] sm:w-[900px] sm:min-h-[400px] 2xl:min-h-[600px] bg-white rounded-lg shadow-xs p-7 h-fit">
            <div className="flex flex-row items-center gap-2 mb-4 relative">
              <VscChecklist size={22} color="#DC7E19" />
              <span className="font-bold text-lg">Convidados</span>
              {loading && (
                <ReactLoading
                  type="spokes"
                  color="#DC7E19"
                  width={17}
                  className="absolute top-[0.35rem] left-36"
                />
              )}
            </div>
            <table className="table-auto w-full">
              <thead className="border-solid border-b-2 text-xs sm:text-sm">
                <tr>
                  <th className="p-1 rounded-tl-sm text-left">Nome</th>
                  <th className="p-1 text-center">Cadastro</th>
                  <th className="p-1 text-left rounded-tr-sm">Status</th>
                </tr>
              </thead>
              <tbody className="font-light text-xs sm:text-sm ">
                {data.map((item: any) => (
                  <tr key={item.id}>
                    <td className="p-1 text-left">{item.name}</td>
                    <td className="p-1 text-center">
                      {maskHelper.formatDateDMY(item.created_at)}
                    </td>
                    <td className="p-1 text-left">
                      {item.subscription.length > 0 ? 'Ativo' : 'Inativo'}
                    </td>
                  </tr>
                ))}
                {!loading && data.length === 0 && (
                  <tr>
                    <td className="p-1 text-left">
                      Nenhum convidado cadastrado(a)
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </motion.section>

        <Footer />
        <Copyright />
      </div>
    </div>
  );
}
