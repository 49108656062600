import HttpClient from '../helpers/httpClient.helper';
import { AuthorizationToken } from './common/authorization.token';

class CupomServices extends AuthorizationToken {
  private api;

  constructor() {
    super();
    this.api = new HttpClient(String(process.env.REACT_APP_API), this.token);
  }

  async create(data: any): Promise<void> {
    return await this.api.post('cupom', data);
  }

  async update(id: number, data: any): Promise<void> {
    return await this.api.put(`cupom/${id}`, data);
  }

  async delete(id: number): Promise<void> {
    return await this.api.delete(`cupom/${id}`);
  }

  async isValid(cupom: string): Promise<void> {
    return await this.api.post('cupom/valid', {
      cupom,
    });
  }

  async getAll(): Promise<void> {
    return await this.api.get('cupom');
  }
}

export default CupomServices;
