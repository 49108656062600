import React from 'react';

import depoyment1 from '../../../assets/images/depoyment01.jpeg';
import depoyment2 from '../../../assets/images/depoyment02.jpeg';
import depoyment3 from '../../../assets/images/depoyment03.jpeg';
import depoyment4 from '../../../assets/images/depoyment04.jpeg';

const Depositions: React.FC = () => (
  <>
    <div className="flex items-center justify-between w-full bg-background relative">
      <div className="bg-primary h-[0.5px] w-[13%] sm:w-[27%] md:w-[32%] lg:w-[35%] xl:w-[37%] 2xl:w-[40%]" />
      <p className="font-bold text-2xl sm:text-2xl text-center">
        Nossa melhor propaganda
      </p>
      <div className="bg-primary h-[0.5px] w-[13%] sm:w-[27%] md:w-[32%] lg:w-[35%] xl:w-[37%] 2xl:w-[40%]" />
    </div>
    <p className="font-bold text-2xl sm:text-2xl text-center bg-background">
      é quem já faz parte!
    </p>
    <div className="bg-background flex flex-col sm:flex-row items-center sm:items-start justify-center gap-10 sm:py-12 py-10">
      <div className="w-[16rem] sm:w-[12rem]">
        <div className="bg-white rounded-b-xl shadow-xl">
          <img src={depoyment1} alt="Carletto" loading="lazy" />
          <p className="text-sm font-semibold mt-2 px-2">Erlaine Ferreira</p>
          <p className="text-[0.7rem] mt-1 px-2 pb-2">
            Financeiro & Churrasqueira nos finais de semana.
          </p>
        </div>
        <p className="p-1 mt-4 text-xs">
          Acho cômodo receber em casa 🙏🏻, preço muito acessível, carnes de
          qualidade muito boa .
        </p>
      </div>
      <div className="w-[16rem] sm:w-[12rem]">
        <div className="bg-white rounded-b-xl shadow-xl">
          <img src={depoyment2} alt="Carletto" loading="lazy" />
          <p className="text-sm font-semibold mt-2 px-2">Rafael Lisboa</p>
          <p className="text-[0.7rem] mt-1 px-2 pb-2">
            Contador & Churrasqueiro nos finais de semana.
          </p>
        </div>
        <p className="p-1 mt-4 text-xs">
          Otima seleção de cortes, entrega antes do combinado, achei a embalagem
          muito pratica e segura tambem.
        </p>
      </div>
      <div className="w-[16rem] sm:w-[12rem]">
        <div className="bg-white rounded-b-xl shadow-xl">
          <img src={depoyment3} alt="Carletto" loading="lazy" />
          <p className="text-sm font-semibold mt-2 px-2">Thiago Caires</p>
          <p className="text-[0.7rem] mt-1 px-2 pb-2">
            Consultor & Churrasqueiro nos finais de semana.
          </p>
        </div>
        <p className="p-1 mt-4 text-xs">
          É uma excelente experiência, preço e qualidade. Na primeira compra já
          paguei minha assinatura. Vale muito a pena ser sócio.
        </p>
      </div>
      <div className="w-[16rem] sm:w-[12rem]">
        <div className="bg-white rounded-b-xl shadow-xl">
          <img
            src={depoyment4}
            alt="Carletto"
            loading="lazy"
            className="contain"
            style={{ width: 260, height: 193, objectFit: 'fill' }}
          />
          <p className="text-sm font-semibold mt-2 px-2">Jader Laódice</p>
          <p className="text-[0.7rem] mt-1 px-2 pb-2">
            Administrador & Churrasqueiro nos finais de semana.
          </p>
        </div>
        <p className="p-1 mt-4 text-xs">
          As carnes são de excelente qualidade, e o preço é melhor ainda, sem
          falar na entrega que foi super rápida. Simplesmente sensacional, uma
          das melhores escolhas que fiz.
        </p>
      </div>
    </div>
  </>
);

export default Depositions;
