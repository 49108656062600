import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { HiArrowSmRight } from 'react-icons/hi';
import Modal from '../../../components/Modal';

import logoAlternative from '../../../assets/images/logo_alternative.png';
import restaurant from '../../../assets/images/restaurant.png';
import currencyExchange from '../../../assets/images/currency_exchange.png';
import sell from '../../../assets/images/sell.png';
import Step1 from '../../../assets/images/register_step1.png';
import Step2 from '../../../assets/images/register_step2.png';
import Step3 from '../../../assets/images/register_step3.png';
import Step4 from '../../../assets/images/register_step4.png';
import maskHelper from '../../../helpers/mask.helper';

type HowItWorksProps = {
  subscriptionValue: number;
  userIdentify: {
    identify: string;
    name: string;
  } | null;
  isOpenModal: boolean;
  /* eslint-disable no-unused-vars */
  setIsOpenModal: (e: boolean) => void;
};

const HowItWorks: React.FC<HowItWorksProps> = ({
  userIdentify,
  subscriptionValue,
  isOpenModal,
  setIsOpenModal,
}) => {
  const navigate = useNavigate();

  return (
    <>
      {isOpenModal && (
        <Modal title="" setIsOpen={setIsOpenModal} isOpen={isOpenModal}>
          <div className="flex flex-col items-center justify-center w-full">
            <img
              src={logoAlternative}
              alt="Carletto"
              loading="lazy"
              className="w-64"
            />

            <p className="text-center my-9 text-[17px]">
              <strong>{userIdentify?.name}</strong> acabou de te convidar para
              participar do <strong>Carletto Meat Club, </strong>carnes nobres
              acompanhadas de
              <br className="hidden sm:inline-block" />
              muitos descontos!
            </p>
            <button
              onClick={() => setIsOpenModal(false)}
              type="button"
              className="rounded-md bg-primary p-3 mt-2 lg:mt-0 lg:pl-8 lg:pr-8 font-Inter font-bold text-md text-black w-full"
            >
              Continuar
            </button>
          </div>
        </Modal>
      )}
      <div className="pb-10 bg-background" id="benefits">
        <div className="flex items-center justify-between w-full py-4 ">
          <div className="bg-primary h-[0.5px] w-[39%]" />
          <p className="font-bold text-2xl text-center">
            Entenda como funciona
          </p>
          <div className="bg-primary h-[0.5px] w-[39%]" />
        </div>
        <p className="font-light text-center mb-10 mt-2">
          <span className="font-bold">No Carletto,</span> você compra as{' '}
          <span className="font-bold">melhores marcas</span> de carnes do
          mercado a <span className="font-bold">preço de fornecedor.</span>{' '}
          <br />
          Um Clube de compras que vc aproveita o nosso volume de compras.
        </p>
        <div className="mx:0 sm:mx-60 sm:my-16">
          <div className="flex flex-col gap-10 sm:gap-0 sm:flex-row justify-between relative">
            <div className="flex items-center flex-col z-10">
              <img src={Step1} alt="Carletto" loading="lazy" className="w-32" />
              <span className="font-bold flex items-center justify-center bg-primary rounded-full w-7 h-7 my-2">
                1
              </span>
              <strong className="text-xl sm:text-lg">Você assina!</strong>
              <p className="w-[300px] sm:w-[150px] text-center">
                {maskHelper.formatMoeda(subscriptionValue)} mensais (menos de R$
                0,90 por dia)
              </p>
            </div>
            <div className="flex items-center flex-col z-10">
              <img
                src={Step2}
                alt="Carletto"
                loading="lazy"
                className="w-[9rem]"
              />
              <span className="font-bold flex items-center justify-center bg-primary rounded-full w-7 h-7 my-3">
                2
              </span>
              <strong className="text-xl sm:text-lg">Curadoria</strong>
              <p className="w-[300px] sm:w-[150px] text-center">
                Nossa equipe escolhe as melhores carnes do mercado, direto com o
                fornecedor.
              </p>
            </div>
            <div className="flex items-center flex-col z-10">
              <img
                src={Step3}
                alt="Carletto"
                loading="lazy"
                className="w-[6rem]"
              />
              <span className="font-bold flex items-center justify-center bg-primary rounded-full w-7 h-7 my-3">
                3
              </span>
              <strong className="text-xl sm:text-lg">Comodidade</strong>
              <p className="w-[300px] sm:w-[150px] text-center">
                Só falar onde que levamos até vc.
              </p>
            </div>
            <div className="flex items-center flex-col z-10">
              <img
                src={Step4}
                alt="Carletto"
                loading="lazy"
                className="w-[5.5rem]"
              />
              <span className="font-bold flex items-center justify-center bg-primary rounded-full w-7 h-7 my-3">
                4
              </span>
              <strong className="text-xl sm:text-lg">Aproveite!</strong>
              <p className="w-[300px] sm:w-[150px] text-center">
                Chama a turma e aproveite aquele churrasco com carnes de
                primeira.
              </p>
            </div>

            <div className="hidden sm:block absolute h-2 w-[85%] 2xl:w-[90%] bottom-[10.5rem] left-16 bg-primary z-0" />
          </div>
        </div>
        <div className="w-full text-center mt-7 sm:my-4">
          <button
            onClick={() =>
              navigate(
                userIdentify?.identify
                  ? `/register/${userIdentify?.identify}`
                  : '/register'
              )
            }
            type="button"
            className="rounded-md bg-primary p-3 mt-2 lg:mt-0 lg:pl-8 lg:pr-8 font-Inter font-bold text-md text-black"
          >
            Faça parte agora mesmo!
          </button>
        </div>
      </div>

      <div className="flex items-center justify-between w-full bg-background relative">
        <div className="bg-primary h-[0.5px] w-[13%] sm:w-[27%] md:w-[32%] lg:w-[35%] xl:w-[37%] 2xl:w-[40%]" />
        <p className="font-bold text-xl sm:text-2xl text-center">
          Como sócio Carletto, você
        </p>
        <div className="bg-primary h-[0.5px] w-[13%] sm:w-[27%] md:w-[32%] lg:w-[35%] xl:w-[37%] 2xl:w-[40%]" />
      </div>
      <p className="font-bold text-xl sm:text-2xl text-center bg-background pb-7 sm:pb-0">
        garante benefícios exclusivos
      </p>
      <div className="px:0 pb-10 sm:px-80 sm:py-14 bg-background">
        <div className="flex flex-col items-center gap-10 sm:gap-0 sm:flex-row justify-between relative">
          <div className="flex items-center flex-col z-10">
            <img src={sell} alt="Carletto" loading="lazy" />
            <p className="w-[300px] sm:w-[150px] text-center mt-4">
              Muito desconto % no valor final do pedido
            </p>
          </div>
          <HiArrowSmRight size={50} className="hidden sm:inline-block" />
          <div className="flex items-center flex-col z-10">
            <img src={currencyExchange} alt="Carletto" loading="lazy" />
            <p className="w-[300px] sm:w-[150px] text-center mt-4">
              Bônus com suas indicações
            </p>
          </div>
          <HiArrowSmRight size={50} className="hidden sm:inline-block" />
          <div className="flex items-center flex-col z-10">
            <img src={restaurant} alt="Carletto" loading="lazy" />
            <p className="w-[300px] sm:w-[150px] text-center mt-4">
              Prêmios exclusivos como cliente vip
            </p>
          </div>
        </div>
      </div>

      <div className="bg-background px-2 sm:px-32 pb-16">
        <div className="bg-primary rounded-md flex flex-col sm:flex-row justify-between items-center w-full p-5 px-5 sm:px-32 shadow-xl">
          <div>
            <strong className="text-xl sm:text-2xl">Não fique de fora!</strong>
            <p className="text-md sm:text-[1rem] w-11/12 sm:w-full mt-1">
              Assine agora e obtenha os melhores cortes a preço de custo.
            </p>
          </div>
          <Link
            to="/register"
            className="w-full mt-7 sm:mt-0 sm:w-auto rounded-md text-center bg-white p-2 px-2 text-sm sm:px-8 sm:text-md font-Inter font-bold  text-black"
          >
            Quero fazer parte!
          </Link>
        </div>
      </div>
    </>
  );
};

export default HowItWorks;
