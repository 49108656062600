import React, { createContext, useMemo } from 'react';
import Alert from '../components/Alert';
import useAlert from './hooks/useAlert';

type AlertProps = {
  title: string;
  description: string;
  button: {
    title: string;
    type?: 'primary' | 'red' | 'black-regular' | 'green';
    onClick?: () => void;
  }[];
  isOpen: boolean;
};

interface AlertData {
  alert: AlertProps;
  /* eslint-disable no-unused-vars */
  setAlert: (e: AlertProps) => void;
}

const AlertContext = createContext<AlertData>({} as AlertData);

interface AlertProviderProps {
  children: React.ReactNode;
}

export const AlertProvider: React.FC<AlertProviderProps> = ({ children }) => {
  const { alert, setAlert } = useAlert();

  const value = useMemo(
    () => ({
      alert,
      setAlert,
    }),
    [alert, setAlert]
  );

  return (
    <AlertContext.Provider value={value}>
      {alert.isOpen && <Alert />}

      {children}
    </AlertContext.Provider>
  );
};

export default AlertContext;
