import { BrowserRouter } from 'react-router-dom';
import moment from 'moment';
import { ToastContainer } from 'react-toastify';
import AppRoutes from './routes';
import ScrollToTop from './hooks/ScrollToTopPage';
import DisableReactDevTools from './hooks/DisableReactDevTools';
import { SessionProvider } from './contexts/session.context';
import 'moment/locale/pt-br';
import 'react-toastify/dist/ReactToastify.css';
import { PurshaseProvider } from './contexts/purshase.context';
import { AlertProvider } from './contexts/alert.context';
import { LoadingProvider } from './contexts/loading.context';

moment().locale('pt-br');

function App() {
  return (
    <AlertProvider>
      <LoadingProvider>
        <SessionProvider>
          <PurshaseProvider>
            <BrowserRouter>
              <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />
              <ScrollToTop />
              <DisableReactDevTools />

              <AppRoutes />
            </BrowserRouter>
          </PurshaseProvider>
        </SessionProvider>
      </LoadingProvider>
    </AlertProvider>
  );
}

export default App;
