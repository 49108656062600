import HttpClient from '../helpers/httpClient.helper';
import { AuthorizationToken } from './common/authorization.token';

class CategoryServices extends AuthorizationToken {
  private api;

  constructor() {
    super();
    this.api = new HttpClient(String(process.env.REACT_APP_API), this.token);
  }

  async create(data: any): Promise<void> {
    return await this.api.post('category', data);
  }

  async update(id: number, data: any): Promise<void> {
    return await this.api.put(`category/${id}`, data);
  }

  async delete(id: number): Promise<void> {
    return await this.api.delete(`category/${id}`);
  }

  async getAll(): Promise<void> {
    return await this.api.get('category');
  }

  async getActive(): Promise<void> {
    return await this.api.get('category/active');
  }
}

export default CategoryServices;
