import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { AiFillStar, AiOutlineShoppingCart } from 'react-icons/ai';
import { BsChevronLeft } from 'react-icons/bs';
import classNames from 'classnames';
import SelectCustom from '../../../components/Select';
import maskHelper from '../../../helpers/mask.helper';
import PurshaseContext from '../../../contexts/purshase.context';

type CardDetailsProductProps = {
  isMobile?: boolean;
  selectedItem?: any;
  brand?: any;
  loading?: boolean;
  /* eslint-disable no-unused-vars */
  setSelectedItem?: (e: number | null) => void;
  setBrand?: (e: any) => void;
};

const CardDetailsProduct: React.FC<CardDetailsProductProps> = ({
  isMobile = false,
  selectedItem,
  brand = null,
  loading = false,
  setSelectedItem = () => {},
  setBrand = () => {},
}) => {
  const { addPurshase, getExistProductPurshase, addQtdPurshase, purshase } =
    useContext(PurshaseContext);
  const [weight, setWeight] = useState<any>(null);
  const [qtys, setQtys] = useState([]);
  const [qty, setQty] = useState<any>(null);

  const handlQty = useCallback(() => {
    const qtyArr: any = [];

    if (weight?.qty) {
      const unit = selectedItem.unit === 'Kg' ? ' peça' : ' Un';
      // console.log('purshase', purshase);
      // console.log('selectedItem', selectedItem);
      let subStock = 0;
      if (purshase) {
        purshase.items.forEach((item: any) => {
          if (
            item.product_id === selectedItem.productSelected.id &&
            item.product_weight_range_id === weight.id
          ) {
            subStock = item.qty;
          }
        });
      }

      const stockTotal = Number(weight?.qty) - subStock;
      if (stockTotal > 0) {
        [...Array(stockTotal)].forEach((item: any, i: number) => {
          qtyArr.push({
            id: i + 1,
            title:
              i +
              1 +
              (i > 0 && selectedItem.unit === 'Kg' ? ` ${unit}s` : unit),
          });
        });
      }
    }

    setQtys(qtyArr);
  }, [weight]);

  function handleAddProduct(data: any) {
    setBrand(null);
    setWeight(null);
    setQty(null);

    const product = getExistProductPurshase(
      data.productId,
      data.productWeightRangeJson.id
    );

    if (product) {
      addQtdPurshase(data.productId, data.productWeightRangeJson.id, data.qty);
      return;
    }

    addPurshase(
      data.productId,
      data.productWeightRangeId,
      data.qty,
      data.discount,
      data.priceByKg,
      data.productJson,
      data.productWeightRangeJson
    );
  }

  useEffect(() => {
    setQtys([]);
    setQty({ id: 1, title: '1 peça' });
    handlQty();
  }, [weight]);

  useEffect(() => {
    setWeight(null);
    setQty({ id: 1, title: '1 peça' });

    if (selectedItem?.productSelected?.category_items?.unit === 'Un') {
      setWeight(selectedItem?.productSelected?.product_weight_range[0]);
    }
  }, [brand, selectedItem]);

  return (
    <section>
      {!isMobile ? (
        <div className="hidden lg:flex flex-row min-h-[350px] w-[770px] 2xl:w-[800px] bg-white rounded-lg shadow-md p-8">
          <div className="flex flex-col w-[1000px] h-full">
            <p className="mb-2 text-xl font-bold">{selectedItem?.subtitle}</p>
            <p className="mb-2 text-sm font-light mr-3">
              {selectedItem?.description}
            </p>
            <div className="flex flex-row gap-1 mt-2">
              {[...Array(5)].map(() => (
                <AiFillStar key={Math.random()} color="#DC7E19" />
              ))}
            </div>
            <p className="text-xs font-light mt-1">5 avaliações</p>
            <div className="flex flex-row gap-3 mt-4 w-full">
              <SelectCustom
                placeholder="Qual a marca?"
                data={selectedItem?.brands}
                defaultValue={
                  !brand ? { id: 0, title: 'Escolha a Marca' } : brand
                }
                noOptionsMessage="Não encontrado"
                onChange={setBrand}
              />
              {brand &&
                selectedItem?.productSelected?.category_items?.unit ===
                  'Kg' && (
                  <SelectCustom
                    placeholder="Escolha o peso..."
                    data={selectedItem?.productSelected?.product_weight_range.filter(
                      (item: any) => item.qty > 0
                    )}
                    defaultValue={
                      !weight ? { id: 0, title: 'Escolha o peso' } : weight
                    }
                    noOptionsMessage="Não encontrado"
                    onChange={setWeight}
                  />
                )}

              {brand && weight && qtys.length > 0 && (
                <SelectCustom
                  placeholder="Qtd"
                  data={qtys}
                  defaultValue={qty}
                  noOptionsMessage="Não encontrado"
                  onChange={setQty}
                />
              )}
            </div>
            {!loading && brand && (
              <>
                <p className="text-[#868686] text-sm line-through mt-6">
                  {maskHelper.formatMoeda(
                    !weight
                      ? selectedItem?.productSelected?.price_by_kg
                      : (Number(selectedItem?.productSelected?.price_by_kg) *
                          Number(weight.weight_end.replace('.', ''))) /
                          1000
                  )}
                  {!weight
                    ? `/${selectedItem?.productSelected?.category_items.unit}`
                    : selectedItem?.productSelected?.category_items.unit ===
                      'Kg'
                    ? ' a peça'
                    : `/${selectedItem?.productSelected?.category_items.unit}`}
                </p>
                <p className="font-bold text-2xl">
                  {maskHelper.formatMoeda(
                    !weight
                      ? Number(selectedItem?.productSelected?.price_by_kg) -
                          Number(selectedItem?.productSelected?.discount)
                      : ((Number(selectedItem?.productSelected?.price_by_kg) -
                          Number(selectedItem?.productSelected?.discount)) *
                          Number(weight.weight_end.replace('.', ''))) /
                          1000
                  )}
                  <span className="font-light text-base">
                    {!weight
                      ? `/${selectedItem?.productSelected?.category_items.unit}`
                      : selectedItem?.productSelected?.category_items.unit ===
                        'Kg'
                      ? ' a peça'
                      : `/${selectedItem?.productSelected?.category_items.unit}`}
                  </span>
                </p>
                <button
                  type="button"
                  disabled={!weight || qtys.length === 0}
                  onClick={() =>
                    handleAddProduct({
                      productId: selectedItem.productSelected.id,
                      productWeightRangeId: weight.id,
                      qty: qty.id,
                      discount: Number(selectedItem.productSelected.discount),
                      priceByKg: Number(
                        selectedItem.productSelected.price_by_kg
                      ),
                      productJson: selectedItem.productSelected,
                      productWeightRangeJson: weight,
                    })
                  }
                  className={classNames(
                    'cursor-pointer flex flex-row items-center justify-center text-sm gap-2 p-[10.2px] pl-4 pr-4 mt-3 bg-primary w-52 rounded-md',
                    {
                      'cursor-not-allowed opacity-20':
                        !weight || qtys.length === 0,
                    }
                  )}
                >
                  <AiOutlineShoppingCart size={17} />
                  <span className="font-bold text-sm">
                    {selectedItem?.productSelected?.category_items.unit ===
                      'Kg' &&
                    brand &&
                    weight &&
                    qtys.length === 0
                      ? 'Estoque esgotado!'
                      : selectedItem?.productSelected?.category_items.unit ===
                          'Un' &&
                        brand &&
                        qtys.length === 0
                      ? 'Estoque esgotado!'
                      : 'Adicionar ao Carrinho'}
                  </span>
                </button>
              </>
            )}
          </div>

          <div className="w-30% h-full">
            <img
              alt="Carletto"
              src={`${process.env.REACT_APP_API}/${
                selectedItem?.productSelected?.path
                  ? selectedItem?.productSelected?.path
                  : selectedItem?.path
              }`}
              loading="lazy"
              className="w-[800px] h-[360px] object-cover rounded-md"
            />
          </div>
        </div>
      ) : (
        <div className="w-full bg-white flex flex-col lg:hidden p-5 mt-4 rounded-lg">
          <button
            type="button"
            onClick={() => setSelectedItem(null)}
            className="flex items-center gap-2 mb-8"
          >
            <BsChevronLeft size={20} />
            <span className="font-light text-xl">Voltar</span>
          </button>
          <p className="mb-2 text-xl font-bold">{selectedItem?.subtitle}</p>
          <p className="mb-2 text-sm font-light">{selectedItem?.description}</p>
          <div className="flex flex-row gap-1 mt-2">
            {[...Array(5)].map(() => (
              <AiFillStar key={Math.random()} color="#DC7E19" />
            ))}
          </div>
          <p className="text-xs font-light mt-1 mb-6">(5 avaliações)</p>
          <img
            alt="Carletto"
            src={`${process.env.REACT_APP_API}/${
              selectedItem?.productSelected?.path
                ? selectedItem?.productSelected?.path
                : selectedItem?.path
            }`}
            loading="lazy"
            className="w-full  object-contain rounded-lg mb-4"
          />
          <SelectCustom
            placeholder="Qual a marca?"
            data={selectedItem?.brands}
            defaultValue={!brand ? { id: 0, title: 'Escolha a Marca' } : brand}
            noOptionsMessage="Não encontrado"
            onChange={setBrand}
          />

          {brand &&
            selectedItem?.productSelected?.category_items?.unit === 'Kg' && (
              <SelectCustom
                placeholder="Escolha o peso..."
                data={selectedItem?.productSelected?.product_weight_range.filter(
                  (item: any) => item.qty > 0
                )}
                defaultValue={
                  !weight ? { id: 0, title: 'Escolha o peso' } : weight
                }
                noOptionsMessage="Não encontrado"
                onChange={setWeight}
              />
            )}

          {brand && weight && qtys.length > 0 && (
            <SelectCustom
              placeholder="Qtd"
              data={qtys}
              defaultValue={qty}
              noOptionsMessage="Não encontrado"
              onChange={setQty}
            />
          )}

          {!loading && brand && (
            <>
              <p className="text-[#868686] text-sm line-through mt-6">
                {maskHelper.formatMoeda(
                  !weight
                    ? selectedItem?.productSelected?.price_by_kg
                    : (Number(selectedItem?.productSelected?.price_by_kg) *
                        Number(weight.weight_end.replace('.', ''))) /
                        1000
                )}
                <span className="font-light text-base">
                  {!weight
                    ? `/${selectedItem?.productSelected?.category_items.unit}`
                    : selectedItem?.productSelected?.category_items.unit ===
                      'Kg'
                    ? ' a peça'
                    : `/${selectedItem?.productSelected?.category_items.unit}`}
                </span>
              </p>
              <p className="font-bold text-2xl">
                {maskHelper.formatMoeda(
                  !weight
                    ? Number(selectedItem?.productSelected?.price_by_kg) -
                        Number(selectedItem?.productSelected?.discount)
                    : ((Number(selectedItem?.productSelected?.price_by_kg) -
                        Number(selectedItem?.productSelected?.discount)) *
                        Number(weight.weight_end.replace('.', ''))) /
                        1000
                )}
                <span className="font-light text-base">
                  {!weight
                    ? `/${selectedItem?.productSelected?.category_items.unit}`
                    : selectedItem?.productSelected?.category_items.unit ===
                      'Kg'
                    ? ' a peça'
                    : `/${selectedItem?.productSelected?.category_items.unit}`}
                </span>
              </p>
              <button
                type="button"
                disabled={!weight || qtys.length === 0}
                onClick={() =>
                  handleAddProduct({
                    productId: selectedItem.productSelected.id,
                    productWeightRangeId: weight.id,
                    qty: qty.id,
                    discount: Number(selectedItem.productSelected.discount),
                    priceByKg: Number(selectedItem.productSelected.price_by_kg),
                    productJson: selectedItem.productSelected,
                    productWeightRangeJson: weight,
                  })
                }
                className={classNames(
                  'cursor-pointer flex flex-row items-center justify-center text-sm gap-2 p-[10.2px] pl-4 pr-4 mt-3 bg-primary w-52 rounded-md',
                  {
                    'cursor-not-allowed opacity-20':
                      !weight || qtys.length === 0,
                  }
                )}
              >
                <AiOutlineShoppingCart size={17} />
                <span className="font-bold text-sm">
                  {selectedItem?.productSelected?.category_items.unit ===
                    'Kg' &&
                  brand &&
                  weight &&
                  qtys.length === 0
                    ? 'Estoque esgotado!'
                    : selectedItem?.productSelected?.category_items.unit ===
                        'Un' &&
                      brand &&
                      qtys.length === 0
                    ? 'Estoque esgotado!'
                    : 'Adicionar ao Carrinho'}
                </span>
              </button>
            </>
          )}
        </div>
      )}
    </section>
  );
};

export default memo(CardDetailsProduct);
