import { AiFillInfoCircle } from 'react-icons/ai';
import QRCode from 'react-qr-code';
import { motion } from 'framer-motion';

import { useLocation, useNavigate } from 'react-router-dom';

import { ReactSVG } from 'react-svg';
import { useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import Footer from '../../../components/Footer';
import Copyright from '../../../components/Copyright';
import Header from '../../../components/Header';
import pix from '../../../assets/svg/pix.svg';
import { PurshaseServices } from '../../../services';
import AlertContext from '../../../contexts/alert.context';

export default function PixPage() {
  const [copy, setCopy] = useState(false);
  const { alert, setAlert } = useContext(AlertContext);
  const params: any = useLocation();
  const navigate = useNavigate();

  async function handleConfirm() {
    try {
      const response: any = await new PurshaseServices().getStatusByIdAsaas(
        params.state.purshase.payment_id
      );

      if (
        response.data.payment_status === 'RECEIVED' ||
        response.data.payment_status === 'CONFIRMED'
      ) {
        setAlert({
          title: 'Parábens, pedido concluído!',
          description:
            'Estamos finalizando a conferência do pagamento e logo enviaremos seu pedido :)',
          isOpen: true,
          button: [
            {
              title: 'Ok, entendi!',
              type: 'green',
              onClick: () => {
                setAlert({ ...alert, isOpen: false });
                navigate('/products');
              },
            },
          ],
        });
      }
    } catch (e: any) {
      console.log('error', e);
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      handleConfirm();
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="z-0 h-screen relative flex justify-center">
      <div className="bg-white relative w-full h-screen">
        <Header
          isDivideMarginTop
          title="Nossos produtos"
          description="As melhores peças para você."
          isApresentation={false}
        />

        <motion.section
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="flex justify-center items-center px-0 lg:px-56 bg-background h-fit p-0 sm:p-20"
        >
          <div className="w-[100%] sm:w-[550px] bg-white rounded-md shadow-lg p-7 h-fit">
            <div className="flex items-center justify-center mb-6 gap-2">
              <ReactSVG src={pix} className="cursor-pointer" />
              <span className="font-bold text-xs">Pagamento com PIX</span>
            </div>

            <div className="mt-6">
              <p className="font-bold text-sm text-left">
                Pague pelo seu app de pagamento preferido ou pelo Internat
                Banking.
              </p>
              <p className="font-light text-xs text-left">
                Você pode escanear este código QR com o celular:
              </p>
              <div className="flex justify-center mt-8 mb-8">
                <div className="p-2 border-primary border-solid border-[1px] rounded-md">
                  <QRCode
                    size={125}
                    value={params.state?.payment.pix.payload}
                  />
                </div>
              </div>

              <p className="font-light text-xs mb-2">
                Ou copiar e colar o código abaixo:
              </p>
              <input
                className="border-solid border-[0.5px] border-[#aaa] p-3 rounded-md text-xs text-[#aaa] w-full"
                placeholder="Digite o endereço para entrega..."
                disabled
                value={params.state?.payment.pix.payload}
              />
              <div className="flex items-start gap-2 mt-6 p-3 text-xs bg-primary bg-opacity-10 rounded-md border-solid border-[1px] border-primary mb-4">
                <AiFillInfoCircle size={20} color="#DC7E19" />
                <p className="font-light">
                  O pagamento deve ser feito dentro dos próximos 30 minutos
                  <br />
                  para que seja aprovado.
                </p>
              </div>
            </div>

            <div className="flex flex-col-reverse justify-end sm:flex-row gap-3 mt-7">
              <button
                type="button"
                onClick={() => {
                  navigator.clipboard.writeText(
                    params.state?.payment.pix.payload
                  );
                  setCopy(true);
                }}
                className={classNames(
                  'rounded-md bg-primary p-2 pl-6 pr-6 font-Inter font-bold text-xs text-black',
                  {
                    'bg-white border-solid border-[1px] border-green text-green hover:bg-green hover:text-white':
                      copy,
                  }
                )}
              >
                {copy ? 'Código copiado' : 'Copiar código'}
              </button>
            </div>
          </div>
        </motion.section>

        <Footer />
        <Copyright />
      </div>
    </div>
  );
}
