import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { getItem, removeItem, setItem } from '../../helpers/storage.helper';
import SessionContext from '../session.context';

type ItemPurshaseTypes = {
  product_id: number;
  product_weight_range_id: number;
  qty: number;
  discount: number;
  subtotal: number;
  total: number;
  product_json: any;
  product_weight_range_json: any;
};

export type PurshaseTypes = {
  qty: number;
  delivery: number;
  discount: number;
  cupom_value: number;
  subtotal: number;
  total: number;
  items: ItemPurshaseTypes[];
  createdAt?: string;
};

export default function usePurshase() {
  const { isAuthenticated, user } = useContext(SessionContext);
  const [purshase, setPurshase] = useState<PurshaseTypes | null>(null);

  function getPurshase(): PurshaseTypes {
    const data = getItem('purshase', true);
    setPurshase(data);
    return data;
  }

  function calculeDiscount(discount: number, weightEnd: number, qty: number) {
    return (discount * weightEnd * qty) / 1000;
  }

  function calculeSubtotal(priceByKg: number, weightEnd: number, qty: number) {
    return (priceByKg * weightEnd * qty) / 1000;
  }

  function calculeTotal(
    priceByKg: number,
    discount: number,
    weightEnd: number,
    qty: number,
    delivery: number
  ) {
    return ((priceByKg - discount) * weightEnd * qty) / 1000 + delivery;
  }

  function calculeResume(storagePurshase: PurshaseTypes) {
    let qtyP = 0;
    let discountP = 0;
    let subtotalP = 0;
    let totalP = 0;

    storagePurshase.items.forEach((item: ItemPurshaseTypes) => {
      qtyP += Number(item.qty);
      discountP += Number(item.discount);
      subtotalP += Number(item.subtotal);
      totalP += Number(item.total);
    });

    setItem(
      'purshase',
      {
        ...storagePurshase,
        qty: qtyP,
        discount: discountP,
        subtotal: subtotalP,
        total: totalP + storagePurshase.delivery,
        items: storagePurshase.items,
      },
      true
    );
  }

  function addCupomPurshase(cupom: any) {
    const storagePurshase = getPurshase();

    setItem(
      'purshase',
      {
        ...storagePurshase,
        cupom_value: Number(cupom.value),
        cupom_json: cupom,
        discount: Number(storagePurshase.discount) + Number(cupom.value),
        total: storagePurshase.total - Number(cupom.value),
      },
      true
    );

    toast.success('Cupom de desconto aplicado ao pedido!');
    getPurshase();
  }

  function addPurshase(
    productId: number,
    productWeightRangeId: number,
    qty: number,
    discount: number,
    priceByKg: number,
    productJson: any,
    productWeightRangeJson: any
  ): void {
    const delivery = isAuthenticated ? Number(user.delivery_value) : 0;

    const weightEnd = Number(
      productWeightRangeJson.weight_end.replace('.', '')
    );

    const storagePurshase = getPurshase();

    if (storagePurshase) {
      storagePurshase.items.push({
        product_id: productId,
        product_weight_range_id: productWeightRangeId,
        qty,
        discount: calculeDiscount(discount, weightEnd, qty),
        subtotal: calculeSubtotal(priceByKg, weightEnd, qty),
        total: calculeTotal(priceByKg, discount, weightEnd, qty, 0),
        product_json: productJson,
        product_weight_range_json: productWeightRangeJson,
      });

      calculeResume(storagePurshase);
    } else {
      setItem(
        'purshase',
        {
          qty,
          delivery,
          discount: calculeDiscount(discount, weightEnd, qty),
          cupom_value: 0,
          cupom_json: {},
          subtotal: calculeSubtotal(priceByKg, weightEnd, qty),
          total: calculeTotal(priceByKg, discount, weightEnd, qty, delivery),
          createdAt: new Date(),
          items: [
            {
              product_id: productId,
              product_weight_range_id: productWeightRangeId,
              qty,
              discount: calculeDiscount(discount, weightEnd, qty),
              subtotal: calculeSubtotal(priceByKg, weightEnd, qty),
              total: calculeTotal(priceByKg, discount, weightEnd, qty, 0),
              product_json: productJson,
              product_weight_range_json: productWeightRangeJson,
            },
          ],
        },
        true
      );
    }

    toast.success('Produto adicionado ao carrinho!');
    getPurshase();
  }

  function delAllPurshase(): void {
    removeItem('purshase');
    getPurshase();
  }

  function delPurshase(productWeightId: number): void {
    if (purshase?.items.length === 1) {
      delAllPurshase();
      return;
    }

    const storagePurshaseItems = purshase?.items.filter(
      (item) => item.product_weight_range_id !== productWeightId
    );

    setItem(
      'purshase',
      {
        ...purshase,
        items: storagePurshaseItems,
      },
      true
    );

    const storagePurshase = getPurshase();
    calculeResume(storagePurshase);
    getPurshase();
  }

  function addQtdPurshase(
    productId: number,
    productWeightId: number,
    qtyP = 1
  ): void {
    const storagePurshase = getPurshase();
    const storagePurshaseCustom: PurshaseTypes = storagePurshase;

    storagePurshase.items.forEach((item: ItemPurshaseTypes, i: number) => {
      if (
        item.product_id === productId &&
        item.product_weight_range_id === productWeightId
      ) {
        const qty = item.qty + qtyP;
        const discount = Number(item.product_json.discount);
        const priceByKg = Number(item.product_json.price_by_kg);
        const weightEnd = Number(
          item.product_weight_range_json.weight_end.replace('.', '')
        );

        storagePurshaseCustom.items[i] = {
          ...item,
          qty,
          discount: calculeDiscount(discount, weightEnd, qty),
          subtotal: calculeSubtotal(priceByKg, weightEnd, qty),
          total: calculeTotal(priceByKg, discount, weightEnd, qty, 0),
          product_json: item.product_json,
        };
      }
    });

    toast.success('Quantidade adicionada ao produto!');
    calculeResume(storagePurshase);
    getPurshase();
  }

  function delQtdPurshase(
    productId: number,
    productWeightId: number,
    qtyP = 1,
    msg = true
  ): void {
    const storagePurshase = getPurshase();
    const storagePurshaseCustom: PurshaseTypes = storagePurshase;

    storagePurshase.items.forEach((item: ItemPurshaseTypes, i: number) => {
      if (
        item.product_id === productId &&
        item.product_weight_range_id === productWeightId
      ) {
        const qty = item.qty - qtyP;
        const discount = Number(item.product_json.discount);
        const priceByKg = Number(item.product_json.price_by_kg);
        const weightEnd = Number(
          item.product_weight_range_json.weight_end.replace('.', '')
        );

        storagePurshaseCustom.items[i] = {
          ...item,
          qty,
          discount: calculeDiscount(discount, weightEnd, qty),
          subtotal: calculeSubtotal(priceByKg, weightEnd, qty),
          total: calculeTotal(priceByKg, discount, weightEnd, qty, 0),
          product_json: item.product_json,
        };
      }
    });

    if (msg) toast.success('Quantidade alterada do produto!');
    calculeResume(storagePurshase);
    getPurshase();
  }

  function getExistProductPurshase(
    productId: number,
    productWeightRangeId: number
  ): boolean {
    const itemPurshase = getItem('purshase', true);

    if (!itemPurshase) return false;

    return itemPurshase.items.some(
      (item: ItemPurshaseTypes) =>
        item.product_id === productId &&
        item.product_weight_range_id === productWeightRangeId
    );
  }

  useEffect(() => {
    getPurshase();

    setInterval(() => {
      const data = getPurshase();

      if (data) {
        const dateNow = moment().format('YYYY-MM-DD');
        const createdAt = moment(data.createdAt?.substring(0, 10)).format(
          'YYYY-MM-DD'
        );

        if (dateNow > createdAt) {
          delAllPurshase();
          setPurshase(null);
        }
      }
    }, 60000);
  }, []);

  return {
    purshase,
    addPurshase,
    delPurshase,
    addQtdPurshase,
    delQtdPurshase,
    delAllPurshase,
    getPurshase,
    getExistProductPurshase,
    addCupomPurshase,
  };
}
