import { useState } from 'react';

type LoadingProps = {
  title?: string;
  isOpen: boolean;
};

export default function useLoading() {
  const [loading, setLoading] = useState<LoadingProps>({
    title: 'Carregando',
    isOpen: false,
  });

  return {
    loading,
    setLoading,
  };
}
