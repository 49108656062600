import React, { Fragment, useContext } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import classNames from 'classnames';
import AlertContext from '../contexts/alert.context';

const Alert: React.FC = () => {
  const { alert } = useContext(AlertContext);

  return (
    <Transition appear show={alert.isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  {alert.title}
                </Dialog.Title>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">{alert.description}</p>
                </div>

                <div className="mt-10 flex">
                  {alert.button.map((item) => (
                    <button
                      key={Math.random()}
                      type="button"
                      className={classNames(
                        'mr-2 inline-flex justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium',
                        {
                          'bg-primary font-Inter font-bold text-black hover:bg-blue-200':
                            item.type === 'primary',
                          'bg-red text-white hover:bg-red/70':
                            item.type === 'red',
                          'bg-[#ddd] text-gray-500 hover:bg-blue-200':
                            item.type === 'black-regular',
                          'bg-green text-white hover:bg-blue-200':
                            item.type === 'green',
                        }
                      )}
                      onClick={item.onClick}
                    >
                      {item.title}
                    </button>
                  ))}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default Alert;
