import { useNavigate, Link, useParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import { AiFillStar } from 'react-icons/ai';
import { toast } from 'react-toastify';
import bannerProducts from '../../../assets/images/banner_products.png';
import AboutUS from '../components/AboutUS';
import Footer from '../../../components/Footer';
import Copyright from '../../../components/Copyright';
import Header from '../../../components/Header';
import maskHelper from '../../../helpers/mask.helper';
import HowItWorks from '../components/HowItWorks';
import Depositions from '../components/Depositions';
import PromotionalVideo from '../components/PromotionalVideo';
import PromotionalBannerSmall from '../components/PromotionalBannerSmall';
import { SessionServices } from '../../../services';
import Slider from '../components/Slider';
import { removeItem, setItem } from '../../../helpers/storage.helper';

type UserProps = {
  identify: string;
  name: string;
};

export default function HomePage() {
  const navigate = useNavigate();
  const [userIdentify, setUserIdentify] = useState<UserProps | null>(null);
  const [subscriptionValue, setSubscriptionValue] = useState(0);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const { identify } = useParams();
  const [products, setProducts] = useState<any[]>([]);
  const ancorRef = useRef<HTMLDivElement>(null);
  const ancorHelpRef = useRef<HTMLDivElement>(null);

  const slideLeft = () => {
    const x: any = document.getElementById('slider');
    x.scrollLeft -= window.screen.width;
  };

  const slideRight = () => {
    const x: any = document.getElementById('slider');
    x.scrollLeft += window.screen.width;
  };

  useEffect(() => {
    if (identify) {
      toast.promise(
        async () => {
          const response: any = await new SessionServices().validIdentify(
            String(identify)
          );

          removeItem('identify');

          if (response.data) {
            setIsOpenModal(true);
            setUserIdentify({
              identify: response.data?.identify,
              name: response.data?.name,
            });

            setItem('identify', response.data?.identify);
          } else {
            toast.warn('Convite inválido, tente outro!');
            navigate('/');
          }
        },
        {
          pending: 'Verificando o link',
          error: 'Ops! Ocorreu um erro inesperado :(',
        }
      );
    }

    (async () => {
      const responseProducts: any =
        await new SessionServices().getProductsPrime();
      setProducts(responseProducts.data);

      const responseSubs: any = await new SessionServices().getCompany();
      setSubscriptionValue(Number(responseSubs.data.subscription_value));
    })();

    if (window.location.href.includes('#')) {
      if (window.location.href.includes('#help')) {
        ancorHelpRef?.current?.scrollIntoView();
      } else {
        ancorRef?.current?.scrollIntoView();
      }
    }
  }, []);

  return (
    <div className="z-0 h-screen relative flex justify-center">
      <div className="bg-white relative w-full h-screen">
        <Header isApresentation={false} />
        <Slider />

        <section className="flex">
          <div className="flex flex-row justify-between items-center w-full  mt-4 sm:mt-0 2xl:mt-3 p-5 px-5 sm:px-32 shadow-xl">
            <div>
              <strong className="text-md sm:text-2xl">
                Não fique de fora!
              </strong>
              <p className="text-xs sm:text-[1rem] w-11/12 sm:w-full mt-1">
                Assine agora e obtenha os melhores cortes a preço de custo.
              </p>
            </div>
            <Link
              to="/register"
              className="w-32 sm:w-auto rounded-md text-center bg-primary p-2 px-2 text-xs sm:text-sm sm:px-8 sm:text-md font-Inter font-bold  text-black"
            >
              Assinar agora
            </Link>
          </div>
        </section>

        <div className="flex items-center justify-between w-full pt-3 sm:pt-7 bg-background mt-5 relative">
          <div className="bg-primary h-[0.5px] w-[24%] sm:w-[31%] md:w-[36%] lg:w-[39%] xl:w-[41%]" />
          <p className="font-bold text-2xl sm:text-2xl text-center">
            Produtos Prime
          </p>
          <div className="bg-primary h-[0.5px] w-[24%] sm:w-[31%] md:w-[36%] lg:w-[39%] xl:w-[41%]" />
        </div>
        <p className="font-light text-center bg-background pb-2 sm:pb-0">
          Os cortes mais desejados
        </p>

        <div className="relative flex items-center px-5 lg:px-16 py-5 sm:py-10 bg-background">
          <MdChevronLeft
            size={70}
            color="#DC7E19"
            onClick={() => slideLeft()}
            className="opacity-60 cursor-pointer hover:opacity-100 hover:scale-125 ease-in-out duration-300 hidden sm:inline-block"
          />
          <div
            id="slider"
            className="w-full h-full overflow-x-scroll scroll whitespace-nowrap scroll-smooth no-scrollbar pb-4"
          >
            {products.map((item: any) => (
              <div
                key={Math.random()}
                className="inline-block mr-4 sm:hover:scale-105 sm:ease-in-out sm:duration-300"
              >
                <div className="flex flex-col items-center justify-center bg-white rounded-md p-4 shadow-lg">
                  <img
                    alt="Carletto"
                    src={`${process.env.REACT_APP_API}/${item.path}`}
                    loading="lazy"
                    className="w-[200px] h-[200px] lg:w-[200px] rounded-lg object-cover"
                  />
                  <p className="font-bold text-md text-center mt-3">
                    {item.category_items.subtitle}
                  </p>
                  <div className="flex flex-row gap-1 mt-2">
                    {[...Array(item.stars)].map(() => (
                      <AiFillStar key={Math.random()} color="#DC7E19" />
                    ))}
                  </div>
                  <p className="text-[#868686] text-sm mt-3 text-left mb-[-4px]">
                    a partir de
                  </p>
                  <p className="font-bold text-2xl">
                    {maskHelper.formatMoeda(
                      item.category_items.unit === 'Kg'
                        ? Number(item.price_by_kg) - Number(item.discount)
                        : (Number(item.price_by_kg) - Number(item.discount)) *
                            Number(item.product_weight_range[0].weight_end)
                    )}
                    <span className="font-light text-base">
                      /{item.category_items.unit}
                    </span>
                  </p>
                  <button
                    type="button"
                    onClick={() => {
                      navigate(`/products/${item.id}`);
                    }}
                    className="flex flex-row items-center justify-center text-sm gap-2 p-[10.2px] pl-4 pr-4 mt-3 bg-primary w-52 rounded-md"
                  >
                    <span className="font-bold text-sm">Ver produto</span>
                  </button>
                </div>
              </div>
            ))}
          </div>

          <MdChevronRight
            size={70}
            color="#DC7E19"
            onClick={() => slideRight()}
            className="opacity-60 cursor-pointer hover:opacity-100 hover:scale-125 ease-in-out duration-300 hidden sm:inline-block"
          />
        </div>

        <PromotionalBannerSmall />
        <PromotionalVideo />
        <div ref={ancorRef} />
        <Depositions />

        <HowItWorks
          userIdentify={userIdentify}
          subscriptionValue={subscriptionValue}
          setIsOpenModal={setIsOpenModal}
          isOpenModal={isOpenModal}
        />

        <img
          src={bannerProducts}
          alt="Carletto"
          loading="lazy"
          className="w-full bg-background"
        />

        <AboutUS />
        <Footer />
        <Copyright />
        <div ref={ancorHelpRef} id="help" />
      </div>
    </div>
  );
}
