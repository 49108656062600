import { BsTruck } from 'react-icons/bs';
import { AiOutlineUser } from 'react-icons/ai';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import ReactLoading from 'react-loading';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import { motion } from 'framer-motion';
import Footer from '../../../components/Footer';
import Copyright from '../../../components/Copyright';
import Header from '../../../components/Header';
import { PostalCodeServices, UserServices } from '../../../services';
import phoneValidate from '../../../helpers/validations/phone.validate';
import dateValidate from '../../../helpers/validations/date.validate';
import cpfValidation from '../../../helpers/validations/cpf.validation';
import yup from '../../../config/yup';
import maskHelper from '../../../helpers/mask.helper';
import useGoogleApi from '../../../hooks/useGoogleApi';
import { getItem, setItem } from '../../../helpers/storage.helper';
import usePurshase from '../../../contexts/hooks/usePurshase';

interface IForm {
  name: string;
  phone: string;
  email: string;
  birth: string;
  document: string;
  postal_code: string;
  address: string;
  number: string;
  complement?: string;
  province: string;
  city: string;
  state: string;
}

export default function AccountPage() {
  const [loading, setLoading] = useState(true);
  const [loadingPostalCode, setLoadingPostalCode] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const { calculeDistanceDelivery, calculeDelivery } = useGoogleApi();
  const { getPurshase } = usePurshase();

  const schema = yup
    .object({
      name: yup.string().required(),
      email: yup.string().email().required(),
      phone: yup
        .string()
        .length(15, 'Obrigatório 11 números')
        .required()
        .test('valid-phone', 'Whatsapp inválido', (value) =>
          phoneValidate.isValid(String(value).replace(/\D/g, ''))
        ),
      birth: yup
        .string()
        .length(10, 'Obrigatório 10 caracteres')
        .required()
        .test('valid-birth', 'Aniversário inválido', (value) =>
          dateValidate.isValid(String(value))
        ),
      document: yup
        .string()
        .length(14, 'Obrigatório 11 caracteres')
        .required()
        .test('valid-document', 'Aniversário inválido', (value) =>
          cpfValidation.isValid(String(value).replace(/\D/g, ''))
        ),
      postal_code: yup.string().length(9, 'Obrigatório 8 números').required(),
      address: yup.string().required(),
      number: yup.string().required(),
      complement: yup.string(),
      province: yup.string().required(),
      city: yup.string().required(),
      state: yup.string().length(2, '2 letras').required(),
    })
    .required();

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<IForm>({
    resolver: yupResolver(schema),
  });

  async function onSubmit(data: IForm): Promise<void> {
    try {
      let params = {
        name: data.name,
        email: data.email,
        phone: data.phone.replace(/\D/g, ''),
        birth: maskHelper.formatDateYMD(data.birth),
        document: data.document.replace(/\D/g, ''),
        postal_code: data.postal_code.replace(/\D/g, ''),
        address: data.address,
        number: data.number,
        complement: data.complement,
        province: data.province,
        city: data.city,
        state: data.state,
        delivery_km: '',
        delivery_value: 0,
      };

      setLoadingSave(true);

      const deliveryKm = await calculeDistanceDelivery(
        `${data.address}, ${data.number}, ${data.province}, ${data.city}`
      );

      if (deliveryKm === 0) {
        return;
      }

      const deliveryValue = calculeDelivery(deliveryKm);

      await toast.promise(
        async () => {
          params = {
            ...params,
            delivery_km: String(deliveryKm),
            delivery_value: deliveryValue,
          };

          await new UserServices().update(params);

          setItem('user', {
            ...params,
            delivery_km: String(deliveryKm),
            delivery_value: String(deliveryValue),
          });

          const purshase = getItem('purshase', true);

          if (purshase) {
            const total = Number(purshase.total) - Number(purshase.delivery);

            setItem('purshase', {
              ...purshase,
              delivery: deliveryValue,
              total: Number((total + deliveryValue).toFixed(2)),
            });

            getPurshase();
          }
        },
        {
          pending: 'Processando',
          success: 'Dados atualizados com sucesso!',
          error: 'Ops! Ocorreu um erro inesperado :(',
        }
      );
    } catch (e: any) {
      console.log(e);
    } finally {
      setLoadingSave(false);
    }
  }

  async function handlePostalCode(postalCode: string) {
    try {
      setLoadingPostalCode(true);
      const response: any = await new PostalCodeServices().get(postalCode);

      setValue('postal_code', maskHelper.cep(response.data.cep));
      setValue('address', response.data.logradouro);
      setValue('province', response.data.bairro);
      setValue('city', response.data.localidade);
      setValue('state', response.data.uf);
    } catch (e: any) {
      console.log(e);
    } finally {
      setLoadingPostalCode(false);
    }
  }

  useEffect(() => {
    (async () => {
      setLoading(true);
      const response: any = await new UserServices().getById();
      setLoading(false);

      setValue('name', response.data.name);
      setValue('email', response.data.email);
      setValue('phone', maskHelper.phone(response.data.phone));
      setValue('birth', maskHelper.formatDateDMY(response.data.birth));
      setValue('document', maskHelper.cpf(response.data.document));
      setValue(
        'postal_code',
        maskHelper.cep(String(response.data.postal_code))
      );
      setValue('address', response.data.address);
      setValue('number', response.data.number);
      setValue('complement', response.data.complement);
      setValue('province', response.data.province);
      setValue('city', response.data.city);
      setValue('state', response.data.state);
    })();
  }, []);

  return (
    <div className="z-0 h-screen relative flex justify-center">
      <div className="bg-white relative w-full h-screen">
        <Header
          isDivideMarginTop
          title="Nossos produtos"
          description="As melhores peças para você."
          isApresentation={false}
        />

        <motion.form
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          id="form"
          onSubmit={handleSubmit(onSubmit)}
          autoComplete="off"
          className="flex flex-col sm:flex-row justify-center items-center gap-2 px-0 lg:px-56 bg-background h-fit p-0 sm:p-20"
        >
          <div className="w-[100%] sm:w-[450px] sm:h-[540px] bg-white rounded-lg shadow-xs p-7 h-fit">
            <div className="flex items-center gap-2 mb-6 relative">
              <AiOutlineUser size={22} color="#DC7E19" />
              <p className="font-bold text-lg">Dados pessoais</p>
              {loading && (
                <ReactLoading
                  type="spokes"
                  color="#DC7E19"
                  width={17}
                  className="absolute top-[0.35rem] left-44"
                />
              )}
            </div>

            <div className="flex flex-col mt-4 gap-1 relative">
              <span className="font-light text-xs">Nome Completo</span>
              <input
                className="border-solid border-[0.5px] border-[#aaa] p-3 rounded-md text-xs w-full focus:border-primary focus:border"
                placeholder="Ex: CARLETTO R S SILVA"
                {...register('name')}
              />
              <span
                className={classNames(
                  'absolute text-red text-[0.60rem] left-1 bottom-[-15px]',
                  {
                    hidden: !!errors.name === false,
                  }
                )}
              >
                {errors.name?.message}
              </span>
            </div>
            <div className="flex flex-col mt-4 gap-1 relative">
              <span className="font-light text-xs">E-mail</span>
              <input
                {...register('email')}
                className="border-solid border-[0.5px] border-[#aaa] p-3 rounded-md text-xs w-full focus:border-primary focus:border"
                placeholder="Seu email"
              />
              <span
                className={classNames(
                  'absolute text-red text-[0.60rem] left-1 bottom-[-15px]',
                  {
                    hidden: !!errors.email === false,
                  }
                )}
              >
                {errors.email?.message}
              </span>
            </div>
            <div className="flex flex-col mt-4 gap-1 relative">
              <span className="font-light text-xs">Nascimento</span>
              <input
                {...register('birth')}
                value={maskHelper.date(String(watch('birth')))}
                className="border-solid border-[0.5px] border-[#aaa] p-3 rounded-md text-xs w-full focus:border-primary focus:border"
                placeholder="Seu número de Whatsapp"
              />
              <span
                className={classNames(
                  'absolute text-red text-[0.60rem] left-1 bottom-[-15px]',
                  {
                    hidden: !!errors.birth === false,
                  }
                )}
              >
                {errors.birth?.message}
              </span>
            </div>
            <div className="flex flex-col mt-4 gap-1 relative">
              <span className="font-light text-xs">Whatsapp</span>
              <input
                {...register('phone')}
                value={maskHelper.phone(String(watch('phone')))}
                className="border-solid border-[0.5px] border-[#aaa] p-3 rounded-md text-xs w-full focus:border-primary focus:border"
                placeholder="Seu número de Whatsapp"
              />
              <span
                className={classNames(
                  'absolute text-red text-[0.60rem] left-1 bottom-[-15px]',
                  {
                    hidden: !!errors.phone === false,
                  }
                )}
              >
                {errors.phone?.message}
              </span>
            </div>
            <div className="flex flex-col mt-4 gap-1 relative">
              <span className="font-light text-xs">CPF</span>
              <input
                {...register('document')}
                value={maskHelper.cpf(String(watch('document')))}
                className="border-solid border-[0.5px] border-[#aaa] p-3 rounded-md text-xs w-full focus:border-primary focus:border"
                placeholder="Informe seu CPF"
              />
              <span
                className={classNames(
                  'absolute text-red text-[0.60rem] left-1 bottom-[-15px]',
                  {
                    hidden: !!errors.document === false,
                  }
                )}
              >
                {errors.document?.message}
              </span>
            </div>
          </div>
          <div className="w-[100%] sm:w-[450px] bg-white rounded-lg shadow-xs p-7 h-fit">
            <div className="flex flex-row items-center gap-2 mb-6 relative">
              <BsTruck size={22} color="#DC7E19" />
              <p className="font-bold text-lg">Endereço de entrega</p>
              {loadingPostalCode && (
                <ReactLoading
                  type="spokes"
                  color="#DC7E19"
                  width={17}
                  className="absolute top-[0.35rem] left-[13.5rem]"
                />
              )}
            </div>

            <div className="flex flex-col mt-4 gap-1 relative">
              <span className="font-light text-xs">CEP</span>
              <input
                {...register('postal_code')}
                value={maskHelper.cep(String(watch('postal_code')))}
                onBlur={(e) => handlePostalCode(e.target.value)}
                className="border-solid border-[0.5px] border-[#aaa] p-3 rounded-md text-xs w-full focus:border-primary focus:border"
                placeholder="00000-000"
              />
              <span
                className={classNames(
                  'absolute text-red text-[0.60rem] left-1 bottom-[-15px]',
                  {
                    hidden: !!errors.postal_code === false,
                  }
                )}
              >
                {errors.postal_code?.message}
              </span>
            </div>
            <div className="flex flex-col mt-4 gap-1 relative">
              <span className="font-light text-xs">Logradouro</span>
              <input
                {...register('address')}
                className="border-solid border-[0.5px] border-[#aaa] p-3 rounded-md text-xs w-full focus:border-primary focus:border"
                placeholder="Sua rua..."
              />
              <span
                className={classNames(
                  'absolute text-red text-[0.60rem] left-1 bottom-[-15px]',
                  {
                    hidden: !!errors.address === false,
                  }
                )}
              >
                {errors.address?.message}
              </span>
            </div>
            <div className="flex justify-between gap-2 relative">
              <div className="flex flex-col mt-4 gap-1 w-full">
                <span className="font-light text-xs">Número</span>
                <input
                  {...register('number')}
                  className="border-solid border-[0.5px] border-[#aaa] p-3 rounded-md text-xs w-full focus:border-primary focus:border"
                  placeholder="999"
                />
                <span
                  className={classNames(
                    'absolute text-red text-[0.60rem] left-1 bottom-[-15px]',
                    {
                      hidden: !!errors.number === false,
                    }
                  )}
                >
                  {errors.number?.message}
                </span>
              </div>
              <div className="flex flex-col mt-4 gap-1 w-full">
                <span className="font-light text-xs">Complemento</span>
                <input
                  {...register('complement')}
                  className="border-solid border-[0.5px] border-[#aaa] p-3 rounded-md text-xs w-full focus:border-primary focus:border"
                  placeholder="Ex: casa 123"
                />
              </div>
            </div>

            <div className="flex flex-col mt-4 gap-1 relative">
              <span className="font-light text-xs">Cidade</span>
              <input
                {...register('province')}
                className="border-solid border-[0.5px] border-[#aaa] p-3 rounded-md text-xs w-full focus:border-primary focus:border"
                placeholder="Cidade"
              />
              <span
                className={classNames(
                  'absolute text-red text-[0.60rem] left-1 bottom-[-15px]',
                  {
                    hidden: !!errors.city === false,
                  }
                )}
              >
                {errors.city?.message}
              </span>
            </div>

            <div className="flex flex-col mt-4 gap-1 relative">
              <span className="font-light text-xs">Estado</span>
              <input
                {...register('city')}
                className="border-solid border-[0.5px] border-[#aaa] p-3 rounded-md text-xs w-full focus:border-primary focus:border"
                placeholder="Estado"
              />
              <span
                className={classNames(
                  'absolute text-red text-[0.60rem] left-1 bottom-[-15px]',
                  {
                    hidden: !!errors.state === false,
                  }
                )}
              >
                {errors.state?.message}
              </span>
            </div>
            <div className="flex gap-3 mt-7 justify-end">
              <button
                type="submit"
                form="form"
                disabled={loadingSave}
                className={classNames(
                  'relative rounded-md bg-primary p-2 pl-6 pr-6 font-Inter font-bold text-xs w-36',
                  {
                    'bg-opacity-20 text-gray-400 cursor-not-allowed hover:bg-opacity-20':
                      loadingSave,
                  }
                )}
              >
                <span>Salvar</span>
                <ReactLoading
                  type="spokes"
                  color="#DC7E19"
                  width={15}
                  className={classNames('absolute right-3 top-2', {
                    hidden: !loadingSave,
                  })}
                />
              </button>
            </div>
          </div>
        </motion.form>

        <Footer />
        <Copyright />
      </div>
    </div>
  );
}
