import React, { useContext, useState } from 'react';
import { ReactSVG } from 'react-svg';
import { Link } from 'react-router-dom';
import { AiOutlineMenu, AiOutlineShoppingCart } from 'react-icons/ai';
import { BiUser, BiUserCheck } from 'react-icons/bi';
import logo from '../assets/svg/logo.svg';

import ShoppingSummary from './ShoppingSummary';
import Drawer from './Drawer';
import MenuUser from './MenuUser';
import SessionContext from '../contexts/session.context';
import PurshaseContext from '../contexts/purshase.context';

type HeaderProps = {
  title?: string;
  description?: string;
  isDivideMarginTop?: boolean;
  isApresentation?: boolean;
  button?: {
    title: string;
    onClick: () => void;
  };
};

const Header: React.FC<HeaderProps> = ({
  title = '',
  description = '',
  isDivideMarginTop,
  isApresentation = true,
  button,
}) => {
  const [drawer, setDrawer] = useState(false);
  const [isShoppingSummary, setIsShoppingSummary] = useState(false);
  const [isMenuUser, setIsMenuUser] = useState(false);
  const pathname = window.location.pathname.substr(1);
  const { isAuthenticated } = useContext(SessionContext);
  const { purshase } = useContext(PurshaseContext);

  return (
    <>
      {drawer && <Drawer purshase={purshase} setDrawer={setDrawer} />}

      <header className="z-10 bg-[#0A0A0A] h-16 relative w-full flex justify-between items-center pl-2 pr-2 sm:pl-10 sm:pr-10 md:pl-20 md:pr-20 xl:pl-32 xl:pr-32 backdrop-blur-[15px]">
        <Link to="/" className="hidden lg:flex cursor-pointer">
          <ReactSVG src={logo} />
        </Link>

        <AiOutlineMenu
          size={25}
          color="#DC7E19"
          className="lg:hidden"
          onClick={() => setDrawer(true)}
        />
        <div className="hidden lg:flex items-center text-primary gap-8 text-sm">
          <Link
            to="/"
            className={`relative flex justify-between items-center cursor-pointer hover:text-white hover:underline ${
              pathname === '' ? 'text-white underline' : ''
            }`}
          >
            <span>Home</span>
          </Link>
          <Link
            to="/products"
            className={`relative flex justify-between items-center cursor-pointer hover:text-white hover:underline ${
              pathname === 'products' ? 'text-white underline' : ''
            }`}
          >
            <span>Nossos Produtos</span>
          </Link>

          {pathname === '' || pathname === 'home' ? (
            <a
              href="#benefits"
              className="relative flex justify-between items-center cursor-pointer hover:text-white hover:underline"
            >
              <span>Benefícios</span>
            </a>
          ) : (
            <Link
              to="/#benefits"
              className="relative flex justify-between items-center cursor-pointer hover:text-white hover:underline"
            >
              <span>Benefícios</span>
            </Link>
          )}

          {pathname === '' || pathname === 'home' ? (
            <a
              href="#benefits"
              className="relative flex justify-between items-center cursor-pointer hover:text-white hover:underline"
            >
              <span>Vantagens</span>
            </a>
          ) : (
            <Link
              to="/#benefits"
              className="relative flex justify-between items-center cursor-pointer hover:text-white hover:underline"
            >
              <span>Vantagens</span>
            </Link>
          )}

          {pathname === '' || pathname === 'home' ? (
            <a
              href="#help"
              className="relative flex justify-between items-center cursor-pointer hover:text-white hover:underline"
            >
              <span>Ajuda</span>
            </a>
          ) : (
            <Link
              to="/#help"
              className="relative flex justify-between items-center cursor-pointer hover:text-white hover:underline"
            >
              <span>Ajuda</span>
            </Link>
          )}

          <button
            type="button"
            disabled={!!purshase === false}
            onClick={() => {
              setIsMenuUser(false);
              setIsShoppingSummary(true);
            }}
            className="flex justify-between items-center cursor-pointer hover:text-white hover:underline relative text-white"
          >
            <AiOutlineShoppingCart
              size={18}
              color={pathname === 'shopping-summary' ? '#fff' : ''}
            />

            {purshase && (
              <span className="animate-bounce font-bold w-4 bg-primary text-black text-center text-xs rounded-full cursor-pointer absolute top-[-10px] right-[-10px]">
                {purshase.qty}
              </span>
            )}
          </button>

          {isShoppingSummary && (
            <ShoppingSummary setIsShoppingSummary={setIsShoppingSummary} />
          )}

          {isAuthenticated ? (
            <button
              type="button"
              onClick={() => {
                setIsMenuUser(true);
                setIsShoppingSummary(false);
              }}
              className={`flex justify-between items-center cursor-pointer hover:text-white hover:underline ${
                isMenuUser ? 'text-white underline' : ''
              }`}
            >
              <BiUserCheck size={23} />
            </button>
          ) : (
            <Link
              to="/login"
              className={`flex justify-between items-center cursor-pointer hover:text-white hover:underline ${
                pathname === 'login' ? 'text-white underline' : ''
              }`}
            >
              <BiUser size={18} />
            </Link>
          )}

          {isMenuUser && <MenuUser setIsMenuUser={setIsMenuUser} />}
        </div>

        <ReactSVG
          src={logo}
          className="cursor-pointer lg:hidden self-center ml-10"
        />

        <div className="flex gap-3 sm:gap-8 text-primary lg:hidden">
          <Link
            to="/shopping-summary"
            className="flex justify-between items-center cursor-pointer hover:text-white hover:underline relative text-white"
          >
            <AiOutlineShoppingCart size={25} />
            {purshase && (
              <span className="animate-bounce font-bold w-4 bg-primary text-black text-center text-xs rounded-full cursor-pointer absolute top-[-10px] right-[-10px]">
                {purshase.qty}
              </span>
            )}
          </Link>

          {isAuthenticated ? (
            <button
              type="button"
              onClick={() => setDrawer(true)}
              className="flex justify-between items-center cursor-pointer hover:text-white hover:underline"
            >
              <BiUserCheck size={30} />
            </button>
          ) : (
            <Link
              to="/login"
              className="flex justify-between items-center cursor-pointer hover:text-white hover:underline"
            >
              <BiUser size={25} />
            </Link>
          )}
        </div>

        {isApresentation && (
          <div
            className={`absolute left-[9%] text-4xl text-white font-Inter font-bold ${
              isDivideMarginTop
                ? 'top-[90px] sm:top-[90px] lg:top-[150px]'
                : 'top-[100px] sm:top-[140px] lg:top-[300px]'
            }`}
          >
            <p
              className={`text-3xl lg:text-5xl text-white font-Inter font-bold pr-5 w-full mb-6 ${
                isDivideMarginTop ? 'w-full mb-1' : 'lg:w-3/4'
              }`}
            >
              {title}
            </p>
            <p
              className={`text-xl lg:text-2xl 2xl:text-3xl text-white font-Inter font-light mb-6 ${
                isDivideMarginTop ? 'w-full' : 'w-2/3'
              }`}
            >
              {description}
            </p>
            <button
              type="button"
              onClick={() => button?.onClick()}
              className="rounded-md bg-primary p-2 pl-8 pr-8 font-Inter font-bold text-lg text-black"
            >
              {button?.title}
            </button>
          </div>
        )}
      </header>
    </>
  );
};

export default Header;
