import { Link, useNavigate } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import classNames from 'classnames';
import ReactLoading from 'react-loading';
import { AiOutlineUser } from 'react-icons/ai';
import { TbAddressBook } from 'react-icons/tb';
import { BsCreditCard2Back } from 'react-icons/bs';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { motion } from 'framer-motion';
import Footer from '../../../components/Footer';
import Copyright from '../../../components/Copyright';
import Header from '../../../components/Header';
import photoRegister from '../../../assets/images/photo_register.png';
import purshaseSecuriry from '../../../assets/images/purshase_security.png';
import yup from '../../../config/yup';
import { PostalCodeServices, SessionServices } from '../../../services';
import cpfValidation from '../../../helpers/validations/cpf.validation';
import phoneValidate from '../../../helpers/validations/phone.validate';
import dateValidate from '../../../helpers/validations/date.validate';
import SessionContext from '../../../contexts/session.context';
import maskHelper from '../../../helpers/mask.helper';
import AlertContext from '../../../contexts/alert.context';
import useGoogleApi from '../../../hooks/useGoogleApi';
import { getItem, removeItem } from '../../../helpers/storage.helper';

interface IFormOne {
  name: string;
  phone: string;
  email: string;
  birth: string;
  document: string;
  password: string;
  confirmPassword: string;
}

interface IFormTwo {
  postal_code: string;
  address: string;
  number: string;
  complement?: string;
  province: string;
  city: string;
  state: string;
}

interface IFormThree {
  holderName: string;
  number: string;
  expiry: string;
  cvv: string;
}

export default function RegisterPage() {
  const [step, setStep] = useState<number | null>(1);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [dataForm, setDataForm] = useState<any>(null);
  const [idParent, setIdParent] = useState<number | null>(null);
  const [subsValue, setSubsValue] = useState(0);
  const { signIn } = useContext(SessionContext);
  const { alert, setAlert } = useContext(AlertContext);
  const { calculeDistanceDelivery, calculeDelivery } = useGoogleApi();
  let rerender = false;

  const schemaStepOne = yup
    .object({
      name: yup.string().required(),
      email: yup.string().email().required(),
      phone: yup
        .string()
        .length(15, 'Obrigatório 11 números')
        .required()
        .test('valid-phone', 'Whatsapp inválido', (value) =>
          phoneValidate.isValid(String(value).replace(/\D/g, ''))
        ),
      birth: yup
        .string()
        .length(10, 'Obrigatório 10 caracteres')
        .required()
        .test('valid-birth', 'Aniversário inválido', (value) =>
          dateValidate.isValid(String(value))
        ),
      document: yup
        .string()
        .length(14, 'Obrigatório 11 caracteres')
        .required()
        .test('valid-document', 'CPF inválido', (value) =>
          cpfValidation.isValid(String(value).replace(/\D/g, ''))
        ),
      password: yup
        .string()
        .required()
        .min(4, 'Mínimo 4 caracteres')
        .oneOf([yup.ref('confirmPassword'), null], 'As senhas não são iguais!'),
      confirmPassword: yup
        .string()
        .required()
        .min(4, 'Mínimo 4 caracteres')
        .oneOf([yup.ref('password'), null], 'As senhas não são iguais!'),
    })
    .required();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors: errorsStepOne },
  } = useForm<IFormOne>({
    resolver: yupResolver(schemaStepOne),
  });

  async function onSubmitStepOne(data: IFormOne): Promise<void> {
    try {
      setLoading(true);

      const response: any = await new SessionServices().isAccount(
        data.phone.replace(/\D/g, ''),
        data.email,
        data.document.replace(/\D/g, '')
      );

      if (response.data) {
        setLoading(false);
        toast.warn('Whatsapp ou Email ou CPF já existe(m).');

        return;
      }

      setStep(2);
      setDataForm(data);
    } catch (e: any) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }

  const schemaStepTwo = yup
    .object({
      postal_code: yup.string().length(9, 'Obrigatório 8 números').required(),
      address: yup.string().required(),
      number: yup.string().required(),
      complement: yup.string(),
      province: yup.string().required(),
      city: yup.string().required(),
      state: yup.string().length(2, '2 letras').required(),
    })
    .required();

  const {
    register: registerTwo,
    handleSubmit: handleSubmitTwo,
    watch: watchTwo,
    setValue: setValueTwo,
    formState: { errors: errorsStepTwo },
  } = useForm<IFormTwo>({
    resolver: yupResolver(schemaStepTwo),
  });

  async function onSubmitStepTwo(data: IFormTwo): Promise<void> {
    try {
      setStep(3);
      setDataForm({
        ...dataForm,
        ...data,
      });
    } catch (e: any) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }

  async function handlePostalCode(postalCode: string) {
    try {
      setLoading(true);
      const response: any = await new PostalCodeServices().get(postalCode);

      setValueTwo('postal_code', maskHelper.cep(response.data.cep));
      setValueTwo('address', response.data.logradouro);
      setValueTwo('province', response.data.bairro);
      setValueTwo('city', response.data.localidade);
      setValueTwo('state', response.data.uf);
    } catch (e: any) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }

  const schemaStepThree = yup
    .object({
      holderName: yup.string().required(),
      number: yup.string().length(19, 'Obrigatório 16 dígitos').required(),
      expiry: yup
        .string()
        .length(7, 'Formato 00/0000')
        .required()
        .test('valid-expiry', 'Mês/Ano inválido', (value) => {
          const expiryMonth = Number(value?.substring(0, 2));
          const expiryYear = Number(value?.substring(3, 7));
          const date = new Date();
          const year = date.getFullYear();
          const month = new Date().getMonth() + 1;

          if (expiryMonth > 0 && expiryMonth <= 12 && expiryYear >= year) {
            if (year === expiryYear && month > expiryMonth) return false;

            return true;
          }

          return false;
        }),
      cvv: yup
        .string()
        .min(3, 'Mínimo 3 dígitos')
        .max(4, 'Máximo 4 dígitos')
        .required(),
    })
    .required();

  const {
    register: registerThree,
    handleSubmit: handleSubmitThree,
    watch: watchThree,
    formState: { errors: errorsStepThree },
  } = useForm<IFormThree>({
    resolver: yupResolver(schemaStepThree),
  });

  async function onSubmitStepThree(data: IFormThree): Promise<void> {
    try {
      let params = {
        name: dataForm.name,
        email: dataForm.email,
        user_id_parent: idParent,
        phone: dataForm.phone.replace(/\D/g, ''),
        birth: maskHelper.formatDateYMD(dataForm.birth),
        document: dataForm.document.replace(/\D/g, ''),
        password: dataForm.password,
        confirmPassword: dataForm.confirmPassword,
        postal_code: dataForm.postal_code.replace(/\D/g, ''),
        address: dataForm.address,
        number: dataForm.number,
        complement: dataForm.complement,
        province: dataForm.province,
        city: dataForm.city,
        state: dataForm.state,
        delivery_km: '',
        delivery_value: 0,
        creditCard: {
          holderName: data.holderName.toUpperCase(),
          number: data.number.replace(/\s/g, ''),
          expiryMonth: data.expiry.substring(0, 2),
          expiryYear: data.expiry.substring(3, 7),
          ccv: data.cvv,
        },
      };

      const deliveryKm = await calculeDistanceDelivery(
        `${dataForm.address}, ${dataForm.number}, ${dataForm.province}, ${dataForm.city}`
      );

      if (deliveryKm === 0) {
        return;
      }

      const deliveryValue = calculeDelivery(deliveryKm);

      params = {
        ...params,
        delivery_km: String(deliveryKm),
        delivery_value: deliveryValue,
      };

      setLoading(true);
      await new SessionServices().register(params);

      const responseAuth: any = await signIn(dataForm.email, dataForm.password);

      if (
        responseAuth === 'error_credential_invalid' ||
        responseAuth === 'error_not_exist'
      ) {
        toast.warn('Usuário não autenticado, confirme seu email e senha!');
        setTimeout(() => navigate('/login'), 2000);
        return;
      }

      removeItem('identify');

      setAlert({
        title: 'Parábens, assinatura concluída!',
        description: 'Aproveite e confira nossos produtos :)',
        isOpen: true,
        button: [
          {
            title: 'Ok, vamos lá!',
            type: 'green',
            onClick: () => {
              setAlert({ ...alert, isOpen: false });
              navigate('/products');
            },
          },
        ],
      });
    } catch (e: any) {
      if (e === 'account_exist') {
        toast.warn('Whatsapp ou Email ou CPF já existe(m).');
        return;
      }

      if (e?.errors) {
        toast.warn(e?.errors[0]?.description);
        return;
      }
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    (async () => {
      const response: any = await new SessionServices().getCompany();
      setSubsValue(Number(response.data.subscription_value));
    })();

    const identify = getItem('identify');

    if (!rerender && identify) {
      rerender = true;
      toast.promise(
        async () => {
          const response: any = await new SessionServices().validIdentify(
            String(identify)
          );

          if (!response.data) {
            removeItem('identify');
            toast.warn('Convite inválido, tente outro!');
            navigate('/home');
            return;
          }

          setIdParent(response?.data?.id);
        },
        {
          pending: 'Verificando o link',
          error: 'Ops! Ocorreu um erro inesperado :(',
        }
      );
    }
  }, [rerender]);

  return (
    <div className="containerScreen">
      <div className="main">
        <Header
          isDivideMarginTop
          title="Nossos produtos"
          description="As melhores peças para você."
          isApresentation={false}
        />

        <motion.section
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="flex flex-row justify-center px-0 py-0 sm:px-56 sm:py-5 md:py-10 lg:py-5 xl:pt-10 lg:pb-5 bg-background max-h-[90%] 2xl:h-full"
        >
          <div className="flex justify-center w-full">
            <div className="relative hidden xl:flex">
              <img
                alt="Carletto"
                src={photoRegister}
                loading="lazy"
                className="bottom-12 left-24"
                width="400"
              />
            </div>
            <div className="relative flex flex-col items-center justify-center bg-white w-full py-7 xl:py-0 min-w-[330px] sm:w-[390px] min-h-[500px] sm:h-[96.5%] shadow-lg rounded-r-lg">
              {step === 1 && (
                <form
                  id="form-step-one"
                  onSubmit={handleSubmit(onSubmitStepOne)}
                  autoComplete="off"
                  className="flex flex-col w-full items-center mt-3 sm:mt-0"
                >
                  <div className="flex flex-row gap-1 justify-center w-[70%] mb-3">
                    <AiOutlineUser size={22} color="#DC7E19" />
                    <MdKeyboardArrowRight size={22} color="#aaa" />
                    <TbAddressBook size={22} color="#aaa" />
                    <MdKeyboardArrowRight size={22} color="#aaa" />
                    <BsCreditCard2Back size={22} color="#aaa" />
                  </div>
                  <span className="w-[70%] text-sm font-light text-center mb-3">
                    Antes de continuar, é preciso
                    <br />
                    fazer seu cadastro.
                  </span>
                  <div className="flex flex-col gap-[1.20rem] w-[70%] mb-8">
                    <div className="relative">
                      <input
                        className={classNames(
                          'w-[100%] border-[1px] border-solid border-black-regular placeholder:text-black-regular p-[0.65rem] rounded-md text-xs focus:border-primary',
                          {
                            'border-red': !!errorsStepOne.name,
                          }
                        )}
                        placeholder="Nome Completo"
                        {...register('name')}
                      />
                      <span
                        className={classNames(
                          'absolute text-red text-[0.60rem] left-1 bottom-[-15px]',
                          {
                            hidden: !!errorsStepOne.name === false,
                          }
                        )}
                      >
                        {errorsStepOne.name?.message}
                      </span>
                    </div>

                    <div className="relative">
                      <input
                        type="email"
                        className={classNames(
                          'w-[100%] border-[1px] border-solid border-black-regular placeholder:text-black-regular p-[0.65rem] rounded-md text-xs focus:border-primary',
                          {
                            'border-red': !!errorsStepOne.email,
                          }
                        )}
                        placeholder="E-mail"
                        {...register('email')}
                      />
                      <span
                        className={classNames(
                          'absolute text-red text-[0.60rem] left-1 bottom-[-15px]',
                          {
                            hidden: !!errorsStepOne.email === false,
                          }
                        )}
                      >
                        {errorsStepOne.email?.message}
                      </span>
                    </div>
                    <div className="flex gap-2">
                      <div className="relative w-[47%]">
                        <input
                          className={classNames(
                            'w-[100%] border-[1px] border-solid border-black-regular placeholder:text-black-regular p-[0.65rem] rounded-md text-xs focus:border-primary',
                            {
                              'border-red': !!errorsStepOne.phone,
                            }
                          )}
                          placeholder="Whatsapp"
                          {...register('phone')}
                          value={maskHelper.phone(String(watch('phone')))}
                        />
                        <span
                          className={classNames(
                            'absolute text-red text-[0.60rem] left-1 bottom-[-15px]',
                            {
                              hidden: !!errorsStepOne.phone === false,
                            }
                          )}
                        >
                          {errorsStepOne.phone?.message}
                        </span>
                      </div>
                      <div className="relative w-[47%]">
                        <input
                          maxLength={10}
                          className={classNames(
                            'w-[100%] border-[1px] border-solid border-black-regular placeholder:text-black-regular p-[0.65rem] rounded-md text-xs focus:border-primary',
                            {
                              'border-red': !!errorsStepOne.birth,
                            }
                          )}
                          placeholder="Aniversário"
                          {...register('birth')}
                          value={maskHelper.date(String(watch('birth')))}
                        />
                        <span
                          className={classNames(
                            'absolute text-red text-[0.60rem] left-1 bottom-[-15px]',
                            {
                              hidden: !!errorsStepOne.birth === false,
                            }
                          )}
                        >
                          {errorsStepOne.birth?.message}
                        </span>
                      </div>
                    </div>
                    <div className="relative">
                      <input
                        className={classNames(
                          'w-[100%] border-[1px] border-solid border-black-regular placeholder:text-black-regular p-[0.65rem] rounded-md text-xs focus:border-primary',
                          {
                            'border-red': !!errorsStepOne.document,
                          }
                        )}
                        placeholder="CPF"
                        {...register('document')}
                        value={maskHelper.cpf(String(watch('document')))}
                      />
                      <span
                        className={classNames(
                          'absolute text-red text-[0.60rem] left-1 bottom-[-15px]',
                          {
                            hidden: !!errorsStepOne.document === false,
                          }
                        )}
                      >
                        {errorsStepOne.document?.message}
                      </span>
                    </div>
                    <div className="flex gap-2">
                      <div className="relative w-[47%]">
                        <input
                          type="password"
                          className={classNames(
                            'w-[100%] border-[1px] border-solid border-black-regular placeholder:text-black-regular p-[0.65rem] rounded-md text-xs focus:border-primary',
                            {
                              'border-red': !!errorsStepOne.password,
                            }
                          )}
                          placeholder="Senha"
                          {...register('password')}
                        />
                        <span
                          className={classNames(
                            'absolute text-red text-[0.60rem] left-1 bottom-[-15px]',
                            {
                              hidden: !!errorsStepOne.password === false,
                            }
                          )}
                        >
                          {errorsStepOne.password?.message}
                        </span>
                      </div>
                      <div className="relative w-[47%]">
                        <input
                          type="password"
                          className={classNames(
                            'w-[100%] border-[1px] border-solid border-black-regular placeholder:text-black-regular p-[0.65rem] rounded-md text-xs focus:border-primary',
                            {
                              'border-red': !!errorsStepOne.confirmPassword,
                            }
                          )}
                          placeholder="Confirmar Senha"
                          {...register('confirmPassword')}
                        />
                        <span
                          className={classNames(
                            'absolute text-red text-[0.60rem] left-1 bottom-[-15px]',
                            {
                              hidden: !!errorsStepOne.confirmPassword === false,
                            }
                          )}
                        >
                          {errorsStepOne.confirmPassword?.message}
                        </span>
                      </div>
                    </div>
                  </div>
                  <button
                    type="submit"
                    form="form-step-one"
                    className={classNames(
                      'relative bg-primary p-[0.65rem] pl-4 pr-4 rounded-md text-xs focus:border-primary w-[70%] font-bold transition shadow-lg hover:bg-opacity-80',
                      {
                        'bg-opacity-20 text-gray-400 cursor-not-allowed hover:bg-opacity-20':
                          loading,
                      }
                    )}
                  >
                    <span>Continuar</span>
                    <ReactLoading
                      type="spokes"
                      color="#DC7E19"
                      width={17}
                      className={classNames('absolute right-3 top-2', {
                        hidden: !loading,
                      })}
                    />
                  </button>
                </form>
              )}

              {step === 2 && (
                <form
                  id="form-step-two"
                  onSubmit={handleSubmitTwo(onSubmitStepTwo)}
                  autoComplete="off"
                  className="flex flex-col w-full items-center"
                >
                  <div className="flex flex-row gap-1 justify-center w-[70%] mb-3">
                    <AiOutlineUser size={22} color="#aaa" />
                    <MdKeyboardArrowRight size={22} color="#aaa" />
                    <TbAddressBook size={22} color="#DC7E19" />
                    <MdKeyboardArrowRight size={22} color="#aaa" />
                    <BsCreditCard2Back size={22} color="#aaa" />
                  </div>
                  <span className="w-[70%] text-sm font-light text-center mb-3">
                    Agora precisamos do seu
                    <br />
                    <strong>endereço para entrega.</strong>
                  </span>
                  <div className="flex flex-col gap-[1.20rem] w-[70%] mb-6">
                    <div className="relative">
                      <input
                        className={classNames(
                          'w-[100%] border-[1px] border-solid border-black-regular placeholder:text-black-regular p-[0.65rem] rounded-md text-xs focus:border-primary',
                          {
                            'border-red': !!errorsStepTwo.postal_code,
                          }
                        )}
                        placeholder="Cep"
                        {...registerTwo('postal_code')}
                        value={maskHelper.cep(String(watchTwo('postal_code')))}
                        onBlur={(e) => handlePostalCode(e.target.value)}
                      />
                      <span
                        className={classNames(
                          'absolute text-red text-[0.60rem] left-1 bottom-[-15px]',
                          {
                            hidden: !!errorsStepTwo.postal_code === false,
                          }
                        )}
                      >
                        {errorsStepTwo.postal_code?.message}
                      </span>
                    </div>
                    <div className="relative">
                      <input
                        className={classNames(
                          'w-[100%] border-[1px] border-solid border-black-regular placeholder:text-black-regular p-[0.65rem] rounded-md text-xs focus:border-primary',
                          {
                            'border-red': !!errorsStepTwo.address,
                          }
                        )}
                        placeholder="Logradouro"
                        {...registerTwo('address')}
                      />
                      <span
                        className={classNames(
                          'absolute text-red text-[0.60rem] left-1 bottom-[-15px]',
                          {
                            hidden: !!errorsStepTwo.address === false,
                          }
                        )}
                      >
                        {errorsStepTwo.address?.message}
                      </span>
                    </div>
                    <div className="flex gap-4">
                      <div className="relative w-[47%]">
                        <input
                          className={classNames(
                            'w-[100%] border-[1px] border-solid border-black-regular placeholder:text-black-regular p-[0.65rem] rounded-md text-xs focus:border-primary',
                            {
                              'border-red': !!errorsStepTwo.number,
                            }
                          )}
                          placeholder="Número"
                          {...registerTwo('number')}
                        />
                        <span
                          className={classNames(
                            'absolute text-red text-[0.60rem] left-1 bottom-[-15px]',
                            {
                              hidden: !!errorsStepTwo.number === false,
                            }
                          )}
                        >
                          {errorsStepTwo.number?.message}
                        </span>
                      </div>
                      <div className="relative w-[47%]">
                        <input
                          className={classNames(
                            'w-[100%] border-[1px] border-solid border-black-regular placeholder:text-black-regular p-[0.65rem] rounded-md text-xs focus:border-primary',
                            {
                              'border-red': !!errorsStepTwo.complement,
                            }
                          )}
                          placeholder="Complemento"
                          {...registerTwo('complement')}
                        />
                        <span
                          className={classNames(
                            'absolute text-red text-[0.60rem] left-1 bottom-[-15px]',
                            {
                              hidden: !!errorsStepTwo.complement === false,
                            }
                          )}
                        >
                          {errorsStepTwo.complement?.message}
                        </span>
                      </div>
                    </div>
                    <div className="relative">
                      <input
                        className={classNames(
                          'w-[100%] border-[1px] border-solid border-black-regular placeholder:text-black-regular p-[0.65rem] rounded-md text-xs focus:border-primary',
                          {
                            'border-red': !!errorsStepTwo.province,
                          }
                        )}
                        placeholder="Bairro"
                        {...registerTwo('province')}
                      />
                      <span
                        className={classNames(
                          'absolute text-red text-[0.60rem] left-1 bottom-[-15px]',
                          {
                            hidden: !!errorsStepTwo.province === false,
                          }
                        )}
                      >
                        {errorsStepTwo.province?.message}
                      </span>
                    </div>
                    <div className="flex gap-4">
                      <div className="relative w-[75%]">
                        <input
                          className={classNames(
                            'w-[100%] border-[1px] border-solid border-black-regular placeholder:text-black-regular p-[0.65rem] rounded-md text-xs focus:border-primary',
                            {
                              'border-red': !!errorsStepTwo.city,
                            }
                          )}
                          placeholder="Cidade"
                          {...registerTwo('city')}
                        />
                        <span
                          className={classNames(
                            'absolute text-red text-[0.60rem] left-1 bottom-[-15px]',
                            {
                              hidden: !!errorsStepTwo.city === false,
                            }
                          )}
                        >
                          {errorsStepTwo.city?.message}
                        </span>
                      </div>
                      <div className="relative w-[25%]">
                        <input
                          className={classNames(
                            'uppercase w-[100%] border-[1px] border-solid border-black-regular placeholder:text-black-regular p-[0.65rem] rounded-md text-xs focus:border-primary',
                            {
                              'border-red': !!errorsStepTwo.state,
                            }
                          )}
                          placeholder="UF"
                          {...registerTwo('state')}
                          maxLength={2}
                          value={
                            watchTwo('state')
                              ? maskHelper.uppercaseAZ(
                                  String(watchTwo('state'))
                                )
                              : ''
                          }
                        />
                        <span
                          className={classNames(
                            'absolute text-red text-[0.60rem] left-1 bottom-[-15px]',
                            {
                              hidden: !!errorsStepTwo.state === false,
                            }
                          )}
                        >
                          {errorsStepTwo.state?.message}
                        </span>
                      </div>
                    </div>
                  </div>
                  <button
                    type="submit"
                    form="form-step-two"
                    className="bg-primary p-[0.65rem] pl-4 pr-4 rounded-md text-xs focus:border-primary w-[70%] font-bold transition shadow-lg hover:bg-opacity-80"
                  >
                    Continuar
                  </button>
                </form>
              )}

              {step === 3 && (
                <form
                  id="form-step-three"
                  onSubmit={handleSubmitThree(onSubmitStepThree)}
                  autoComplete="off"
                  className="flex flex-col w-full items-center"
                >
                  <div className="flex flex-row gap-1 justify-center w-[70%] mb-3">
                    <AiOutlineUser size={22} color="#aaa" />
                    <MdKeyboardArrowRight size={22} color="#aaa" />
                    <TbAddressBook size={22} color="#aaa" />
                    <MdKeyboardArrowRight size={22} color="#aaa" />
                    <BsCreditCard2Back size={22} color="#DC7E19" />
                  </div>
                  <span className="w-[70%] text-sm font-light text-center mb-5">
                    Dados do cartão de crédito
                  </span>

                  <div className="flex flex-col gap-[1.20rem] mb-2">
                    <div className="relative">
                      <input
                        className={classNames(
                          'uppercase w-[100%] border-[1px] border-solid border-black-regular placeholder:text-black-regular p-[0.65rem] rounded-md text-xs focus:border-primary',
                          {
                            'border-red': !!errorsStepThree.holderName,
                          }
                        )}
                        placeholder="Nome no Cartão de Crédito"
                        {...registerThree('holderName')}
                      />
                      <span
                        className={classNames(
                          'absolute text-red text-[0.60rem] left-1 bottom-[-15px]',
                          {
                            hidden: !!errorsStepThree.holderName === false,
                          }
                        )}
                      >
                        {errorsStepThree.holderName?.message}
                      </span>
                    </div>
                    <div className="relative">
                      <input
                        className={classNames(
                          'w-[100%] border-[1px] border-solid border-black-regular placeholder:text-black-regular p-[0.65rem] rounded-md text-xs focus:border-primary',
                          {
                            'border-red': !!errorsStepThree.number,
                          }
                        )}
                        placeholder="Número do Cartão"
                        {...registerThree('number')}
                        value={maskHelper.creditCard(
                          String(watchThree('number'))
                        )}
                      />
                      <span
                        className={classNames(
                          'absolute text-red text-[0.60rem] left-1 bottom-[-15px]',
                          {
                            hidden: !!errorsStepThree.number === false,
                          }
                        )}
                      >
                        {errorsStepThree.number?.message}
                      </span>
                    </div>
                    <div className="flex gap-4">
                      <div className="relative w-[47%]">
                        <input
                          className={classNames(
                            'w-[100%] border-[1px] border-solid border-black-regular placeholder:text-black-regular p-[0.65rem] rounded-md text-xs focus:border-primary',
                            {
                              'border-red': !!errorsStepThree.expiry,
                            }
                          )}
                          placeholder="mm/aaaa"
                          {...registerThree('expiry')}
                          value={maskHelper.monthYear(
                            String(watchThree('expiry'))
                          )}
                        />
                        <span
                          className={classNames(
                            'absolute text-red text-[0.60rem] left-1 bottom-[-15px]',
                            {
                              hidden: !!errorsStepThree.expiry === false,
                            }
                          )}
                        >
                          {errorsStepThree.expiry?.message}
                        </span>
                      </div>
                      <div className="relative w-[47%]">
                        <input
                          className={classNames(
                            'w-[100%] border-[1px] border-solid border-black-regular placeholder:text-black-regular p-[0.65rem] rounded-md text-xs focus:border-primary',
                            {
                              'border-red': !!errorsStepThree.cvv,
                            }
                          )}
                          placeholder="CVV"
                          {...registerThree('cvv')}
                          value={maskHelper.number(String(watchThree('cvv')))}
                          maxLength={4}
                        />
                        <span
                          className={classNames(
                            'absolute text-red text-[0.60rem] left-1 bottom-[-15px]',
                            {
                              hidden: !!errorsStepThree.cvv === false,
                            }
                          )}
                        >
                          {errorsStepThree.cvv?.message}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="h-[0.12rem] bg-primary w-[79%] my-4" />

                  <div className="flex flex-row w-[79%] justify-between text-xs font-light">
                    <p>Plano</p>
                    <p>Mensal</p>
                  </div>

                  <div className="h-[0.12rem] bg-background w-[79%] my-3" />

                  <div className="flex flex-row w-[79%] justify-between text-xs font-light">
                    <p>Carletto Meat Club</p>
                    <p>{maskHelper.formatMoeda(subsValue)}</p>
                  </div>

                  <div className="h-[0.12rem] bg-background w-[79%] my-3" />

                  <div className="flex flex-row w-[79%] justify-between text-sm font-bold mb-7">
                    <p>Valor total</p>
                    <p>{maskHelper.formatMoeda(subsValue)}</p>
                  </div>

                  <button
                    type="submit"
                    form="form-step-three"
                    disabled={loading}
                    className={classNames(
                      'relative bg-primary p-[0.65rem] pl-4 pr-4 rounded-md text-xs focus:border-primary w-[70%] font-bold transition shadow-lg hover:bg-opacity-80',
                      {
                        'bg-opacity-20 text-gray-400 cursor-not-allowed hover:bg-opacity-20':
                          loading,
                      }
                    )}
                  >
                    <span>Concluir assinatura</span>
                    <ReactLoading
                      type="spokes"
                      color="#DC7E19"
                      width={17}
                      className={classNames('absolute right-3 top-2', {
                        hidden: !loading,
                      })}
                    />
                  </button>
                </form>
              )}

              <div className="flex items-center justify-center w-full mt-4">
                <div className="bg-black-light h-[1px] w-[30%]" />
                <p className="font-light text-lg text-center mx-2">ou</p>
                <div className="bg-black-light h-[1px] w-[30%]" />
              </div>
              <p className="font-light text-xs mt-4 text-left w-[70%]">
                Já possui uma conta?{' '}
                <Link to="/login" className="font-bold">
                  Clique aqui
                </Link>{' '}
                para entrar.
              </p>
              <img
                alt="Carletto"
                src={purshaseSecuriry}
                loading="lazy"
                className="mt-3"
                width="200"
              />
            </div>
          </div>
        </motion.section>

        <Footer />
        <Copyright />
      </div>
    </div>
  );
}
